import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import ReplyIcon from '@mui/icons-material/Reply';
import {useDispatch, useSelector} from "react-redux";
import {getSubRanK} from "../utils/helpers";
import {setCmsCopy, setCmsData} from "../../general/redux/actions";

function CMSCopyTarget({ position }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsCopy = useSelector((state) => state.default.cmsCopy);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const copyArticle = () => {
    let updatedData = [...cmsData];

    cmsCopy.reverse().forEach((art) => {
      updatedData.splice(position, 0, art)
    })

    let outerCount = 0;
    updatedData = updatedData.map((it, index) => {
      let newRank;
      if(it.rank % 100 === 0){
        outerCount++;
        newRank = outerCount * 100;
      } else {
        const subRank = getSubRanK(it.rank);
        newRank = (outerCount * 100 || 100) + subRank
      }

      return {
        ...it,
        rank: newRank
      };
    });

    dispatch(setCmsData(updatedData));
    setTimeout(() => dispatch(setCmsCopy([])));

  }


  return (
    <Zoom
      in={Boolean(cmsCopy && cmsCopy.length)}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${Boolean(cmsCopy && cmsCopy.length) ? transitionDuration.exit : transitionDuration.enter}ms`,
      }}
      unmountOnExit
    >
      <Box
        className={`cms-non-closable cms-article-copy-bar ${position === 0 ? 'joyride-article-copy-bar' : ''}`}
        component="div"
        sx={{
          my: 1,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          height: Boolean(cmsCopy && cmsCopy.length) ? 4 : 'auto',
          // overflow: active && position !== activePosition ? 'visible' : 'hidden',
          backgroundColor: Boolean(cmsCopy && cmsCopy.length) ? 'warning.main' : 'background.main',
        }}
      >
        {Boolean(cmsCopy && cmsCopy.length) && (
          <Box
            component="div"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Fab
              sx={{
                position: 'absolute',
                zIndex: 2,
              }}
              size="small"
              aria-label="Hinzufügen"
              color="warning"
              onClick={copyArticle}
            >
              <ReplyIcon sx={{transform: 'rotate(-90deg)'}}/>
            </Fab>
          </Box>
        )}
      </Box>
    </Zoom>
  );
}

CMSCopyTarget.propTypes = {
  position: PropTypes.number,
};

export default CMSCopyTarget;
