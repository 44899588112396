import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from "react-joyride";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CMSJOYRIDE} from "./joyride";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {setCmsMode, toggleCmsAdd, toggleJoyride, toggleJoyrideAdd} from "../redux/actions";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JoyrideRoot(){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const joyride = useSelector((state) => state.default.joyride);
  const [open, setOpen] = React.useState(false);
  const [run,setRun] = React.useState(false);
  const [stepIndex,setStepIndex] = React.useState(0);
  const [joyrideSteps, setJoyrideSteps] = React.useState();

  const handleStartTour = () => {
    setOpen(false);
    setTimeout(() => {
      setRun(true);
    }, 400)

  };

  const handleClose = () => {
    setOpen(false);
    setRun(false);
    setStepIndex(0);
    dispatch(toggleJoyride(false));
  };

  React.useEffect(() => {
    if(joyride){
      setTimeout(() => {
        setOpen(true);
      }, 500)
      setJoyrideSteps([...CMSJOYRIDE.steps])
      return () => {}
    } else {
      setRun(false);
      setStepIndex(0);
    }

  },[joyride])

  const handleJoyrideCallback = ({ action, index, type, status }) => {
    console.log('handeJoyrideCallback', action, index, type, status)

    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status) && run) {
      setRun(false);
      setStepIndex(0);
      dispatch(toggleCmsAdd(false));
      dispatch(setCmsMode('preview'));
      setTimeout(( )=> {
        dispatch(toggleJoyride(false));
      }, 500)
    } else if([EVENTS.STEP_AFTER].includes(type) && index === 10 && action === ACTIONS.NEXT){
      const stepIndex = index + 1;
      setRun(false);
      setStepIndex(stepIndex)
      dispatch(toggleJoyrideAdd(true));
      setTimeout(( )=> {
        setRun(true)
      }, 500)
    } else if([EVENTS.STEP_AFTER].includes(type) && index === 15 && action === ACTIONS.PREV){
      const stepIndex = index - 1;
      setRun(false);
      setStepIndex(stepIndex);
      dispatch(toggleCmsAdd(true));
      dispatch(toggleJoyrideAdd(true));
      setTimeout(( )=> {
        setRun(true)
      }, 500)
    } else if([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)){
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(stepIndex)
    } else if([EVENTS.STEP_BEFORE].includes(type)){
      // console.log('step:before', index, action)
      // if(index === 1){
      //   console.log('activate preview')
      //   dispatch(setCmsMode('preview'));
      // }
      if(index === 0){

      } else if(index === 1){

      } else if(index === 2){
        if(action === ACTIONS.PREV) {
          dispatch(setCmsMode('preview'));
        }
      } else if(index === 3){
        if(action === ACTIONS.NEXT) {
          dispatch(setCmsMode('edit'));
        } else if(action === ACTIONS.PREV) {
          // dispatch(toggleCmsRecover(false));
        }
      } else if(index === 4){
        if(action === ACTIONS.NEXT) {
          // dispatch(toggleCmsRecover(true));
        }
      } else if(index === 7){
        if(action === ACTIONS.PREV){
        }
      } else  if(index === 8){
        if(action === ACTIONS.PREV) {
          dispatch(setCmsMode('edit'));
        } else if(action === ACTIONS.NEXT) {
          dispatch(setCmsMode('structure'));
        }
      } else  if(index === 9){
        if(action === ACTIONS.PREV) {

        } else if(action === ACTIONS.NEXT) {
          dispatch(toggleCmsAdd(true));
        }
      } else  if(index === 10){
        if(action === ACTIONS.PREV) {
          dispatch(toggleJoyrideAdd(false));
        }
      } else if(index === 13){
        if(action === ACTIONS.NEXT) {

        }else if(action === ACTIONS.PREV) {
          dispatch(toggleJoyrideAdd(false));
        }
      } else if(index === 14){
        if(action === ACTIONS.NEXT) {
          // dispatch(toggleJoyrideAdd(true));
        } else if(action === ACTIONS.PREV) {
        }
      } else if(index === 15){
        if(action === ACTIONS.NEXT) {
          dispatch(toggleCmsAdd(false));
          dispatch(toggleJoyrideAdd(false));
        } else if(action === ACTIONS.PREV) {
          dispatch(toggleJoyrideAdd(true));
          dispatch(toggleCmsAdd(true));
        }
      }
    }

  };

  return (
    <>
      <Joyride
        steps={joyrideSteps}
        run={run}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        stepIndex={stepIndex}
        showSkipButton
        disableOverlayClose
        disableCloseOnEsc
        disableScrolling
        locale={{
          back: 'Zurück',
          close: 'Schliessen',
          last: 'Last',
          next: 'Weiter',
          open: 'Open the dialog',
          skip: 'Überspringen'
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="joyride-dialog-title"
        aria-describedby="joyride-dialog-description"
      >
        <DialogTitle disableTypography id="joyride-dialog-title">
          <Typography variant="h3">
            {"Willkommen zum CMS-Editor von dito"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="joyride-dialog-description">
            <Typography component="div" variant="body1">
              Sind Sie das erstmal hier und haben noch keine Ahnung von Tuten und Blasen?<br/>
              <strong>Kein Problem!</strong><br/>
              Starten Sie einfach die Tour und lassen es sich erklären.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Kenn ich schon
          </Button>
          <Button onClick={handleStartTour} autoFocus>
            Tour starten
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

JoyrideRoot.propTypes = {
  shade: PropTypes.bool,
};

export default JoyrideRoot;
