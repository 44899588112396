import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import {checkTitleVariant, checkTypeHas} from "../utils/helpers";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import { updateCmsArticle} from "../../general/redux/actions";
import CMSImage from "./CMSImage";

function CMSGenericItem({ resource, noteOnly, mode}){
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const variant = checkTitleVariant(resource.label);
  const image = resource && resource.attachments && resource && resource.attachments.length ? resource && resource.attachments[0] : null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: resource.label.indexOf('center') !== -1 ? 'center' : 'flex-start',
      }}
    >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 1,
          }}
        >
          <CMSImage image={image} resource={resource} type={resource.label} mode={mode} width={150} height={150} />
        </Box>
        <EditableTextSlim
          handleChange={(val) => {
            dispatch(updateCmsArticle({
              rId: resource.resourceId,
              name: 'title',
              val
            }))
          }}
          center={['h1only','h2only'].indexOf(resource.label) !== -1}
          currentValue={resource.title}
          variant={variant}
          placeholder={t('cms.field.title.placeholder')}
          disabled={mode !== 'edit'}
        />
        <EditableTextAreaSlim
          name="note"
          inline
          resource={resource}
          placeholder={t('cms.field.note.placeholder')}
          currentValue={resource.note}
          handleChange={(val) => {
            dispatch(updateCmsArticle({
              rId: resource.resourceId,
              name: 'note',
              val
            }))
          }}
          minHeight={0}
          maxWidth={1152}
          disabled={mode !== 'edit'}
        />
    </Box>
  );
}

CMSGenericItem.propTypes = {
  resource: PropTypes.object,
  noteOnly: PropTypes.bool,
  mode: PropTypes.string,
};

export default CMSGenericItem;
