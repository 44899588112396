import {
  SET_PROJECT_ID,
  SET_JOURNAL_ID,
  SET_ACTIVE_RESOURCE_ID,
  SET_COMMUNITY_ID,
  AUTH_UPDATE_USER,
  AUTH_RESET_USER,
  SET_CONFIRMATION,
  DISPLAY_TOAST,
  HIDE_TOAST,
  SET_GENERIC_PROPERTIES,
  SET_PROPERTIES,
  SET_QUALIFIERS,
  SET_LABELS,
  SET_LINK_LABELS,
  SET_KEYWORDS,
  SET_BOARD_CONFIG,
  SET_MAIL,
  SET_GROUPS,
  SET_VOTING_CONFIG,
  SET_CMS_DATA,
  SET_INITIAL_CMS_DATA,
  UPDATE_CMS_ARTICLE,
  SET_CMS_MODE,
  TOGGLE_CMS_SIDEBAR,
  SET_CMS_ACTIVE_ID,
  UPDATE_CMS_DELETED_ARTICLES,
  UPDATE_CMS_DELETED_ATTACHMENTS,
  UPDATE_CMS_ADDED_ATTACHMENTS,
  UPDATE_CMS_UPDATED_ATTACHMENTS,
  UPDATE_CMS_ADD,
  UPDATE_CMS_COPY,
  SET_JOYRIDE,
  UPDATE_JOYRIDE_ARTICLE,
  SET_JOYRIDE_ADD,
  SET_MODERATORS,
} from './actions';
import {CMSJOYRIDEDATA} from "../joyride/joyride";

const storageUser = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser'))
  : null;

const initialDefaultState = {
  projectId: 0,
  journalId: 0,
  activeResourceId: '',
  communityId: 0,
  currentUser: storageUser,
  confirm: null,
  toast: null,
  properties: [],
  genericProperties: [],
  qualifiers: [],
  labels: [],
  linkLabels: [],
  keywords: [],
  boardConfig: null,
  mail: null,
  groups: [],
  votingConfig: null,
  cmsInitialData: [],
  cmsData: [],
  cmsMode: 'preview',
  cmsSidebar: false,
  cmsActiveId: '',
  cmsAdd: false,
  cmsCopy: [],
  cmsDeletedArticles: [],
  cmsAddedAttachments: [],
  cmsUpdatedAttachments: [],
  cmsDeletedAttachments: [],
  moderators: [],
  joyride: false,
  joyrideCmsData: [...CMSJOYRIDEDATA],
  joyrideDemoAdd: false
};

const defaultReducer = (state = initialDefaultState, action) => {
  switch (action.type) {
    case SET_PROJECT_ID:
      return { ...state, projectId: action.payload };
    case SET_JOURNAL_ID:
      return { ...state, journalId: action.payload };
    case SET_ACTIVE_RESOURCE_ID:
      return { ...state, activeResourceId: action.payload };
    case SET_MODERATORS:
      return { ...state, moderators: action.payload };
    case SET_COMMUNITY_ID:
      return { ...state, communityId: action.payload };
    case AUTH_UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case AUTH_RESET_USER:
      return { ...state, currentUser: null };
    case DISPLAY_TOAST:
      return { ...state, toast: action.payload };
    case HIDE_TOAST:
      return { ...state, toast: null };
    case SET_CONFIRMATION:
      return { ...state, confirm: action.payload };
    case SET_GENERIC_PROPERTIES:
      return { ...state, genericProperties: action.payload };
    case SET_PROPERTIES:
      return { ...state, properties: action.payload };
    case SET_QUALIFIERS:
      return { ...state, qualifiers: action.payload };
    case SET_LABELS:
      return { ...state, labels: action.payload };
    case SET_LINK_LABELS:
      return { ...state, linkLabels: action.payload };
    case SET_KEYWORDS:
      return { ...state, keywords: action.payload };
    case SET_BOARD_CONFIG:
      return { ...state, boardConfig: action.payload };
    case SET_MAIL:
      return { ...state, mail: action.payload };
    case SET_GROUPS:
      return { ...state, groups: action.payload };
    case SET_VOTING_CONFIG:
      return { ...state, votingConfig: action.payload };
    case SET_CMS_DATA:
      return { ...state, cmsData: action.payload };
    case SET_INITIAL_CMS_DATA:
      return { ...state, cmsInitialData: action.payload };
    case SET_CMS_MODE:
      return { ...state, cmsMode: action.payload };
    case TOGGLE_CMS_SIDEBAR:
      return { ...state, cmsSidebar: action.payload };
    case UPDATE_CMS_ADD:
      return { ...state, cmsAdd: action.payload };
    case UPDATE_CMS_COPY:
      return { ...state, cmsCopy: action.payload };
    case UPDATE_CMS_ADDED_ATTACHMENTS:
      return { ...state, cmsAddedAttachments: action.payload };
    case UPDATE_CMS_UPDATED_ATTACHMENTS:
      return { ...state, cmsUpdatedAttachments: action.payload };
    case UPDATE_CMS_DELETED_ATTACHMENTS:
      return { ...state, cmsDeletedAttachments: action.payload };
    case UPDATE_CMS_DELETED_ARTICLES:
      return { ...state, cmsDeletedArticles: action.payload };
    case SET_CMS_ACTIVE_ID:
      return {
        ...state,
        cmsActiveId:
          state.cmsActiveId === action.payload
            ? ''
            : action.payload
      };
    case UPDATE_CMS_ARTICLE:
      return {
        ...state,
        cmsData: (state.cmsData || []).map((item) => {
          if (item.resourceId === action.payload.rId) {
            return {
              ...item,
              [action.payload.name]: action.payload.val
            }
          }
          return item;
        }) };
    case SET_JOYRIDE:
      return { ...state, joyride: action.payload };
    case UPDATE_JOYRIDE_ARTICLE:
      return {
        ...state,
        joyrideCmsData: (state.joyrideCmsData || []).map((item) => {
          if (item.resourceId === action.payload.rId) {
            return {
              ...item,
              [action.payload.name]: action.payload.val
            }
          }
          return item;
        }) };
    case SET_JOYRIDE_ADD:
      return { ...state, joyrideDemoAdd: action.payload };
    default:
      return state;
  }
};

export default defaultReducer;
