import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {alpha} from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CMSSlider from "./CMSSlider";
import CMSColContainer from "./CMSColContainer";
import {setCmsActiveId} from "../../general/redux/actions";
import CMSArticleEdit from "./CMSArticleEdit";
import CMSSequenceContainer from "./CMSSequenceContainer";

function CMSContainer(
  {
    resource,
    mode,
    activeId,
    childs
  }
){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);
  const [item, setItem] = React.useState({...resource});
  const [highlight, setHighLight] = React.useState(false);

  React.useEffect(() => {
    setItem({...resource});
    return () => {};
  },[resource]);

  const activateArticle = (e) => {
    if(!e.target.closest('.cms-container-article-root')){
      dispatch(setCmsActiveId(resource.resourceId))
    }
  }

  const deactivateArticle = (e) => {
    if(!e || !e.target.closest('.cms-non-closable')){
      dispatch(setCmsActiveId(''))
    }
  }

  const highlightArticle = (e, state) => {
    setHighLight(state)
  }

  const handleCmsContainerType = (item, children) => {

    switch(item.label){
      case 'slider':
        return (
          <CMSSlider
            resource={item}
            mode={mode}
            activeId={activeId}
            items={children}
            deactivateArticle={deactivateArticle}
            highlightParent={setHighLight}
          />
        )
      case 'sequenceContainer':
        return (
          <CMSSequenceContainer
            resource={item}
            mode={mode}
            activeId={activeId}
            items={children}
            deactivateArticle={deactivateArticle}
            highlightParent={setHighLight}
          />
        )
      default:
        return (
          <CMSColContainer
            resource={item}
            mode={mode}
            activeId={activeId}
            items={children}
            deactivateArticle={deactivateArticle}
            highlightParent={setHighLight}
          />
        );
    }
  }

  const subItems = item ?
    cmsData.filter((art) => {
      return art.type === 'cms_article_child' &&
        !art.markedForDeletion &&
        art.rank > item.rank && art.rank < item.rank + 100
    }) :[];

  const isTitle = ['h1only', 'h2only', 'h3only', 'h4only', 'h5only', 'h6only'].indexOf(item ? item.label : '') !== -1;
  const isFullSection = item ? item.properties.cmsStyle.fullSection : false;
  const isBox = item ? item.properties.cmsStyle.box : false;
  const boxColor = item ? item.properties.cmsStyle.boxColor || 'background.blue' : 'background.blue';
  const boxPt = item ? parseInt(item.properties.cmsStyle.boxPaddingTop || 4) : 4;
  const boxPb = item ? parseInt(item.properties.cmsStyle.boxPaddingBottom || 4) : 4;
  const boxPl = item ? parseInt(item.properties.cmsStyle.boxPaddingLeft || 4) : 4;
  const boxPr = item ? parseInt(item.properties.cmsStyle.boxPaddingRight || 4) : 4;
  const bColor = item ? item.properties.cmsStyle.borderColor || 'background.main' : 'background.main';
  const bRadius = item ? parseInt(item.properties.cmsStyle.borderRadius || 0) : 0;
  const bWidth = item ? item.properties.cmsStyle.borderWidth : 0;

  const handleCurrentWidth = (hasSidebar, fullsize) => {
    if(hasSidebar && !fullsize){
      return 944;
    }
    if(!hasSidebar && fullsize){
      return 1456;
    }
    return 1200;
  }

  return (
    <Box
      className="cms-container-root"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mb: isTitle ? 1: 4,
        width: handleCurrentWidth(cmsSidebar, isFullSection),
        mx: 'auto',
        backgroundColor: isBox ? boxColor  : 'initial',
        borderRadius: bRadius,
        border: bWidth ? `${bWidth}px solid` : 'none',
        borderColor: bColor,
      }}
    >
      {/*{(highlight || (item && activeId === item.resourceId)) && (*/}
      {/*  <Typography*/}
      {/*    component="div"*/}
      {/*    variant="body1"*/}
      {/*    sx={{*/}
      {/*      position: 'absolute',*/}
      {/*      top:'calc(100% + 4px)',*/}
      {/*      left:0,*/}
      {/*      zIndex: 15,*/}
      {/*      ml: 2,*/}
      {/*      p: 0.5,*/}
      {/*      maxHeight: 32,*/}
      {/*      cursor: 'pointer',*/}
      {/*      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {t(`cms.type.${item ? item.label : ''}`)}*/}
      {/*  </Typography>*/}
      {/*)}*/}
      <Box
        className="cms-container-frame"
        component={Paper}
        elevation={0}
        onClick={mode === 'structure' ? activateArticle : null}
        onMouseEnter={mode === 'structure' ? (e) => highlightArticle(e,true) : null}
        onMouseLeave={mode === 'structure' ? (e) => highlightArticle(e,false) : null}
        sx={{
          backgroundColor: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          cursor: 'pointer',
          outline: resource.resourceId === activeId || highlight ? '1px solid' : 'none',
          outlineOffset: 2,
          outlineColor: (theme) => theme.palette.secondary.main,
        }}
      >
        <Box
          className="cms-container-frame-center"
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'inherit',
          }}
        >
          <Box
            className="cms-container-frame-inner"
            component="div"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Box
              className={`cms-container-container cms-container-${item ? item.label : ''}`}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                pt: isBox ? boxPt : 1,
                pb: isBox ? boxPb : 1,
                pl: !isBox && (cmsSidebar || isFullSection) ? 0 : boxPl,
                pr: !isBox && (cmsSidebar || isFullSection) ? 0 : boxPr,
              }}
            >
              {handleCmsContainerType(item,childs || subItems)}
            </Box>
          </Box>
        </Box>
      </Box>
      {mode === 'structure' && Boolean(resource.resourceId === activeId) && (
        <CMSArticleEdit
          activateArticle={activateArticle}
          deactivateArticle={deactivateArticle}
          highlightArticle={highlightArticle}
          mode={mode}
          activeId={activeId}
        />
      )}
    </Box>
  );
}

CMSContainer.propTypes = {
  resource: PropTypes.object,
};

export default CMSContainer;
