/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageRightNoFloat = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path id="svg_9" d="m14.875,1.00006l7.5,0l0,8.29166l-7.5,0l0,-8.29166z" opacity="undefined" fill="#ffffff" stroke="inherit"/>
        <g id="svg_11" stroke="null">
          <path fill="inherit" opacity="undefined" d="m-0.12498,0.06253l13.75,0l0,4.37499l-13.75,0l0,-4.37499z" id="svg_1" stroke="#fff"/>
          <g id="svg_8" stroke="null">
            <path fill="none" opacity="undefined" d="m0.52976,6.125l12.65875,0" id="svg_7" stroke="inherit"/>
            <line id="svg_2" y2="9.87499" x2="12.99071" y1="9.87499" x1="0.76711" fill="none" stroke="inherit"/>
            <line id="svg_3" y2="13.49997" x2="12.99071" y1="13.49997" x1="0.88579" fill="none" stroke="inherit"/>
          </g>
          <g id="svg_10" stroke="null">
            <line id="svg_5" y2="17.5833" x2="13.53772" y1="17.5833" x1="0.70438" fill="none" stroke="inherit"/>
            <line id="svg_6" y2="21.33329" x2="13.53772" y1="21.33329" x1="0.70438" fill="none" stroke="inherit"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

ImageRightNoFloat.propTypes = {};

export default ImageRightNoFloat;
