import i18n from './i18n';

export const fakeStringId = () => {
  let result = 'created-';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};



export const handleFlatObject = (item) => {
  return { ...item, ...item.properties };
};

export const startsWithCapital = (word) => {
  return word.charAt(0) === word.charAt(0).toUpperCase()
}

export const handleFlatData = (items) => {
  return (items || []).map((item) => {
    return {
      ...item,
      ...item.properties,
    };
  })
};
export const handleTranslateQualifier = (qualifier) => {
  if(qualifier.indexOf('assignedTo') !== -1){
    const check = qualifier.split('.');
    check.splice(0, 2);
    return `${i18n.t('qualifier.assignedTo.basic')} ${check.join(' ')}`
  }
  return i18n.t(qualifier || 'qualifier.no.status');
}

export const handleQualifierColor = (qualifier) => {
  if(!qualifier){
    return 'warning.main';
  }
  if(qualifier.indexOf('read') !== -1){
    return 'success.main';
  }
  if(qualifier.indexOf('assignedTo') !== -1){
    return 'info.main';
  }
  return 'text.primary';
}

export const handleFlatUserData = (items, groups) => {

  return (items || []).map((item) => {
    const groupProps = {};
    (groups || []).filter((group) => group.uid !== -1).forEach((group) => {
      groupProps[group.name] = Boolean((item.groupMemberships || []).find((memberShip) => {
        return memberShip.groupUid === group.uid;
      }));
    })
    const newItem = { ...item, ...groupProps };
    return newItem;
  })
};

export const handleGroupName = (gName, t) => {
  if(gName.indexOf('all') !== -1){
    return t('groups.all');
  }
  if(gName.indexOf('authorities') !== -1){
    return t('groups.authorities');
  }
  if(gName.indexOf('moderation') !== -1){
    return t('groups.moderation');
  }
  if(gName.indexOf('editorial') !== -1){
    return t('groups.editorials');
  }
  if(gName.indexOf('lightAdmin') !== -1){
    return t('groups.lightAdmins');
  }
  if(gName.indexOf('newsLetter') !== -1){
    return t('groups.newsletter');
  }
  if(gName.indexOf('admin') !== -1){
    return t('groups.admins');
  }
  return t('groups.participants');
}

export function a11yProps(index, name) {
  return {
    id: `${name}-tab-${index}`,
    'aria-controls': `${name}-tabpanel-${index}`,
  };
}

export const handleMemberships = (groups, userGroups) => {
  const memberships = {};
  (groups || []).forEach((group) => {
    memberships[group.name] = Boolean((userGroups || []).find((gr) => gr.groupUid === group.uid))
  })
  return memberships;
}

export const checkComplexity = (pwd) => {
  return Boolean(String(pwd)
    .toLowerCase()
    .match(
      /((?=.*\d)(?=.*[a-zA-Z]).{6,128})/
    ));
}

export const validateEmail = (email) => {
  return Boolean(String(email)
    .toLowerCase()
    .match(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    ));
};

export const checkAllOptionsAreEmails = (options) => {
  let allEmails = true;
  (options || []).forEach((option) => {
    if(option && !validateEmail(option)){
      allEmails = false;
    }
  })
  return allEmails;
}

export  const extractResourceKeywordsSet = (group, rKeywords, ids, flat) => {
  const groupKeywordIds = group ? (group.keywords || []).map((kw) => kw.id) : [];

  if(ids){
    return (rKeywords || []).filter((kw) => groupKeywordIds.indexOf(kw.id) !== -1 || groupKeywordIds.indexOf(kw.id) !== -1).map((kw) => kw.id);
  }
  if(flat){
    return (rKeywords || []).filter((kw) => groupKeywordIds.indexOf(kw.id) !== -1).map((kw) => kw.name);
  }
  return (rKeywords || []).filter((kw) => groupKeywordIds.indexOf(kw.id) !== -1);
}

export const normalizeAttachmentType = (type) => {
  switch (type) {
    case 'audio':
    case 'audio/mp4':
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/vnd.wav':
      return 'audio';
    case 'pdf':
    case 'application/pdf':
      return 'pdf';
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return 'image';
    default:
      return 'generic';
  }
}

export const isImage = (type) => {
  if(type && type.toLowerCase().indexOf('image') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('jpeg') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('jpg') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('png') !== -1){
    return true;
  }
  return false;
};

export const isPDF = (type) => {
  if(type && type.toLowerCase().indexOf('pdf') !== -1){
    return true;
  }
  return false;
};

export const isXls = (type) => {
  if(type && type.toLowerCase() === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
    return true;
  }
  if(type && type.toLowerCase().indexOf('vnd.openxml') !== -1){
    return true;
  }
  return false;
};


export const isAudio = (type) => {
  if(type && type.toLowerCase().indexOf('audio') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('mp3') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('mpeg') !== -1){
    return true;
  }
  if(type && type.toLowerCase().indexOf('mp4') !== -1){
    return true;
  }
  return false;
};


export function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length;
    while ((k--) + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}