/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MultiCol5 = (props) => {
  return (
    <SvgIcon {...props}>
      <g >
        <title >Layer 1</title>
        <g id="svg_8" stroke-width="0">
          <rect fill="none" x="0.375" y="5.5" width="23.125" height="12.625" id="svg_1" stroke="inherit"/>
          <rect fill="none" x="2" y="7.25" width="2" height="9.125" id="svg_2" stroke="inherit"/>
          <rect fill="none" x="5.5" y="7.25" width="2" height="9.125" id="svg_3" stroke="inherit"/>
          <rect fill="none" x="9.125" y="7.25" width="2" height="9.125" id="svg_4" stroke="inherit"/>
          <rect fill="none" x="12.75" y="7.25" width="2" height="9.125" id="svg_5" stroke="inherit"/>
          <rect fill="none" x="16.25" y="7.25" width="2" height="9.125" id="svg_6" stroke="inherit"/>
          <rect fill="none" x="19.875" y="7.25" width="2" height="9.125" id="svg_7" stroke="inherit"/>
        </g>
        <rect stroke="inherit" id="svg_9" height="14" width="23.75" y="5" x="0.125" fill="none"/>
        <rect stroke="inherit" id="svg_10" height="10.25" width="2.5" y="6.875" x="2" fill="none"/>
        <rect stroke="inherit" id="svg_11" height="10.25" width="2.5" y="7" x="6.125" fill="none"/>
        <rect stroke="inherit" id="svg_12" height="10.25" width="2.5" y="7" x="10.375" fill="none"/>
        <rect stroke="inherit" id="svg_13" height="10.25" width="2.5" y="7" x="14.875" fill="none"/>
        <rect stroke="inherit" id="svg_14" height="10.25" width="2.5" y="6.875" x="19.375" fill="none"/>
      </g>

    </SvgIcon>
  );
};

MultiCol5.propTypes = {};

export default MultiCol5;
