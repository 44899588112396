/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageLeftNoFloat = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path id="svg_9" d="m1.04171,1.33339l7.5,0l0,8.29166l-7.5,0l0,-8.29166z" opacity="undefined" fill="#ffffff" stroke="inherit"/>
        <g id="svg_11" stroke="null">
          <path fill="inherit" opacity="undefined" d="m9.70832,0.72919l13.75,0l0,4.37499l-13.75,0l0,-4.37499z" id="svg_1" stroke="#fff"/>
          <g id="svg_8" stroke="null">
            <path fill="none" opacity="undefined" d="m10.36306,6.79166l12.65875,0" id="svg_7" stroke="inherit"/>
            <line id="svg_2" y2="10.54165" x2="22.82401" y1="10.54165" x1="10.60041" fill="none" stroke="inherit"/>
            <line id="svg_3" y2="14.16663" x2="22.82401" y1="14.16663" x1="10.71909" fill="none" stroke="inherit"/>
          </g>
          <g id="svg_10" stroke="null">
            <line id="svg_5" y2="18.24996" x2="23.37102" y1="18.24996" x1="10.53768" fill="none" stroke="inherit"/>
            <line id="svg_6" y2="21.99995" x2="23.37102" y1="21.99995" x1="10.53768" fill="none" stroke="inherit"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

ImageLeftNoFloat.propTypes = {};

export default ImageLeftNoFloat;
