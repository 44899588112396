import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import {updateCmsArticle} from "../../general/redux/actions";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import CustomExpandMore from "../../general/components/CustomExpandMore";

function CMSFaq({ resource, parent, mode }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState( false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const basicTextColor = 'primary.main';
  const textColor = resource && resource.properties && resource.properties.cmsStyle ? resource.properties.cmsStyle.textColor || basicTextColor : basicTextColor;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography variant="h2" component="div">
          <CustomExpandMore
            buttonSx={{py: 1, pr: 0.5, pl: 0}}
            iconCollapsed={<HelpOutlineIcon fontSize="inherit" sx={{width: 32, height: 32}} />}
            iconExpanded={<HelpOutlineIcon fontSize="inherit" sx={{width: 32, height: 32}} />}
            expanded={mode !== 'preview' ? true : expanded}
            handleChange={handleExpandClick}
          />
        </Typography>
        <Box onClick={mode !== 'preview' ? null : handleExpandClick} sx={{flexGrow: 1}}>
          <EditableTextSlim
            handleChange={(val) => {
              dispatch(updateCmsArticle({
                rId: resource.resourceId,
                name: 'title',
                val
              }))
            }}
            color={textColor}
            currentValue={resource.title}
            variant="h2"
            placeholder={t('cms.field.title.placeholder')}
            disabled={mode !== 'edit'}
          />
        </Box>
      </Box>

      <Collapse in={mode !== 'preview' ? true : expanded} timeout="auto" unmountOnExit sx={{pl: 5}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography
            component="div"
            variant="body1"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              py: 1,
          }}
          >
            <InfoOutlinedIcon fontSize="inherit" />
            <Box sx={{ml: 1}}>Antwort</Box>
          </Typography>
          <EditableTextAreaSlim
            name="note"
            inline
            resource={resource}
            placeholder={t('cms.field.note.placeholder')}
            currentValue={resource.note}
            handleChange={(val) => {
              dispatch(updateCmsArticle({
                rId: resource.resourceId,
                name: 'note',
                val
              }))
            }}
            minHeight={0}
            maxWidth={1152}
            disabled={mode !== 'edit'}
          />
        </Box>
      </Collapse>
    </>
  );
}

CMSFaq.propTypes = {
  resource: PropTypes.object,
  parent: PropTypes.object,
  mode: PropTypes.string,
};

export default CMSFaq;
