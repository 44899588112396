/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StandardExpand = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <line id="svg_6" y2="16" x2="24.0625" y1="16" x1="0" stroke="inherit" fill="none"/>
        <line id="svg_14" y2="20" x2="24.0625" y1="20" x1="0" stroke="inherit" fill="none"/>
        <g id="svg_15">
          <line id="svg_2" y2="8" x2="24.0625" y1="8" x1="0" stroke="inherit" fill="none"/>
          <line id="svg_3" y2="12" x2="24.0625" y1="12" x1="0" stroke="inherit" fill="none"/>
        </g>
        <rect stroke-width="0" id="svg_1" height="4" width="18.3125" y="0" x="5.6875" stroke="inherit" fill="inherit"/>
        <line id="svg_17" y2="1" x2="2.5" y1="3.5" x1="2.5" stroke="inherit" fill="none"/>
        <line id="svg_18" y2="2.125" x2="4" y1="2.125" x1="1" stroke="inherit" fill="none"/>
      </g>
    </SvgIcon>
  );
};

StandardExpand.propTypes = {};

export default StandardExpand;
