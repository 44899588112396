import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import Input from "@mui/material/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FormControl from "@mui/material/FormControl";

function DebouncedInputStandard({ type, label, placeholder, value, handleChange, outlined }){
  const { t } = useTranslation();
  const [currentValue, setCurrentValue]= React.useState(value);
  const ref = React.useRef(null);

  const debouncedChangeHandler = React.useMemo(
    () => debounce((e) => handleChange(e.target.value), 500)
    , []);

  React.useEffect(() => {
    if(type !== 'search'){
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <FormControl
      sx={{
        m: 0,
        letterSpacing: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
      }}
      fullWidth
      variant="standard"
    >
      <Input
        placeholder={placeholder || ''}
        id="debounced-input"
        type="text"
        inputRef={ref}
        defaultValue={currentValue}
        onChange={debouncedChangeHandler}
        multiline
        sx={{
          '&.MuiInput-root': {
            lineHeight: 'inherit',
            letterSpacing: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            textAlign: 'inherit',
            color: 'inherit',
            p: 0,
            '& textarea': {
              textAlign: 'inherit',
            }
          }
        }}
      />

    </FormControl>
  );
}

DebouncedInputStandard.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default DebouncedInputStandard;
