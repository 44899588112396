import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import CMSWithImage from "./CMSWithImage";
import CMSStandard from "./CMSStandard";
import CMSImage from "./CMSImage";
import {updateCmsArticle, updateJoyrideCmsArticle} from "../../general/redux/actions";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import {useDispatch} from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CustomExpandMore from "../../general/components/CustomExpandMore";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import {getBoxStyle, getTextColor, isBox, isExpandable, isFullSection, isImageRounded, isMultiCol} from "../utils/cms";

function CMSExpandableWithImage({ resource, joyrideMark, mode, parent}){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const boxedWithImage = Boolean(parent)
    && isBox(resource)
    && !isImageRounded(resource)
    && (resource.label.indexOf('above') !== -1 || resource.label.indexOf('below') !== -1);

  const boxPt = boxedWithImage ? getBoxStyle(resource, 'boxPaddingTop', 0) : 0;
  const boxPl = boxedWithImage ? getBoxStyle(resource, 'boxPaddingLeft', 2) : 0;
  const boxPr = boxedWithImage ? getBoxStyle(resource, 'boxPaddingRight', 2) : 0;
  const boxPb = boxedWithImage ? getBoxStyle(resource, 'boxPaddingBottom', 0) : 0;
  const smallFontSx = isMultiCol(parent, true)  ? {
    '& h2': {
      fontSize: '115%',
    }
  }: {};

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {(!isExpandable(resource) || isBox(resource)) && resource.label.indexOf('abovetext') !== -1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 1,
              alignItems: 'center'
            }}
          >
            <CMSImage
              resource={resource}
              parent={parent}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexGrow: 1
          }}
        >
          {resource.label.indexOf('leftnoFloat') !== -1 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mr: 8
              }}
            >
              <CMSImage
                resource={resource}
                parent={parent}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              pt: boxPt,
              pb: boxPb,
              pl: boxPl,
              pr: boxPr,
              ...smallFontSx
            }}
          >
            {isExpandable(resource) ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: isFullSection(resource) ? 'center' : 'flex-start',
                    pr: isFullSection(resource) ? 4 : 2
                  }}
                >
                  <Typography variant="h2" component="div">
                    <CustomExpandMore
                      buttonSx={{py: 1, pr: 0.5, pl: 0}}
                      iconCollapsed={<AddCircleIcon sx={{width: 32, height: 32}} fontSize="inherit" />}
                      iconExpanded={<RemoveCircleIcon sx={{width: 32, height: 32}} fontSize="inherit" />}
                      expanded={mode !== 'preview' ? true : expanded}
                      handleChange={handleExpandClick}
                    />
                  </Typography>
                  <Box
                    onClick={mode !== 'preview' ? null : handleExpandClick}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <EditableTextSlim
                      handleChange={(val) => {
                        dispatch(updateCmsArticle({
                          rId: resource.resourceId,
                          name: 'title',
                          val
                        }))
                      }}
                      color={getTextColor(resource)}
                      currentValue={resource.title}
                      variant="h2"
                      placeholder={t('cms.field.title.placeholder')}
                      disabled={mode !== 'edit'}
                    />
                  </Box>
                </Box>
                <Collapse in={mode !== 'preview' ? true : expanded} timeout="auto" unmountOnExit sx={{pl: 5}}>
                  {(resource.label.indexOf('noFloat') !== -1 || (isExpandable(resource) && isBox(resource))) ? (
                    <CMSStandard
                      resource={resource}
                      noteOnly
                      mode={mode}
                    />
                  ) : (
                    <CMSWithImage
                      resource={resource}
                      parent={parent}
                    />
                  )}
                </Collapse>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  alignItems: isFullSection(resource) ? 'center' : 'flex-start',
                  pr: isFullSection(resource) ? 4 : 2
                }}
              >
                <EditableTextSlim
                  handleChange={(val) => {
                    const dFunc = joyrideMark ? updateJoyrideCmsArticle : updateCmsArticle;
                    dispatch(dFunc({
                      rId: resource.resourceId,
                      name: 'title',
                      val
                    }))
                  }}
                  color={getTextColor(resource)}
                  currentValue={resource.title}
                  variant="h2"
                  placeholder={t('cms.field.title.placeholder')}
                  disabled={mode !== 'edit'}
                  joyrideMark={joyrideMark}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    alignItems: 'flex-start'
                  }}
                >
                  {resource.label.indexOf('fulltextimageleft') !== -1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mr: isFullSection(resource) ? 14 : 4
                      }}
                    >
                      <CMSImage
                        resource={resource}
                        parent={parent}
                      />
                    </Box>
                  )}
                  <EditableTextAreaSlim
                    name="note"
                    inline
                    resource={resource}
                    placeholder={t('cms.field.note.placeholder')}
                    currentValue={resource.note}
                    color={getTextColor(resource)}
                    handleChange={(val) => {
                      const dFunc = joyrideMark ? updateJoyrideCmsArticle : updateCmsArticle;
                      dispatch(dFunc({
                        rId: resource.resourceId,
                        name: 'note',
                        val
                      }))
                    }}
                    variant={isMultiCol(parent, true) ? 'body2' : 'body1'}
                    minHeight={0}
                    maxWidth={1152}
                    disabled={mode !== 'edit'}
                    joyrideMark={joyrideMark}
                  />
                  {resource.label.indexOf('fulltextimageright') !== -1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: isFullSection(resource) ? 14 : 4
                      }}
                    >
                      <CMSImage
                        resource={resource}
                        parent={parent}
                        joyrideMark={joyrideMark}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
            {resource.label.indexOf('rightnoFloat') !== -1 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  ml: isFullSection(resource) ? 14 : 8
                }}
              >
                <CMSImage
                  resource={resource}
                  parent={parent}
                />
              </Box>
            )}
          </Box>
        {(!isExpandable(resource) || isBox(resource)) && resource.label.indexOf('belowtext') !== -1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 1,
              alignItems: 'center',
            }}
          >
            <CMSImage
              resource={resource}
              parent={parent}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

CMSExpandableWithImage.propTypes = {
  resource: PropTypes.object,
};

export default CMSExpandableWithImage;
