import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Cropper from "react-cropper";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Loading from "../../general/components/Loading";
import "cropperjs/dist/cropper.css";

function CMSImageCropper(
  {
    imgUrl,
    aspect = 16 / 9,
    widt,
    height,
    loading,
    setLoading,
    cropData,
    setCropData,
    cropper,
    setCropper,
    cropperRef
  }
){
  const { t } = useTranslation();
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropperElement = imageElement?.cropper;
    setCropData(cropperElement.getCroppedCanvas().toDataURL());
  };


  return (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', position: 'relative'}}>
        <Box component={Paper} elevation={0} sx={{mx:'auto', position: 'relative', p: 1, mb: 2, minHeight: 460}}>
          {loading && <Loading />}
          <Cropper
            src={imgUrl}
            ref={cropperRef}
            style={{ height: 460, width: 512 }}
            // initialAspectRatio={aspect}
            aspectRatio={aspect}
            crop={onCrop}
            // Cropper.js options
            zoomTo={0}
            zoomable
            viewMode={0}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            rotatable
            wheelZoomRatio={0.2}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            ready={() => {
              setLoading(false);
            }}
            guides={true}
          />
        </Box>
        {/*<Alert variant="outlined" severity="info" sx={{mb: 2}}>*/}
        {/*  <Typography color="inherit" variant="body2" dangerouslySetInnerHTML={{__html: t('image.crop.notice')}} />*/}
        {/*</Alert>*/}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 320,
          minHeight: 460,
          pl: 2,
          pr: 1,
          pt: 2,
          borderLeft: '1px solid',
          borderColor: "divider"
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: "100%",
            p: 2
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: "100%",
              minHeight: 150,
              p: 2,
              border: '1px solid',
              borderColor: 'secondary.main'
            }}
          >
            {loading && <Loading />}
            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CMSImageCropper.propTypes = {
  imgUrl: PropTypes.string,
};

export default CMSImageCropper;
