/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BoxImageAboveTextToggle = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <ellipse stroke="inherit" ry="4.24997" rx="4.37497" id="svg_2" cy="6" cx="12" fill="none"/>
        <line stroke="inherit" id="svg_3" y2="14" x2="23.875" y1="14" x1="-0.125" fill="none"/>
        <line stroke="inherit" id="svg_4" y2="17" x2="24" y1="17" x1="0" fill="none"/>
        <line stroke="inherit" id="svg_5" y2="20" x2="24" y1="20" x1="0" fill="none"/>
        <line stroke="inherit" id="svg_6" y2="23" x2="24" y1="23" x1="0" fill="none"/>
      </g>
    </SvgIcon>
  );
};

BoxImageAboveTextToggle.propTypes = {};

export default BoxImageAboveTextToggle;
