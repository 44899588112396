/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FullTextImageRightSide = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <rect id="svg_11" height="5" width="9" y="8" x="14.5625" stroke="inherit" fill="none"/>
        <rect stroke-width="0" id="svg_1" height="4.125" width="24" y="0" x="0" stroke="inherit" fill="inherit"/>
        <line id="svg_6" y2="16" x2="24.0625" y1="16" x1="0" stroke="inherit" fill="none"/>
        <line id="svg_14" y2="20" x2="24.0625" y1="20" x1="0" stroke="inherit" fill="none"/>
        <g id="svg_15">
          <line id="svg_2" y2="8" x2="12.1875" y1="8" x1="0.0625" stroke="inherit" fill="none"/>
          <line id="svg_3" y2="12" x2="12.1875" y1="12" x1="0.0625" stroke="inherit" fill="none"/>
        </g>
      </g>
    </SvgIcon>
  );
};

FullTextImageRightSide.propTypes = {};

export default FullTextImageRightSide;
