import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {alpha} from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CMSStandard from "./CMSStandard";
import CMSExpandableWithImage from "./CMSExpandableWithImage";
import CMSImageWithOverlay from "./CMSImageWithOverlay";
import CMSSlider from "./CMSSlider";
import CMSArticleEdit from "./CMSArticleEdit";
import CMSGenericItem from "./CMSGenericItem";
import CMSFaq from "./CMSFaq";
import CMSAttachmentOnly from "./CMSAttachmentOnly";
import {setCmsActiveId, setCmsCopy} from "../../general/redux/actions";
import {getBorderColor, getBoxColor, getBoxStyle, isBox, isFullSection} from "../utils/cms";

function CMSArticle(
  {
    resource,
    mode,
    activeId,
    joyrideMark,
  }
){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [item, setItem] = React.useState({...resource});
  const [highlight, setHighLight] = React.useState(false);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);

  React.useEffect(() => {
    setItem({...resource});
    return () => {};
  },[resource]);

  const handleCmsType = (item, fullize) => {
    switch(item.label){
      case 'imageTextOverlay':
        return (
          <CMSImageWithOverlay
            resource={item}
            mode={mode}
          />
        );
      case 'attachmentonly':
        return (
          <CMSAttachmentOnly
            resource={item}
            mode={mode}
          />
        );
      case 'fulltextimageleftside':
      case 'fulltextimagerightside':
      case 'imageabovetext':
      case 'imagebelowtext':
      case 'imageleftnoFloat':
      case 'imagerightnoFloat':
        return (
          <CMSExpandableWithImage
            resource={item}
            mode={mode}
            joyrideMark={joyrideMark}
          />
        )
      case 'h1only':
      case 'h2only':
      case 'h3only':
      case 'h4only':
      case 'textonly':
      case 'standard':
      case 'standardcenter':
        return (
          <CMSStandard
            resource={item}
            mode={mode}
          />
        );
      case 'faq':
        return (
          <CMSFaq
            resource={item}
            mode={mode}
          />
        )
      default:
        return (
          <CMSGenericItem
            resource={item}
            mode={mode}
          />
        );
    }
  }

  const highlightArticle = (state) => {
    setHighLight(state)
  }

  const activateArticle = () => {
    dispatch(setCmsActiveId(resource.resourceId))
  }

  const deactivateArticle = (e) => {
    if(!e.target.closest('.cms-non-closable')) {
      dispatch(setCmsActiveId(''))
    }
  }

  const deactivateCopy = (e) => {
    if(
      !e.target.closest('.cms-article-copy-bar')
      && !e.target.closest('.cms-non-closable')
      && !e.target.closest('.cms-edit-buttons')
      && !e.target.closest('.cms-article-structure-bar')
    ) {
      dispatch(setCmsCopy([]))
    }
  };

  const getClassName = (joyrideMark, label) => {
    if(joyrideMark){
      return `joyride-article-root cms-article-root ${label}`;
    }
    return `cms-article-root ${label}`
  }

  const isTitle = ['h1only', 'h2only', 'h3only', 'h4only', 'h5only', 'h6only'].indexOf(item ? item.label : '') !== -1;
  const boxPt = getBoxStyle(item, 'boxPaddingTop', 4);
  const boxPl = getBoxStyle(item, 'boxPaddingLeft', 4);
  const boxPr = getBoxStyle(item, 'boxPaddingRight', 4);
  const boxPb = getBoxStyle(item, 'boxPaddingBottom', 4);
  const boxColor = getBoxColor(item);
  const bColor = getBorderColor(item);
  const bRadius = getBoxStyle(item, 'borderRadius', 0);
  const bWidth = getBoxStyle(item, 'borderWidth', 0);

  const handleCurrentWidth = (hasSidebar, fullsize) => {
    if(hasSidebar && !fullsize){
      return 944;
    }
    if(!hasSidebar && fullsize){
      return 1456;
    }
    return 1200;
  }

  return (
    <Box
      className={getClassName(joyrideMark, item ? item.label : '')}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mb: isTitle ? 1: 4,
        width: handleCurrentWidth(cmsSidebar, isFullSection(item)),
        mx: 'auto',
        backgroundColor: isBox(item) ? boxColor  : 'initial',
        borderRadius: bRadius,
        border: bWidth ? `${bWidth}px solid` : 'none',
        borderColor: bColor,
      }}
    >
      {/*{(highlight || (item && activeId === item.resourceId)) && (*/}
      {/*  <Typography*/}
      {/*    component="div"*/}
      {/*    variant="body1"*/}
      {/*    sx={{*/}
      {/*      position: 'absolute',*/}
      {/*      top:'calc(100% + 4px)',*/}
      {/*      left:0,*/}
      {/*      zIndex: 15,*/}
      {/*      ml: 2,*/}
      {/*      p: 0.5,*/}
      {/*      maxHeight: 32,*/}
      {/*      cursor: 'pointer',*/}
      {/*      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {t(`cms.type.${item ? item.label : ''}`)}*/}
      {/*  </Typography>*/}
      {/*)}*/}
      <Box
        className="cms-article-frame"
        component={Paper}
        elevation={0}
        onMouseEnter={mode === 'structure' ? () => highlightArticle(true) : null}
        onMouseLeave={mode === 'structure' ? () => highlightArticle(false) : null}
        onClick={mode === 'structure' ? activateArticle : null}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          cursor: 'pointer',
          backgroundColor: highlight && !Boolean(activeId === resource.resourceId) ? (theme) => alpha(theme.palette.secondary.main, 0.25) : 'transparent',
        }}
      >
        <Box
          className="cms-article-frame-center"
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'inherit',
          }}
        >
          <Box
            className="cms-article-frame-inner"
            component="div"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              pt: isBox(item) ? boxPt : 1,
              pb: isBox(item) ? boxPb : 1,
              pl: !isBox(item) && (cmsSidebar || isFullSection(item)) ? 0 : boxPl,
              pr: !isBox(item) && (cmsSidebar || isFullSection(item)) ? 0 : boxPr,
            }}
          >
            <Box
              className={`cms-article-container cms-article-${item ? item.label : ''}`}
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              {handleCmsType(item, isFullSection(item), isBox(item))}
            </Box>
          </Box>
        </Box>
      </Box>
      {mode === 'structure' && Boolean(resource.resourceId === activeId) && (
        <CMSArticleEdit
          activateArticle={activateArticle}
          deactivateArticle={deactivateArticle}
          highlightArticle={highlightArticle}
          deactivateCopy={deactivateCopy}
          mode={mode}
          activeId={activeId}
        />
      )}
    </Box>
  );
}

CMSArticle.propTypes = {
  resource: PropTypes.object,
  mode: PropTypes.string,
  activeId: PropTypes.string,
  joyrideMark: PropTypes.bool,
};

export default CMSArticle;
