export const CMSGROUPS = {
  buttonGroupStandard: [
    "h1only",
    "h2only",
    "h3only",
    "h4only",
    "standard",
    "textonly",
    "faq",
    "attachmentonly",
  ],
  buttonGroupImage: [
    "imageabovetext",
    "imagebelowtext",
    "fulltextimageleftside",
    "fulltextimagerightside",
    "imageleftnoFloat",
    "imagerightnoFloat",
  ],
  buttonGroupStructure: [
    "slider",
    "multicol2",
    "multicol3",
    "multicol4",
    "sequenceContainer",
  ],
  buttonGroupSlider: [
    "standard",
    "textonly",
    "imageleftnoFloat",
    "imagerightnoFloat",
    "fulltextimageleftside",
    "fulltextimagerightside",
  ],
  buttonGroupSequence: [
    "sequenceItem",
  ],
  buttonGroupMulticol: [
    "standard",
    "imageabovetext",
    "imagebelowtext",
    "imageTextOverlay"
  ],
  buttonGroupSpecial: [
    "footnote",
    "newtab"
  ],
}

export const DEPRECTATED_TYPES = [
  "standard_exp",
  "standardSmall",
  "standardcenter",
  "standardBox01",
  "standardBox02",
  "standardBox03",
  "standardcenter",
  "imageabovetext_exp",
  "imagebelowtext_exp",
  "imageleftnoFloat_exp",
  "imagerightnoFloat_exp",
  "fulltextimageleftside_exp",
  "fulltextimagerightside_exp",
  "imageleftnoFloatFullSection",
  "imageleftnoFloatFullContainer",
  "imagerightnoFloatFullSection",
  "imagerightnoFloatFullContainer",
  "boximageabovetext",
  "boximageabovetextToggle",
  "boximagebelowtext",
  "imageabovetextBG01",
  "imageabovetextBG02",
  "imageabovetextBG03",
  "imageabovetextIcon",
];

export const DEFAULTBOXSTYLE = {
  box: false,
  boxColor: 'background.green',
  borderRadius: 1,
  boxPaddingTop: 4,
  boxPaddingLeft: 4,
  boxPaddingRight: 4,
  boxPaddingBottom: 4,
}

export const MAXIMAGEWIDTH = 1144;
export const MAXIMAGEHEIGHT = 768;
export const MAXIMAGEWIDTHSIDEBAR = 1200;
export const MAXIMAGEHEIGHTSIDEBAR = 640;