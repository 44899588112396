import React from 'react';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AnimationIcon from '@mui/icons-material/Animation';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import H1Only from "../../general/icons/cms/H1Only";
import H2Only from "../../general/icons/cms/H2Only";
import H3Only from "../../general/icons/cms/H3Only";
import H4Only from "../../general/icons/cms/H4Only";
import Standard from "../../general/icons/cms/Standard";
import ImageAboveText from "../../general/icons/cms/ImageAboveText";
import ImageBelowText from "../../general/icons/cms/ImageBelowText";
import FullTextImageLeftSide from "../../general/icons/cms/FullTextImageLeftSide";
import FullTextImageRightSide from "../../general/icons/cms/FullTextImageRightSide";
import ImageLeftNoFloat from "../../general/icons/cms/ImageLeftNoFloat";
import ImageRightNoFloat from "../../general/icons/cms/ImageRightNoFloat";
import TextOnly from "../../general/icons/cms/TextOnly";
import FullTextImageRightSideExpand from "../../general/icons/cms/FullTextImageRightSideExpand";
import FullTextImageLeftSideExpand from "../../general/icons/cms/FullTextImageLeftSideExpand";
import StandardExpand from "../../general/icons/cms/StandardExpand";
import ImageBelowTextExpand from "../../general/icons/cms/ImageBelowTextExpand";
import ImageAboveTextExpand from "../../general/icons/cms/ImageAboveTextExpand";
import ImageLeftNoFloatExpand from "../../general/icons/cms/ImageLeftNoFloatExpand";
import ImageRightNoFloatExpand from "../../general/icons/cms/ImageRightNoFloatExpand";
import Slider from "../../general/icons/cms/Slider";
import BoxImageAboveText from "../../general/icons/cms/BoxImageAboveText";
import ImageTextOverlay from "../../general/icons/cms/ImageTextOverlay";
import {fakeStringId} from "../../general/helpers";
import i18n from "../../general/i18n";
import MultiCol3 from "../../general/icons/cms/MultiCol3";
import MultiCol2 from "../../general/icons/cms/MultiCol2";
import MultiCol4 from "../../general/icons/cms/MultiCol4";
import MultiCol6 from "../../general/icons/cms/MultiCol6";
import {CMSGROUPS} from "./constants";
import BoxImageBelowText from "../../general/icons/cms/BoxImageBelowText";
import StandardCenter from "../../general/icons/cms/StandardCenter";
import BoxImageAboveTextToggle from "../../general/icons/cms/BoxImageAboveTextToggle";
import MultiCol5 from "../../general/icons/cms/MultiCol5";
import SequenceItem from "../../general/icons/cms/SequenceItem";

export const handleCmsTypeIcon = (type) => {
  switch(type){
    case 'h1only':
      return <H1Only color="secondary" />;
    case 'h2only':
      return <H2Only color="secondary" />;
    case 'h3only':
      return <H3Only color="secondary" />;
    case 'h4only':
      return <H4Only color="secondary" />;
    case 'textonly':
      return <TextOnly color="secondary" />;
    case 'standard':
      return <Standard color="secondary" />;
    case 'standardcenter':
      return <StandardCenter color="secondary" />;
    case 'standard_exp':
      return <StandardExpand color="secondary" />;
    case 'attachmentonly':
      return <AttachFileOutlinedIcon color="secondary" />
    case 'faq':
      return <QuestionMarkOutlinedIcon color="secondary" />
    case 'imageabovetext':
      return <ImageAboveText color="secondary" />
    case 'imagebelowtext':
      return <ImageBelowText color="secondary" />
    case 'imageabovetext_exp':
      return <ImageAboveTextExpand color="secondary" />
    case 'imagebelowtext_exp':
      return <ImageBelowTextExpand color="secondary" />
    case 'fulltextimageleftside':
      return <FullTextImageLeftSide color="secondary" />
    case 'fulltextimagerightside':
      return <FullTextImageRightSide color="secondary" />
    case 'fulltextimageleftside_exp':
      return <FullTextImageLeftSideExpand color="secondary" />
    case 'fulltextimagerightside_exp':
      return <FullTextImageRightSideExpand color="secondary" />
    case 'imageleftnoFloat':
    case 'imageleftnoFloatFullSection':
    case 'imageleftnoFloatFullContainer':
      return <ImageLeftNoFloat />
    case 'imagerightnoFloat':
    case 'imagerightnoFloatFullSection':
    case 'imagerightnoFloatFullContainer':
      return <ImageRightNoFloat color="secondary" />
    case 'imageleftnoFloat_exp':
      return <ImageLeftNoFloatExpand />
    case 'imagerightnoFloat_exp':
      return <ImageRightNoFloatExpand />
    case 'slider':
      return <Slider />
    case 'multicol2':
      return <MultiCol2 />
    case 'multicol3':
      return <MultiCol3 />
    case 'multicol4':
      return <MultiCol4 />
    case 'multicol5':
      return <MultiCol5 />
    case 'multicol6':
      return <MultiCol6 />
    case 'sequenceContainer':
      return <AnimationIcon />
    case 'sequenceItem':
      return <SequenceItem />
    case 'youtube':
      return <YouTubeIcon />;
    case 'imageTextOverlay':
      return <ImageTextOverlay />;
    case 'boximageabovetext':
      return <BoxImageAboveText />;
    case 'boximagebelowtext':
      return <BoxImageBelowText />;
    case 'boximageabovetextToggle':
      return <BoxImageAboveTextToggle />;
    case 'sequence':
    case 'newtab':
    case 'footnote':

    default:
      return 'I';
  }
}

export const checkCropNeed = (resource, expectedWidth, currentWidth, expectedHeight, currentHeight) => {
  const currentAspect = Math.round((currentWidth/currentHeight) *10) / 10;
  const expectedAspect = Math.round((expectedWidth/expectedHeight) *10) / 10;

  if(expectedWidth < currentWidth && expectedHeight < currentHeight){
      if(currentAspect !== expectedAspect){
        return true;
      }
  }

  if(
    (expectedWidth > currentWidth && expectedHeight < currentHeight)
    || (expectedWidth < currentWidth && expectedHeight > currentHeight)
  ){
    if(currentAspect !== expectedAspect){
      return true;
    }
  }

  return false;
}
export const checkTypeHas = (prop, type) => {
  switch(prop){
    case 'title':
      return [
        'fulltextimageleftside',
        'fulltextimagerightside',
        'imageabovetext',
        'imagebelowtext',
        'imageleftnoFloat',
        'imagerightnoFloat',
        'fulltextimageleftside_exp',
        'fulltextimagerightside_exp',
        'faq',
        'standard_exp',
        'standard',
        'standardcenter',
        'h1only',
        'h2only',
        'h3only',
        'h4only'
      ].indexOf(type) !== -1;
    case 'note':
      return [
        'fulltextimageleftside',
        'fulltextimagerightside',
        'imageabovetext',
        'imagebelowtext',
        'imageleftnoFloat',
        'imagerightnoFloat',
        'fulltextimageleftside_exp',
        'fulltextimagerightside_exp',
        'imageabovetext_exp',
        'imagebelowtext_exp',
        'imageleftnoFloat_exp',
        'imagerightnoFloat_exp',
        'faq',
        'standard_exp',
        'standard',
        'standardcenter',
        'textonly'
      ].indexOf(type) !== -1;
    case 'image':
      return [
        'fulltextimageleftside',
        'fulltextimagerightside',
        'imageabovetext',
        'imagebelowtext',
        'imageleftnoFloat',
        'imagerightnoFloat',
        'fulltextimageleftside_exp',
        'fulltextimagerightside_exp',
        'attachmentonly'
      ].indexOf(type) !== -1;
    default:
      return false;
  }

};

export const checkTitleVariant = (type) => {
  switch (type) {
    case 'h1only':
    case 'h2only':
    case 'h3only':
    case 'h4only':
      return type.replace('only', '');
    default:
      return 'h2';
  }
};


export const getSubRanK = (rank) => {
  let subRank = rank;
  while (subRank > 100) {
    subRank = subRank - 100;
  }
  return subRank;
}

export const createCMSTopic = (create, parentId, projectId, item, rankToUse, authorId, label = 'standard') => {
  if(!item || create){
    return {
      label,
      type: rankToUse % 100 === 0 ? 'cms_article' : 'cms_article_child',
      id: null,
      resourceId: fakeStringId(),
      title: i18n.t('cms.field.title.dummy'),
      note: i18n.t('cms.field.note.dummy'),
      qualifier: '',
      attachments: [],
      rank: rankToUse,
      parentId,
      projectId,
      published: true,
      markedForDeletion: false,
      authorId: authorId,
      properties: {
        cmsStyle: {}
      }
    }
  }

  return {
    label: item.label || 'standard',
    type: rankToUse % 100 === 0 ? 'cms_article' : 'cms_article_child',
    id: item.id,
    resourceId: item.resourceId.indexOf('created') !== -1 ? null : item.resourceId,
    title: item.title,
    note: item.note,
    rank: rankToUse,
    parentId,
    projectId,
    published: item.published,
    markedForDeletion: item.markedForDeletion,
    properties: {
      ...item.properties,
    }
  }
}

export const createSubArticles = (count, create, parentId, projectId, item, rankToUse, authorId, type) => {
  const subArticles = [];
  let outerCount = 0;

  for (let i = 0; i < count; i++) {
    outerCount++;
    const currentRankToUSe = rankToUse + (i+outerCount)
    subArticles.push(createCMSTopic(create, parentId, projectId, item, currentRankToUSe, authorId, type))
  }

  return subArticles;
};

export const createCMSArticles = (type, create, parentId, projectId, item, rankToUse, authorId) => {
  const baseArticles = [createCMSTopic(create, parentId, projectId, item, rankToUse, authorId, type)];
  switch(type){
    case 'sequenceContainer':
      return [
        ...baseArticles,
        ...createSubArticles(3, create, parentId, projectId, item, rankToUse, authorId, 'sequenceItem'),
      ];
    case 'slider':
      return [
        ...baseArticles,
        ...createSubArticles(3, create, parentId, projectId, item, rankToUse, authorId, 'fulltextimageleftside'),
      ];
    case 'multicol2':
      return [
        ...baseArticles,
        ...createSubArticles(2, create, parentId, projectId, item, rankToUse, authorId, 'imageabovetext'),
      ];
    case 'multicol3':
      return [
        ...baseArticles,
        ...createSubArticles(3, create, parentId, projectId, item, rankToUse, authorId, 'imageabovetext'),
      ];
    case 'multicol4':
      return [
        ...baseArticles,
        ...createSubArticles(4, create, parentId, projectId, item, rankToUse, authorId, 'imageabovetext'),
      ];
    case 'multicol5':
      return [
        ...baseArticles,
        ...createSubArticles(5, create, parentId, projectId, item, rankToUse, authorId, 'imageabovetext'),
      ];
    case 'multicol6':
      return [
        ...baseArticles,
        ...createSubArticles(6, create, parentId, projectId, item, rankToUse, authorId, 'imageabovetext'),
      ];
    default:
      return baseArticles;
  }
}

export const normalizeCMSItem = (create, item, type, jId, pId) => {
  switch(type){
    case 'topic':
      return createCMSTopic(create, jId, pId, item, item.rank);
    default:
      return {...item};

  }
}

const determineParentRank = (rank) => {
  const nearest100 = Math.round(rank / 100) * 100;
  if(nearest100 > rank){
    return nearest100 - 100;
  }
  return nearest100;
}

export const getArticleParent = (data, article) => {
  const targetRank = article ? determineParentRank(article.rank) : 0;
  return (data || []).find((item) => item.rank === targetRank);
}

export const getContainerChildLength = (cmsData, index) => {
  let childLength = 0;
  for(let checkIndex = index; checkIndex < cmsData.length -1; checkIndex++){
    if(cmsData[checkIndex + 1].type === 'cms_article_child'){
      childLength++;
    } else {
      break;
    }
  }

  return childLength;
}

export const getLastChildIndexByParentArticle = (cmsData, index) => {
  let lastChildIndex;
  for(let checkIndex = index + 1; checkIndex < cmsData.length; checkIndex++){
    if(cmsData[checkIndex].type === 'cms_article_child'){
      lastChildIndex = checkIndex;
    } else {
      break;
    }
  }

  return lastChildIndex;

}

export const checkNextIsDisabled = (cmsData, activeId) => {
  const currentArticle = cmsData.find((it) => it.resourceId === activeId);
 if(CMSGROUPS.buttonGroupStructure.indexOf(currentArticle.label) !== -1){
   const currentArticleIndex = cmsData.findIndex((it) => it.resourceId === activeId);
   return getLastChildIndexByParentArticle(cmsData, currentArticleIndex) === cmsData.length - 1;
 }

  return cmsData.findIndex((it) => it.resourceId === activeId) === cmsData.length - 1;
}

export const getAddPosition = (isStructureArticle, item, data) => {
  const index = data.findIndex((it) => it.rank === item.rank);

  if(!isStructureArticle){
    return index + 1;
  }
  const itemIndex = data.findIndex((it) => it.resourceId === item.resourceId);
  const childLength = getContainerChildLength(data, itemIndex)

  return index + childLength + 1;
};

export const copyCmsArticle = (aId, cData) => {
  const article = cData.find((it) => it.resourceId === aId);
  const articles = [{...article}];

  if(CMSGROUPS.buttonGroupStructure.indexOf(article.label) !== -1){
    const articleIndex = cData.findIndex((it) => it.resourceId === article.resourceId);
    const childLength = getContainerChildLength(cData, articleIndex);
    let childIndex = articleIndex + 1;
    for(let i = 0; i < childLength; i++){
      articles.push({...cData[childIndex]});
      childIndex++;
    }
  }

  return articles
    .map((it) => {
      const newRId = fakeStringId();
      return {
        ...it,
        resourceId: newRId,
        id: null,
        attachments: []
      }
    });
}