/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MultiCol2 = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path id="svg_9" d="m-10.95825,6.50004l7.5,0l0,8.29166l-7.5,0l0,-8.29166z" opacity="undefined" fill="#ffffff" stroke="inherit"/>
        <path stroke="#fff" stroke="inherit" opacity="undefined" d="m29.54159,1.06253l23.0833,0l0,4.37499l-23.0833,0l0,-4.37499z" id="svg_1"/>
        <rect stroke="inherit" fill="none" x="0.75001" y="4.56251" width="22.24998" height="15.06248" id="svg_2"/>
        <path fill="none" d="m8.33937,9.34946l3,-4.7386l3,4.7386l-1.5,0l0,4.7614l-3,0l0,-4.7614l-1.5,0z" id="svg_6" stroke="inherit" stroke-width="0"/>
        <rect stroke="inherit" id="svg_7" height="10.68749" width="7.93749" y="6.78125" x="2.75001" fill="#fff"/>
        <path id="svg_10" d="m13,6.71875l7.87499,0l0,10.68749l-7.87499,0l0,-10.68749z" opacity="undefined" stroke="inherit" fill="#fff"/>
      </g>

    </SvgIcon>
  );
};

MultiCol2.propTypes = {};

export default MultiCol2;
