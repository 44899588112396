import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel'
import CMSContainerArticle from "./CMSContainerArticle";
import {useSelector} from "react-redux";

function CMSSlider(
  {
    resource,
    mode,
    disabled,
    activeId,
    items,
    highlightParent,
    deactivateArticle
  }
){
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);

  return (
    <Box
      sx={{
        '& .cms-slider': {
          overflow:  Boolean((items || []).find((it) => it.resourceId === activeId)) ? 'visible' : 'hidden'
        }
      }}
    >
      <Carousel
        className="cms-slider"

        animation="fade"
        swipe={mode === 'preview'}
        // navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={
          mode === 'structure' &&
          Boolean(resource.resourceId === activeId || items.find((it) => it.resourceId === activeId))
        }
        autoPlay={mode === 'preview'}
        next={ (next, active) => deactivateArticle() }
        prev={ (prev, active) => deactivateArticle() }
      >
        {
          (items || [])
            .map( (item, i) => {
              return (
                <Box
                  key={item.resourceId}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    px: 0,
                    minHeight: cmsSidebar ? 344 : 448,
                  }}
                >
                  <CMSContainerArticle
                    parent={resource}
                    mode={mode}
                    resource={item}
                    activeId={activeId}
                    highlightParent={highlightParent}
                  />
                </Box>
              )
            })
        }
      </Carousel>
    </Box>
  );
}

CMSSlider.propTypes = {
  resource: PropTypes.object,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CMSSlider;
