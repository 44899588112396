import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CMSContainerArticle from "./CMSContainerArticle";
import {useSelector} from "react-redux";

const getColumnFlex = (label) => {
  switch(label){
    case 'multicol6':
      return '16.66%';
    case 'multicol6':
      return '20%';
    case 'multicol4':
      return '25%';
    case 'multicol3':
      return '33.33%';
    default:
      return '50%'
  }
}

const getDisplayedItems = (items = [], label) => {
  const displayItems = [...items];
  switch(label){
    case 'multicol2':
      displayItems.length = 2;
    case 'multicol3':
      displayItems.length = 3;
    case 'multicol4':
      displayItems.length = 4;
    case 'multicol5':
      displayItems.length = 5;
    case 'multicol6':
      displayItems.length = 6;
    default:
      break;
  }
  return displayItems;
}
function CMSColContainer(
  { resource,
    mode,
    disabled,
    activeId,
    items,
    highlightParent,
    deactivateArticle,
  }
){
  const flex = getColumnFlex(resource.label);
  const displayedItems = getDisplayedItems(items, resource.label);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);

  const getContainerItemMaxWidth = (pLabel, hasSidebar) => {
    switch (pLabel) {
      case 'multicol2':
        return hasSidebar ? (540 /18)*15 : 540;
      case 'multicol3':
        return hasSidebar ? (340 /18)*15 : 340;
      case 'multicol4':
        return hasSidebar ? (240 /18)*15 : 240;
      case 'multicol5':
        return hasSidebar ? (196 /18)*15 : 196;
      case 'multicol6':
        return hasSidebar ? (140 /18)*15 : 140;
      default:
        return 'unset'
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      {
        (displayedItems || []).map( (item, i) => {
          return (
            <Box
              key={item.resourceId}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex,
                px: 0,
                maxWidth: getContainerItemMaxWidth(resource.label, cmsSidebar)
              }}
            >
              <CMSContainerArticle
                parent={resource}
                mode={mode}
                resource={item}
                activeId={activeId}
                highlightParent={highlightParent}
              />
            </Box>
          )
        } )
      }
    </Box>
  );
}

CMSColContainer.propTypes = {
  resource: PropTypes.object,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CMSColContainer;
