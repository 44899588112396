import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {styled} from "@mui/material/styles";
import Box from '@mui/material/Box';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {CMSGROUPS} from "../utils/constants";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import {getArticleParent, handleCmsTypeIcon} from "../utils/helpers";
import {useSelector} from "react-redux";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const sliceBySize = (items, size) => {
  const useItems = [...items];
  const returnItems = [];
  const colLength = Math.round(useItems.length / size);
  for(let i = 0; i < colLength;i++){
    let part = useItems.length && useItems.length >= size ? useItems.slice(0,size) : useItems.slice(0,useItems.length);

    returnItems.push(part);
    if(useItems.length) {
      useItems.splice(0,part.length);
    }
  }
  return returnItems;
}

function CMSTypeVerticalButtonGroup(
  {
    cmsData,
    activeId,
    value,
    handleChange,
    add,
  }
){
  const { t } = useTranslation();
  const labels = useSelector((state) => state.default.labels);
  const activeItem = (cmsData || []).find((item) => item.resourceId === activeId);

  const standardGroups = sliceBySize((labels || []).filter((label) => CMSGROUPS.buttonGroupStandard.indexOf(label) !== -1), 2);
  const imageGroups = sliceBySize((labels || []).filter((label) => CMSGROUPS.buttonGroupImage.indexOf(label) !== -1),2);
  const containerGroups = sliceBySize((labels || []).filter((label) => CMSGROUPS.buttonGroupStructure.indexOf(label) !== -1), 2);
  const multicolGroups = sliceBySize((labels || []).filter((label) => CMSGROUPS.buttonGroupMulticol.indexOf(label) !== -1), 2);
  const sequenceGroups = sliceBySize((labels || []).filter((label) => CMSGROUPS.buttonGroupSequence.indexOf(label) !== -1), 2);

  const isStructureArticle = activeItem && activeItem.type === 'cms_article' && CMSGROUPS.buttonGroupStructure.indexOf(activeItem.label) !== -1;
  const isSubArticle = activeItem && activeItem.type === 'cms_article_child';
  const parent = isSubArticle ? getArticleParent(cmsData, activeItem) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection:'column',
          '& .MuiToggleButton-root': {
            color: 'secondary.main',
            stroke: 'secondary.main',
            fill: 'secondary.main',
            '& svg': {
              color: 'secondary.main',
              stroke: (theme) => theme.palette.secondary.main,
              fill: 'secondary.main',
              pointerEvents: 'none',
            },
            backgroundColor: 'background.main',
            '&.Mui-selected': {
              '& svg': {
                color: '#fefefe',
                stroke: '#fefefe',
                fill: '#fefefe',
              },
              color: '#fefefe',
              stroke: '#fefefe',
              fill: '#fefefe',
              backgroundColor: 'secondary.main'
            }
          }
        }}
      >
        {Boolean((!isStructureArticle && !isSubArticle) || add || (isSubArticle && parent && parent.label === 'slider')) && (
          <>
            {standardGroups.map((group, index) => {
              return (
                <StyledToggleButtonGroup
                  key={index}
                  sx={{}}
                  color="secondary"
                  orientation="horizontal"
                  value={value}
                  exclusive
                  size="small"
                  onChange={handleChange}
                  aria-label="cms types"
                >
                  {(group || []).map((label) => {
                    const translationLabel = t(`cms.type.${label}`);
                    return (
                      <ToggleButton key={label} value={label} aria-label={translationLabel}>
                        <Tooltip followCursor placement="right-end" title={translationLabel}>
                          <span>{handleCmsTypeIcon(label)}</span>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
              );
            })}
            {imageGroups.map((group, index) => {
              return (
                <StyledToggleButtonGroup
                  key={index}
                  sx={{}}
                  color="secondary"
                  orientation="horizontal"
                  value={value}
                  exclusive
                  size="small"
                  onChange={handleChange}
                  aria-label="cms types"
                >
                  {(group || []).map((label) => {
                    const translationLabel = t(`cms.type.${label}`);
                    return (
                      <ToggleButton key={label} value={label} aria-label={translationLabel}>
                        <Tooltip followCursor placement="right-end" title={translationLabel}>
                          <span>{handleCmsTypeIcon(label)}</span>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
              );
            })}
          </>
        )}
        {Boolean(add || isStructureArticle) && (
          <>
            {containerGroups.map((group, index) => {
              return (
                <StyledToggleButtonGroup
                  key={index}
                  sx={{}}
                  color="secondary"
                  orientation="horizontal"
                  value={value}
                  exclusive
                  size="small"
                  onChange={handleChange}
                  aria-label="cms types"
                >
                  {(group || []).map((label) => {
                    const translationLabel = t(`cms.type.${label}`);
                    return (
                      <ToggleButton key={label} value={label} aria-label={translationLabel}>
                        <Tooltip followCursor placement="right-end" title={translationLabel}>
                          <span>{handleCmsTypeIcon(label)}</span>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
              );
            })}
          </>
        )}
        {isSubArticle && parent && parent.label !== 'slider' && parent.label !== 'sequenceContainer' && (
          <>
            {multicolGroups.map((group, index) => {
              return (
                <StyledToggleButtonGroup
                  key={index}
                  sx={{}}
                  color="secondary"
                  orientation="horizontal"
                  value={value}
                  exclusive
                  size="small"
                  onChange={handleChange}
                  aria-label="cms types"
                >
                  {(group || []).map((label) => {
                    const translationLabel = t(`cms.type.${label}`);
                    return (
                      <ToggleButton key={label} value={label} aria-label={translationLabel}>
                        <Tooltip followCursor placement="right-end" title={translationLabel}>
                          <span>{handleCmsTypeIcon(label)}</span>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
              );
            })}
          </>
        )}
        {isSubArticle && parent && parent.label === 'sequenceContainer' && (
          <>
            {sequenceGroups.map((group, index) => {
              return (
                <StyledToggleButtonGroup
                  key={index}
                  sx={{}}
                  color="secondary"
                  orientation="horizontal"
                  value={value}
                  exclusive
                  size="small"
                  onChange={handleChange}
                  aria-label="cms types"
                >
                  {(group || []).map((label) => {
                    const translationLabel = t(`cms.type.${label}`);
                    return (
                      <ToggleButton key={label} value={label} aria-label={translationLabel}>
                        <Tooltip followCursor placement="right-end" title={translationLabel}>
                          <span>{handleCmsTypeIcon(label)}</span>
                        </Tooltip>
                      </ToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
}

CMSTypeVerticalButtonGroup.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default CMSTypeVerticalButtonGroup;
