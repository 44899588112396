import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Zoom from '@mui/material/Zoom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import { setConfirmation } from '../../general/redux/actions';

const Transition = React.forwardRef((props, ref) => {
  const { open } = props;
  return <Zoom style={{ transitionDelay: open ? '500ms' : '0ms' }} ref={ref} {...props} />;
});

Transition.propTypes = {
  open: PropTypes.bool,
};

function Confirmation({ open, title, description, action, addition, close }) {
  const { t } = useTranslation();

  const handleConfirm = (additional) => {
    action(additional);
    close();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'visible',
        },
        zIndex: 1301,
        borderRadius: 0,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        sx={{
          display: 'flex',
          p: 0,
          m: 1,
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'warning.main'
        }}
        disableTypography
      >
        <WarningAmberIcon color="warning" sx={{ width: 40, height: 40 }}/>
        <Typography sx={{ mx: 2, my: 0 }} variant="h3">
          {t(title)}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: 1,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Typography
          variant="body1"
          component="div"
          sx={{pt: 1}}
          dangerouslySetInnerHTML={{
            __html: t(description),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{textTransform: 'none'}} variant="contained" onClick={() => handleConfirm()} color="secondary" size="small">
          {t(addition && addition.label2 ? addition.label2 : 'button.agree')}
        </Button>
        {addition && (
          <Button variant="contained" sx={{textTransform: 'none'}} onClick={() => handleConfirm(true)} color="warning" size="small">
            {t(addition.label1)}
          </Button>
        )}
        <Button variant="text" onClick={close} size="small" sx={{textTransform: 'none'}}>
          {t('button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Confirmation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  addition: PropTypes.object,
  action: PropTypes.func,
  close: PropTypes.func,
  open: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(setConfirmation(null)),
  };
};

export default connect(null, mapDispatchToProps)(Confirmation);
