import $ from 'jquery';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import store from '../general/redux/store';
import App from './App';
import {getMuiTheme, getMuiPlainTheme, muiCache} from "../general/themeCms";
import {CacheProvider} from "@emotion/react";
import DisplayApp from "./DisplayApp";

$(document).ready(function () {
  if($('#dito-cms-view').length > 0){
    render(
      <Provider store={store}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            <App/>
          </ThemeProvider>
        </CacheProvider>
      </Provider>,
      document.getElementById('dito-cms-view'),
    );
  }
  if($('#dito-cms-stage').length > 0){
    render(
      <Provider store={store}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiPlainTheme()}>
            <DisplayApp />
          </ThemeProvider>
        </CacheProvider>
      </Provider>,
      document.getElementById('dito-cms-stage'),
    );
  }
});