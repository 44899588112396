import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DebouncedInputStandard from "./DebouncedInputStandard";
import {alpha} from "@mui/material/styles";
import {useSelector} from "react-redux";

function EditableTextSlim ({
  currentValue,
  handleChange,
  variant = 'h2',
  disabled,
  placeholder,
  noInputBorder,
  center,
  color,
  joyrideMark
}) {
  const { t } = useTranslation();
  const cmsMode = useSelector((state) => state.default.cmsMode);
  const [editActive, setEditActive] = React.useState(false);
  const [activeValue, setActiveValue] = React.useState(currentValue);

  const handleChangeValue = (val) => {
    setActiveValue(val);
    handleChange(val)
  };

  const borderSx = noInputBorder ? {
    '& .MuiInputBase-root:before': {
      border: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiInputBase-root:after': {
      border: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):after': {
      border: 'none',
    },
  } : {};

  return (
    <ClickAwayListener onClickAway={disabled ? () => {} : () => setEditActive(false)}>
      {!editActive ? (
        <Typography
          className={joyrideMark ? 'cms-article-title joyride-title-edit' : "cms-article-title"}
          variant={variant}
          component={variant}
          sx={{
            py: !activeValue ? 0 : 0.5,
            cursor: disabled ? 'default': 'pointer',
            color: placeholder && !activeValue ? color || 'textSecondary' : color || 'textPrimary',
            '&:hover': {
              backgroundColor: !disabled
                ? (theme) => alpha(theme.palette.secondary.main, 0.25)
                : 'initial'
            },
            textAlign: center ? 'center' : 'left',
            minHeight: cmsMode === 'edit' ? 8 : 0,
          }}
          onClick={() => disabled ? null : setEditActive(true)}
        >
          {activeValue}
        </Typography>
      ) : (
        <Typography
          sx={{
            py: 0.5,
            ...borderSx,
            textAlign: center ? 'center' : 'left',
            color: color || 'inherit'
          }}
          variant={variant}
          component="div"
        >
          <DebouncedInputStandard
            type="simple"
            multiline
            placeholder={t('cms.field.title.placeholder')}
            handleChange={handleChangeValue}
            value={activeValue}
          />
        </Typography>
      )}
    </ClickAwayListener>
  )
}

EditableTextSlim.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default EditableTextSlim
