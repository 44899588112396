import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {alpha} from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function CMSEditClickAway(
  {
    mode,
    activateArticle,
    deactivateArticle,
    highlightArticle,
    deactivateCopy,
    cmsCopy,
  }
){
  const { t } = useTranslation();
  return (
    <ClickAwayListener onClickAway={Boolean(!cmsCopy || !cmsCopy.length) ? deactivateArticle : deactivateCopy}>
      <Box
        component="div"
        color="secondary"
        onMouseEnter={mode === 'structure' ? () => highlightArticle(true) : null}
        onMouseLeave={mode === 'structure' ? () => highlightArticle(false) : null}
        onClick={mode === 'structure' ? activateArticle : null}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          cursor: 'pointer',
          outline: '1px solid',
          outlineColor: (theme) => alpha(theme.palette.secondary.main, 1),
          outlineOffset: 2
        }}
      />
    </ClickAwayListener>
  );
}

CMSEditClickAway.propTypes = {
  activateArticle: PropTypes.func,
  deactivateArticle: PropTypes.func,
  highlightArticle: PropTypes.func
};

export default CMSEditClickAway;
