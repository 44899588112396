import {createTheme, responsiveFontSizes} from "@mui/material/styles";
import createCache from "@emotion/cache";
import {themeConfig} from "./theme";


export   const  getMuiTheme = () => createTheme({
  ...themeConfig,
  typography: {
    ...themeConfig.typography,
    h1: {
      ...themeConfig.typography.h1,
    },
    h2: {
      ...themeConfig.typography.h2,
    },
    h3: {
      ...themeConfig.typography.h3,
    },
    body1: {
      fontSize: 18,
      fontWeight: 300,
      lineHeight: 1.4
    }
  },
  palette: {
    ...themeConfig.palette,
    background: {
      ...themeConfig.palette.background,
      lightblue: '#def0f5',
      blue: '#7fc6d8',
      green: '#B2C84A',
      alphaBlue: 'rgba(238,238,238,0.6)'
    },
    text: {
      primary: '#333333',
      secondary: '#a3a3a3',
    },
  }
})

export   const  getMuiPlainTheme = () => createTheme({
  ...themeConfig,
  typography: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  palette: {
    ...themeConfig.palette,
    background: {
      ...themeConfig.palette.background,
      lightblue: '#def0f5',
      blue: '#7fc6d8',
      green: '#B2C84A',
    },
    text: {
      primary: '#333333',
      secondary: '#a3a3a3',
    },
  }
})


export const muiCache = createCache({
  "key": "mui",
  "prepend": true
});