import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CMSStage from "./CMSStage";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgba(0,0,0,0.1)',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 224,
  minHeight: 120
}));

function CMSRoot({ }){
  const { t } = useTranslation();
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);
  return (
    <Box
      component={Paper}
      elevation={0}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'flex-start',
        width: 'auto%',
        px: 0,
        mt: 2,
        mx: 'auto',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 960,
          maxWidth: 1456,
          flexGrow: 1,
          width: cmsSidebar ? 1220 : 1456,
        }}
      >
        <CMSStage />
      </Box>
      {cmsSidebar && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 256,
            py: 4,
            minHeight: '100%',
            // borderLeft: '1px solid',
            // borderColor: 'divider'
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Item />
            <Item />
            <Item />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

CMSRoot.propTypes = {};

export default CMSRoot;
