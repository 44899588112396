import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import {ClickAwayListener} from "@mui/material";
import DebouncedTiny from "./DebouncedTiny";
import {alpha} from "@mui/material/styles";
import {useSelector} from "react-redux";

function EditableTextAreaSlim ({
  currentValue,
  handleChange,
  size = 'normal',
  color = 'primary', 
  variant = 'body1',
  name,
  disabled,
  resource,
  simple,
  inline,
  minHeight,
  maxWidth,
  placeholder,
  joyrideMark
}) {
  const [editActive, setEditActive] = React.useState(false);
  const [submissionActive, setSubmissionActive] = React.useState(false);
  const [activeValue, setActiveValue] = React.useState(currentValue);
  const cmsMode = useSelector((state) => state.default.cmsMode);

  React.useEffect(() => {
    if(resource){
      setActiveValue(resource[name]);
    }
    return () => {};
  }, [resource]);

  const handleChangeContent = (event) => {
    setActiveValue(event.target.value);
  };

  const onChangeContentTiny = (content) => {
    setActiveValue(content);
  };

  const handleChangeTiny = (content) => {
    handleChange(content)
  };

  return (
    <ClickAwayListener onClickAway={disabled ? () => {} : () => setEditActive(false)}>
      {!editActive ? (
        <Typography
          className={joyrideMark ? 'cms-article-description joyride-description-edit' : "cms-article-description"}
          variant={variant}
          component="div"
          color={cmsMode === 'structure' && placeholder && !activeValue ? 'textSecondary' : color}
          sx={{
            position: 'relative',
            cursor: disabled ? 'default': 'pointer',
            '&:hover': {
              backgroundColor: !disabled
                ? (theme) => alpha(theme.palette.secondary.main, 0.25)
                : 'initial'
            },
            minHeight: minHeight || 0,
            maxWidth: maxWidth || '100%',
            flexGrow: 1
          }}
          onClick={() => disabled ? null : setEditActive(true)}
          dangerouslySetInnerHTML={{
            __html: activeValue,
          }}
        />
      ) : (
          <Box sx={{position: 'relative', flexGrow: 1}}>
            <Box sx={{position: 'absolute', bottom: 'calc(100% + 4px)'}} id={`tool-target-${resource.resourceId}`}></Box>
            <Typography
              variant={variant}
              component="div"
              sx={{marginBottom: 1, opacity: 0.75}}
            >
              <FormControl
                disabled={submissionActive}
                variant="standard"
                sx={{
                  m: 0,
                  minHeight: minHeight || (cmsMode === 'edit' ? 8 : 0),
                  maxWidth: maxWidth || '100%',
                }}
                fullWidth
              >
                {simple ? (
                  <Input
                    fullWidth
                    multiline
                    placeholder={placeholder}
                    value={activeValue}
                    onChange={handleChangeContent}
                    disabled={submissionActive}
                    variant={variant}
                    sx={{
                      fontSize: 'inherit',
                      color: color,
                    }}
                  />
                ) : (
                  <DebouncedTiny
                    value={activeValue}
                    onChange={onChangeContentTiny}
                    handleChange={handleChangeTiny}
                    inline={inline}
                    minHeight={0}
                    placeholder={placeholder || ''}
                    toolbarFixed
                    name={`auto_focus_${resource.resourceId}`}
                    target={`#tool-target-${resource.resourceId}`}
                  />
                )}
              </FormControl>
            </Typography>
          </Box>
      )}
    </ClickAwayListener>
  )
}

EditableTextAreaSlim.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  simple: PropTypes.bool,
  inline: PropTypes.bool,
  resource: PropTypes.object,
  request: PropTypes.func,
  saveHandler: PropTypes.func,
  minHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  placeholder: PropTypes.string,
};

export default EditableTextAreaSlim;
