/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Slider = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path stroke-width="0" transform="rotate(90, 18.4531, 21.5156)" id="svg_35" d="m16.89062,21.50231l1.56248,-5.50231l1.56248,5.50231l-0.78124,0l0,5.52879l-1.56248,0l0,-5.52879l-0.78124,0z" stroke="inherit" fill="inherit"/>
        <g id="svg_37">
          <rect y="0.46878" id="svg_31" height="17.65617" width="19.63393" x="2.2015" stroke="inherit" fill="#ffffff"/>
          <ellipse ry="1.63225" rx="1.56797" id="svg_33" cy="9.5034" cx="2.09937" stroke="inherit" fill="#ffffff"/>
          <ellipse ry="1.63225" rx="1.56797" id="svg_34" cy="9.54938" cx="21.86938" stroke="inherit" fill="#ffffff"/>
        </g>
        <path stroke-width="0" transform="rotate(-90, 5.67168, 21.6093)" id="svg_38" d="m4.18733,21.59584l1.48436,-5.59584l1.48436,5.59584l-0.74218,0l0,5.62276l-1.48435,0l0,-5.62276l-0.74218,0z" stroke="inherit" fill="inherit"/>
      </g>
    </SvgIcon>
  );
};

Slider.propTypes = {};

export default Slider;
