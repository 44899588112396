/* globals PROJECTID */
/* globals JOURNALID */
import React from 'react';
import {useDispatch} from "react-redux";
import Box from "@mui/material/Box";
import {
  getToken,
  getJournalGenericProperties,
  getJournalProperties,
  getJournalQualifiers,
  getJournalLabels,
  getJournalLinkLabels,
  getKeywords,
} from "../general/api";
import {
  setProjectId,
  setJournalId,
  resetCurrentUser,
  setLabels,
  setQualifiers,
  setLinkLabels,
  setProperties,
  updateCurrentUser,
  setGenericProperties,
  setKeywords, toggleCmsSidebar,
} from "../general/redux/actions";
import MessageContainer from "../general/components/MessageContainer";
import Loading from "../general/components/Loading";
import Main from "./components/Main";
import CssBaseline from "@mui/material/CssBaseline";

function App({ }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    dispatch(setProjectId(PROJECTID));
    dispatch(setJournalId(JOURNALID));
    getToken().then((response) => {
      if (response.data) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
      }
      dispatch(updateCurrentUser(response.data || null));
    });

    getKeywords(PROJECTID).then((response) => {
      dispatch(setKeywords(response.data || []));
    });

    getJournalGenericProperties(JOURNALID).then((response) => {
      dispatch(setGenericProperties(response.data || []));
    });

    getJournalProperties(PROJECTID, JOURNALID).then((response) => {
      dispatch(setProperties(response.data || []));
      if(response.data && response.data['journal.layout.noSidebar']){
        dispatch(toggleCmsSidebar(true))
      } else {
        dispatch(toggleCmsSidebar(false))
      }
    });

    getJournalQualifiers(JOURNALID).then((response) => {
      dispatch(setQualifiers(response.data || []));
    });

    getJournalLinkLabels(JOURNALID).then((response) => {
      dispatch(setLinkLabels(response.data || []));
    });

    getJournalLabels(JOURNALID).then((response) => {
      dispatch(setLabels(response.data || []));
    });

    return () => {
      dispatch(resetCurrentUser(null))
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '100%' }}>
      <CssBaseline />
      {!loading ? (
        <Main />
      ) : (
        <Loading />
      )}
      <MessageContainer />
    </Box>
  );
}

App.propTypes = { };

export default App;