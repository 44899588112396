import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {alpha} from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {setCmsActiveId} from "../../general/redux/actions";
import CMSArticleEdit from "./CMSArticleEdit";
import {getBorderColor, getBoxColor, getBoxStyle, isBox, isFullSection, isImageRounded} from "../utils/cms";
import CMSExpandableWithImage from "./CMSExpandableWithImage";
import CMSStandard from "./CMSStandard";
import CMSFaq from "./CMSFaq";
import CMSImageWithOverlay from "./CMSImageWithOverlay";
import CMSAttachmentOnly from "./CMSAttachmentOnly";
import CMSSequenceItem from "./CMSSequenceItem";
import CMSArticleHidden from "./CMSArticleHidden";

function CMSContainerArticle(
  {
    parent,
    resource,
    hidden,
    mode,
    activeId,
    highlightParent,
    index,
    items
  }
){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [item, setItem] = React.useState({...resource});
  const [highlight, setHighLight] = React.useState(false);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);

  React.useEffect(() => {
    setItem({...resource});
    return () => {};
  },[resource]);

  const handleCmsContainerArticleType = (item) => {
    switch(item.label){
      case 'imageabovetext':
      case 'imagebelowtext':
      case 'imageabovetexticon':
      case 'imageleftnoFloat':
      case 'imagerightnoFloat':
        return (
          <CMSExpandableWithImage
            resource={item}
            mode={mode}
            parent={parent}
          />
        );
      case 'imageTextOverlay':
        return (
          <CMSImageWithOverlay
            resource={item}
            parent={parent}
            mode={mode}
          />
        );
      case 'faq':
        return (
          <CMSFaq
            resource={item}
            parent={parent}
            mode={mode}
          />
        );
      case 'attachmentonly':
        return (
          <CMSAttachmentOnly
            resource={item}
            parent={parent}
            mode={mode}
          />
        );
      case 'sequenceItem':
        return (
          <CMSSequenceItem
            resource={item}
            mode={mode}
            parent={parent}
            index={index}
            items={items}
          />
        );
      case 'textonly':
      case 'standard':
      default:
        return (
          <CMSStandard
            resource={item}
            mode={mode}
            parent={parent}
          />
        );
    }
  }

  const highlightArticle = (e, state) => {
    highlightParent(!state);
    setHighLight(state);
  }

  const activateArticle = () => {
    dispatch(setCmsActiveId(resource.resourceId))
  }

  const deactivateArticle = (e) => {
    if(!e.target.closest('.cms-non-closable')) {
      dispatch(setCmsActiveId(''))
    }
  }

  let boxPt = getBoxStyle(item, 'boxPaddingTop', 2);
  let boxPl = getBoxStyle(item, 'boxPaddingLeft', 2);
  let boxPr = getBoxStyle(item, 'boxPaddingRight', 2);
  let boxPb = getBoxStyle(item, 'boxPaddingBottom', 2);
  if(Boolean(parent) && !isImageRounded(resource)){
    boxPt = 0;
    boxPb = 0;
    boxPl = 0;
    boxPr = 0;
  }
  const boxColor = getBoxColor(item);
  const bColor = getBorderColor(item);
  const bRadius = getBoxStyle(item, 'borderRadius', 0);
  const bWidth = getBoxStyle(item, 'borderWidth', 0);

  return (
    <Box
      className="cms-container-article-root"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: isBox(item) ? boxColor  : 'initial',
        borderRadius: bRadius,
        border: bWidth ? `${bWidth}px solid` : 'none',
        borderColor: bColor,
      }}
    >
      {/*{(highlight || (item && activeId === item.resourceId)) && (*/}
      {/*  <Typography*/}
      {/*    component="div"*/}
      {/*    variant="body1"*/}
      {/*    sx={{*/}
      {/*      position: 'absolute',*/}
      {/*      top:'calc(100% + 4px)',*/}
      {/*      left:0,*/}
      {/*      zIndex: 15,*/}
      {/*      ml: 2,*/}
      {/*      p: 0.5,*/}
      {/*      maxHeight: 32,*/}
      {/*      cursor: 'pointer',*/}
      {/*      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {t(`cms.type.${item ? item.label : ''}`)}*/}
      {/*  </Typography>*/}
      {/*)}*/}
      {item.published ? (
        <Box
          className="cms-container-article-frame"
          component={Paper}
          elevation={0}
          onMouseEnter={mode === 'structure' ? (e) => highlightArticle(e,true) : null}
          onMouseLeave={mode === 'structure' ? (e) => highlightArticle(e,false) : null}
          onClick={mode === 'structure' ? activateArticle : null}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            cursor: 'pointer',
            backgroundColor: highlight && !Boolean(activeId === resource.resourceId) ? (theme) => alpha(theme.palette.secondary.main, 0.25) : 'initial',
          }}
        >
          <Box
            className="cms-container-article-frame-center"
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'inherit',
            }}
          >
            <Box
              className="cms-container-article-frame-inner"
              component="div"
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <Box
                className={`cms-container-article cms-container-article-${item ? item.label : ''}`}
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  pt: isBox(item) ? boxPt : (item && item.label === 'sequenceItem' ? 0 : 1),
                  pb: isBox(item) ? boxPb : (item && item.label === 'sequenceItem' ? 0 : 1),
                  pl: isBox(item) ? boxPl : 0,
                  pr: isBox(item) ? boxPr : 0,
                }}
              >
                {handleCmsContainerArticleType(item)}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <CMSArticleHidden
          mode={mode}
          resource={item}
          activeId={activeId}
          parent={resource}
          highlightParent={highlightParent}
          items={items}
        />
      )}
      {mode === 'structure' && Boolean(resource.resourceId === activeId) && (
        <CMSArticleEdit
          activateArticle={activateArticle}
          deactivateArticle={deactivateArticle}
          highlightArticle={highlightArticle}
          mode={mode}
          activeId={activeId}
          sub={parent.label}
        />
      )}
    </Box>
  );
}

CMSContainerArticle.propTypes = {
  resource: PropTypes.object,
  hidden: PropTypes.bool,
};

export default CMSContainerArticle;
