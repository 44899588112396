import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import debounce from "lodash.debounce";

const TINY_MCE_DEFAULT_OPTIONS = {
  menubar: false,
  plugins: ['advlist lists autolink link'],
  toolbar:
    'undo redo | bold italic forecolor | link | bullist numlist outdent indent | ',
    // 'undo redo | formatselect | bold italic forecolor | link | bullist numlist outdent indent | ',
  theme: 'silver',
  skin: false,
  content_css: '/dito/template_d7/react/tinymce/content_css.css',
  // statusbar: false,
  // branding: false,
  language: 'de',
  // inline: true,

  //
  // Link related configs

  // Default link target in create/edit link dialog *and* link target used
  // for pasted links
  // https://www.tiny.cloud/docs/plugins/opensource/link/#default_link_target
  default_link_target: '_blank',
  // default_link_target: '_self',

  // https://www.tiny.cloud/docs/plugins/opensource/link/#target_list
  // target_list: [
  //   {title: 'None', value: ''},
  //   // {title: 'Same page', value: '_self'},
  //   {title: 'New page', value: '_blank'},
  // ],

  // https://www.tiny.cloud/docs/plugins/opensource/link/#link_quicklink
  // link_quicklink: true,

  // https://stackoverflow.com/a/18068980
  // Adds `target=_blank` to all `a` elements on save
  // extended_valid_elements: 'a[href|target=_blank]',
};

export default function TinyEditor({ value, onChange, handleChange = () => {}, inline, options = {}}) {
  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  const ref = React.useRef(null);

  const debouncedChangeHandler = React.useMemo(
    () => debounce((content) => {
        handleChange(ref.current.getContent());
    }, 500)
    , []);

  return (
    <Editor
      value={value}
      onEditorChange={(newValue, editor) => {
        onChange(newValue);
      }}
      // onKeyUp={(event, editor) => onChangeDebounced(editor.getContent())}
      onKeyUp={debouncedChangeHandler}
      ref={ref}
      inline={inline}
      onInit={(evt, editor) => ref.current = editor}
      init={{
        ...TINY_MCE_DEFAULT_OPTIONS,
        ...options,
        // content_style: [contentCss, contentUiCss].join('\n'),
      }}
    />
  );
}
