/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageLeftNoFloatExpand = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <rect id="svg_20" height="7.8125" width="5.5625" y="0.5" x="0.3125" stroke="inherit" fill="none"/>
        <g id="svg_30">
          <rect stroke-width="0" id="svg_1" height="4" width="12.25565" y="0" x="11.89002" stroke="inherit" fill="inherit"/>
          <line id="svg_17" y2="1" x2="9.75678" y1="3.5" x1="9.75678" stroke="inherit" fill="none"/>
          <line id="svg_18" y2="2.125" x2="10.76066" y1="2.125" x1="8.75291" stroke="inherit" fill="none"/>
          <line id="svg_6" y2="16" x2="24.14567" y1="16" x1="8" stroke="inherit" fill="none"/>
          <line id="svg_2" y2="8" x2="24.14567" y1="8" x1="8" stroke="inherit" fill="none"/>
          <line id="svg_3" y2="12" x2="24.14567" y1="12" x1="8" stroke="inherit" fill="none"/>
          <line id="svg_27" y2="20" x2="24.1875" y1="20" x1="8.08366" stroke="inherit" fill="none"/>
        </g>
      </g>
    </SvgIcon>
  );
};

ImageLeftNoFloatExpand.propTypes = {};

export default ImageLeftNoFloatExpand;
