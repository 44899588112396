/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SequenceItem = (props) => {
  return (
    <SvgIcon {...props}>
      <g >
        <title stroke="null">Layer 1</title>
        <ellipse ry="3.9823" rx="3.80531" id="svg_2" cy="10.45133" cx="4.21239" stroke="inherit" fill="none"/>
        <line stroke="inherit" id="svg_4" y2="6.82301" x2="4.56637" y1="0" x1="4.56637" fill="none"/>
        <line id="svg_5" y2="27.79646" x2="4.30088" y1="14.52212" x1="4.38938" stroke="inherit" fill="none"/>
        <rect id="svg_7" height="1" width="13" y="7.80531" x="10.40708" stroke="inherit" fill="none"/>
        <line id="svg_8" y2="10.80531" x2="23.9115" y1="10.80531" x1="9.9115" stroke="inherit" fill="none"/>
        <line id="svg_9" y2="12.84071" x2="23.9115" y1="12.84071" x1="9.9115" stroke="inherit" fill="none"/>
        <line id="svg_10" y2="14.80531" x2="23.9115" y1="14.80531" x1="9.9115" stroke="inherit" fill="none"/>
        <line id="svg_12" y2="16.80531" x2="23.9115" y1="16.80531" x1="9.9115" stroke="inherit" fill="none"/>
      </g>
    </SvgIcon>
  );
};

SequenceItem.propTypes = {};

export default SequenceItem;
