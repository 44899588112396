import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import defaultReducer from './default.reducer';

const rootReducer = combineReducers({
  default: defaultReducer,
  form: formReducer,
});

export default rootReducer;
