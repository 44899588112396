import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandOutlinedIcon from '@mui/icons-material/ExpandOutlined';
import {
  setCmsActiveId,
  setCmsCopy,
  setCmsData,
  setConfirmation, updateCmsArticle,
  updateCmsDeletedArticles,
} from "../../general/redux/actions";
import {alpha} from "@mui/material/styles";
import {array_move} from "../../general/helpers";
import {
  checkNextIsDisabled,
  copyCmsArticle,
  getContainerChildLength,
  getLastChildIndexByParentArticle,
  getSubRanK
} from "../utils/helpers";
import {CMSGROUPS} from "../utils/constants";
import CMSColorButton from "./CMSColorButton";

function CMSEditButtons({ activeId, sub }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsDeletedArticles = useSelector((state) => state.default.cmsDeletedArticles);
  const [detail, setDetail] = React.useState(null);
  const [styleOpen, setStyleOpen] = React.useState(false);

  React.useEffect(() => {
    if(activeId){
      setDetail(cmsData.find((item) => item.resourceId === activeId));
    }

    return() => {};
  },[activeId, cmsData]);

  const updateCmsStyle = (style, value) => {

    dispatch(setCmsData(cmsData.map((it) => {
      if(it.resourceId === activeId){

        return {
          ...it,
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              [style]: value
            }
          }
        };
      }
      return it;
    })));
  }

  const toggleFullSection = () => {
    const isFullSection = detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.fullSection;
    updateCmsStyle('fullSection', !isFullSection);
  }

  const toggleExpandable = () => {
    const isExpandable = detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.expandable;
    updateCmsStyle('expandable', !isExpandable);
  }

  const handleConfirm = (actionType, customAction) => {
    const action = () => {};

    dispatch(setConfirmation({
      open: true,
      title: `cms.${actionType}.confirm.title`,
      description: `cms.${actionType}.confirm.description`,
      action: customAction || action,
    }));
  };

  const copyArticle = (aId, cData) => {
    const copiedArticles = copyCmsArticle(aId, cData);
    dispatch(setCmsCopy(copiedArticles));
  }

  const moveArticle = (dir, aId, cData) => {
    const currentIndex = cData.findIndex((it) => it.resourceId === aId);
    const isStructureElement = CMSGROUPS.buttonGroupStructure.indexOf(cData[currentIndex].label) !== -1;
    let nextPosition = currentIndex +
      (isStructureElement && dir === 'bottom' ? getContainerChildLength(cData,currentIndex) : 0) +
      (dir === 'top' ?  -1 : 1);
    let updatedData = [...cData];

    if(dir === 'top' && currentIndex !== 0){
      if(updatedData[nextPosition].type === 'cms_article_child'){
        nextPosition = updatedData.findIndex((it) => it.rank === (Math.ceil(updatedData[nextPosition].rank / 100) * 100) - 100)
      }

      if(isStructureElement){
        const lastChildIndex = getLastChildIndexByParentArticle(cmsData, currentIndex);
        const iterations = getContainerChildLength(cmsData, currentIndex);
        for (let iteration = 0;iteration <= iterations; iteration++) {
          updatedData = array_move(updatedData,lastChildIndex, nextPosition) ;
        }
      }
    }

    if(dir === 'bottom' && currentIndex !== updatedData.length -1) {
      if(CMSGROUPS.buttonGroupStructure.indexOf(updatedData[nextPosition].label) !== -1){
        nextPosition = nextPosition + getContainerChildLength(updatedData, nextPosition);
      }
      if(nextPosition > cmsData.length - 1){
        nextPosition = cmsData.length -1;
      }
      if(isStructureElement){
        const iterations = getContainerChildLength(cmsData, currentIndex);
        // nextPosition = currentIndex + iterations + 1;
        for (let iteration = 0;iteration <= iterations; iteration++) {
          updatedData = array_move(updatedData,currentIndex, nextPosition);
        }
      }
    }

    if(!isStructureElement) {
      updatedData = array_move(updatedData,currentIndex, nextPosition);
    }

    let outerCount = 0;
    updatedData = updatedData.map((it, index) => {
      let newRank;
      if(it.rank % 100 === 0){
        outerCount++;
        newRank = outerCount * 100;
      } else {
        const subRank = getSubRanK(it.rank);
        newRank = (outerCount * 100 || 100) + subRank
      }

      return {
        ...it,
        rank: newRank
      };
    });
    dispatch(setCmsData(updatedData));
  }

  const unpublishArticle = () => {
    const targetItem = cmsData.find((it) => it.resourceId === activeId);
    if(CMSGROUPS.buttonGroupStructure.indexOf(targetItem.label) !== -1){
      let targetIndex = cmsData.findIndex((it) => it.resourceId === activeId);
      const childLength = getContainerChildLength(cmsData, targetIndex);
      const unpublishedIds = [];
      for(let i = 0; i <= childLength; i++){
        const rId = cmsData[targetIndex].resourceId;
        unpublishedIds.push(rId);
        targetIndex++
      }

      dispatch(setCmsData(cmsData.map((it) => {
        if(unpublishedIds.indexOf(it.resourceId) !== -1){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }else {
      dispatch(setCmsData(cmsData.map((it) => {
        if(it.resourceId === activeId){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }
    dispatch(setCmsActiveId(''));
  };

  const deleteArticle = () => {
    const targetItem = cmsData.find((it) => it.resourceId === activeId);
    if(CMSGROUPS.buttonGroupStructure.indexOf(targetItem.label) !== -1){
      let targetIndex = cmsData.findIndex((it) => it.resourceId === activeId);
      const childLength = getContainerChildLength(cmsData, targetIndex);
      const deleteIds = [];
      const deleteArticlesIds = [];
      for(let i = 0; i <= childLength; i++){
        const rId = cmsData[targetIndex].resourceId;
        deleteIds.push(rId);
        if(rId.indexOf('created') === -1){
          deleteArticlesIds.push(rId);
        }
        targetIndex++
      }

      dispatch(updateCmsDeletedArticles([...cmsDeletedArticles, ...deleteArticlesIds]));
      dispatch(setCmsData(cmsData.map((item) => {
        if(deleteIds.indexOf(item.resourceId) !== -1){
          return { ...item, markedForDeletion: true };
        }
        return item;
      })));
    }else {
      if(activeId.indexOf('created') === -1){
        dispatch(updateCmsDeletedArticles([...cmsDeletedArticles, activeId]));
      }
      dispatch(setCmsData(cmsData.map((item) => {
        if(item.resourceId === activeId){
          return { ...item, markedForDeletion: true };
        }
        return item;
      })));
    }
    dispatch(setCmsActiveId(''));
  };

  const buttonSx = {
    minWidth: 24,
    flexDirection: 'row',
    px: 1,
    '& .MuiButton-startIcon': {
      mx: 0,
    }
  };

  let isBottomDisabled = detail ? checkNextIsDisabled(cmsData, detail.resourceId) : true;

  return (
    <>
      <Box
        className="cms-edit-buttons cms-non-closable"
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: 1,
          }}
        >
          <Tooltip title={t('cms.move.top')}>
            <Button
              disabled={(detail ? cmsData.findIndex((it) => it.resourceId === detail.resourceId) === 0 : true)}
              variant="contained"
              sx={buttonSx}
              startIcon={<ExpandLessIcon />}
              color="secondary"
              onClick={() => moveArticle('top', detail.resourceId, cmsData)}
            >
              &nbsp;
            </Button>
          </Tooltip>
          <Tooltip title={t('cms.move.bottom')}>
            <Button
              disabled={isBottomDisabled}
              variant="contained"
              sx={buttonSx}
              startIcon={<ExpandMoreIcon />}
              color="secondary"
              onClick={() => moveArticle('bottom', detail.resourceId, cmsData)}
            >
              &nbsp;
            </Button>
          </Tooltip>
          {/*{detail && CMSGROUPS.buttonGroupStructure.indexOf(detail.label) !== -1 && (*/}
          {/*  <Typography*/}
          {/*    component="div"*/}
          {/*    variant="body1"*/}
          {/*    sx={{*/}
          {/*      ml: 2,*/}
          {/*      p: 0.5,*/}
          {/*      cursor: 'pointer',*/}
          {/*      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {t(`cms.type.${detail ? detail.label : ''}`)}*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: 'flex',*/}
          {/*    flexDirection: 'row',*/}
          {/*    alignItems: 'center'*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Tooltip title={t('cms.toggle.fullSection')}>*/}
          {/*    <IconButton onClick={toggleFullSection} sx={{'& svg': {pointerEvents: 'none'}}}>*/}
          {/*      {*/}
          {/*        detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.fullSection*/}
          {/*          ? <CloseFullscreenIcon />*/}
          {/*          : <OpenInFullIcon />*/}
          {/*      }*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*  <Tooltip title={t('cms.toggle.expandable')}>*/}
          {/*    <IconButton onClick={toggleExpandable}>*/}
          {/*      <ExpandOutlinedIcon*/}
          {/*        color={*/}
          {/*          detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.expandable*/}
          {/*            ? 'success'*/}
          {/*            : 'default'*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*  <Tooltip title={t('cms.toggle.text')}>*/}
          {/*    <CMSColorButton*/}
          {/*      resource={detail}*/}
          {/*      type="text"*/}
          {/*      updateCmsStyle={updateCmsStyle}*/}
          {/*    />*/}
          {/*  </Tooltip>*/}
          {/*  <Tooltip title={t('cms.toggle.box')}>*/}
          {/*    <CMSColorButton*/}
          {/*      type="box"*/}
          {/*      resource={detail}*/}
          {/*      updateCmsStyle={updateCmsStyle}*/}
          {/*    />*/}
          {/*  </Tooltip>*/}
          {/*  <Tooltip title={t('cms.toggle.border')}>*/}
          {/*    <CMSColorButton*/}
          {/*      resource={detail}*/}
          {/*      type="border"*/}
          {/*      updateCmsStyle={updateCmsStyle}*/}
          {/*    />*/}
          {/*  </Tooltip>*/}
          {/*</Box>*/}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: 1,
          }}
        >
          {Boolean(!sub || sub === 'slider' || sub === 'sequenceContainer') && (
            <>
              <Tooltip title={t('cms.copy.tooltip')}>
                <IconButton
                  sx={{ml: 'auto'}}
                  onClick={() => copyArticle( detail.resourceId, cmsData)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('button.unpublish')}>
                <IconButton
                  // color="error"
                  onClick={() =>{
                    return handleConfirm(
                      detail && detail.published ? 'unpublish' : 'publish',
                      () => unpublishArticle(),
                    );
                  }}
                >
                  {detail && detail.published ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t('button.delete')}>
                <IconButton
                  // color="error"
                  onClick={() =>{
                    return handleConfirm(
                      'deleteArticle',
                      () => deleteArticle(),
                    );
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

CMSEditButtons.propTypes = {
  activeId: PropTypes.string,
};

export default CMSEditButtons;
