import axios from 'axios';

export const RequestType = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};

export const fileUpload = (attachment, file, fileName, fileDesc, fileAlt) => {
  const url = '/dito/explore';
  const data = {
    action: 'postarticleattachment',
    view: 'multiedit',
    id: attachment.resourceId,
    backend: true,
    ok_further: '&nbsp;&nbsp;OK&nbsp;&nbsp;',
    json: true,
  }

  if(file){
    data['newRank_add'] =  100;
    data['newName_add'] = fileName || '';
    data['newDescription_add'] = fileDesc || '';
    data['newAlt_add'] = fileAlt || '';
    if(attachment && attachment.id && !isNaN(parseInt(attachment.id))){
      data[`delete_${attachment.id}`] = true;
      data[`oldRank_${attachment.id}`] = 100;
      data[`newRank_${attachment.id}`] = 100;
      data[`oldName_${attachment.id}`] = attachment.name;
      data[`newName_${attachment.id}`] = fileName;
      data[`oldDescription${attachment.id}`] = attachment.description;
      data[`newDescription_${attachment.id}`] = fileDesc;
      data[`oldAlt_${attachment.id}`] = attachment.alt;
      data[`newAlt_${attachment.id}`] = fileAlt;
    }
  } else {
    data[`oldRank_${attachment.id}`] = 100;
    data[`newRank_${attachment.id}`] = 100;
    data[`oldName_${attachment.id}`] = attachment.name;
    data[`newName_${attachment.id}`] = fileName;
    data[`oldDescription${attachment.id}`] = attachment.description;
    data[`newDescription_${attachment.id}`] = fileDesc;
    data[`oldAlt_${attachment.id}`] = attachment.alt;
    data[`newAlt_${attachment.id}`] = fileAlt;
  }

  const formData = new FormData();

  for ( var key in data ) {
    formData.append(key, data[key]);
  }

  if(file){
    formData.append('file', file, fileName);
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    }
  }
  return  axios.post(url, formData,config).then((response) => {
    return response;
  })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
};

export const fileDelete = (attachment) => {
  const url = '/dito/explore';
  const data = {
    action: 'postarticleattachment',
    view: 'multiedit',
    id: attachment.resourceId,
    backend: true,
    newRank_add:  100,
    newName_add: '',
    newDescription_add: '',
    ok_further: 'OK',
    json: true,
  }
  data[`editActive_${attachment.id}`] = true;
  data[`delete_${attachment.id}`] = true;
  data[`oldRank_${attachment.id}`] = 100;
  data[`newRank_${attachment.id}`] = 100;
  data[`oldName_${attachment.id}`] = '';
  data[`newName_${attachment.id}`] = '';
  data[`oldDescription${attachment.id}`] = '';
  data[`newDescription_${attachment.id}`] = '';
  data[`oldAlt_${attachment.id}`] = '';
  data[`newAlt_${attachment.id}`] = '';
  const formData = new FormData();
  for ( var key in data ) {
    formData.append(key, data[key]);
  }
  formData.append('file', '')
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return  axios.post(url, formData,config).then((response) => {
    return response;
  })
    .catch((error) => {

      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
};

export const getAddress = (lat, lng) => {
  const url = `/dito/explore?action=crossscriptingout&lat=${lat}&lon=${lng}`;

  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }

  return  axios.get(url, config).then((response) => {
    return response;
  })
    .catch((error) => {

      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
}

export const uriToFile = (uri) => {
  return fetch(uri)
    .then((res) => res.blob())
    .then((myBlob) => {
      return new File([myBlob], 'image.jpeg', {type: myBlob.type});
    });
}

export const getPollingConfig = (jId) => {
  const url = `/dito/explore?action=polling&view=editjson&id=${jId}`;

  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }

  return  axios.get(url, config).then((response) => {
    return response;
  })
    .catch((error) => {

      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
}

export const savePollingConfig = (jId, formData) => {
  const url = `action=polling&view=savejson&id=${jId}`;

  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }

  return  axios.post(url, formData, config).then((response) => {
    return response;
  })
    .catch((error) => {

      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
}