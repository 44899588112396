/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageRightNoFloatExpand = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <rect id="svg_20" height="7.8125" width="5.5625" y="0.5" x="18.0625" stroke="inherit" fill="none"/>
        <g id="svg_30">
          <rect stroke-width="0" id="svg_1" height="4" width="12.25565" y="-0.125" x="3.82752" stroke="inherit" fill="inherit"/>
          <line id="svg_17" y2="0.875" x2="1.69428" y1="3.375" x1="1.69428" stroke="inherit" fill="none"/>
          <line id="svg_18" y2="2" x2="2.69816" y1="2" x1="0.69041" stroke="inherit" fill="none"/>
          <line id="svg_6" y2="15.875" x2="16.08317" y1="15.875" x1="-0.0625" stroke="inherit" fill="none"/>
          <line id="svg_2" y2="7.875" x2="16.08317" y1="7.875" x1="-0.0625" stroke="inherit" fill="none"/>
          <line id="svg_3" y2="11.875" x2="16.08317" y1="11.875" x1="-0.0625" stroke="inherit" fill="none"/>
          <line id="svg_27" y2="19.875" x2="16.125" y1="19.875" x1="0.02116" stroke="inherit" fill="none"/>
        </g>
      </g>
    </SvgIcon>
  );
};

ImageRightNoFloatExpand.propTypes = {};

export default ImageRightNoFloatExpand;
