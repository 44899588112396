import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CloseIcon from '@mui/icons-material/Close';
import {alpha} from "@mui/material/styles";
import CMSImageEdit from "./CMSImageEdit";
import Dialog from '@mui/material/Dialog';
import {useSelector} from "react-redux";
import {getImage, getImageDimensions, isImageCentered, isImageRounded, isImageSquared, isMultiCol} from "../utils/cms";

function CMSImage(
  {
    resource,
    parent,
    joyrideMark,
  }
){
  const { t } = useTranslation();
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);
  const cmsMode = useSelector((state) => state.default.cmsMode);
  const [highlight, setHighlight] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const image = getImage(resource);
  const centered = isImageCentered(resource);
  const rounded = isImageRounded(resource);
  const dimensions = getImageDimensions(resource, parent, cmsSidebar, rounded);
  const width = dimensions.width;
  const height = rounded ? dimensions.width : dimensions.height;
  const small = Boolean(width < 570);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Box
        className={joyrideMark ? 'cms-article-image-root joyride-image-edit' : "cms-article-image"}
        component={cmsMode === 'edit' ? ButtonBase : "div"}
        onClick={cmsMode === 'edit' ? () => setEdit(true) : null}
        onMouseEnter={cmsMode === 'edit' ? () => setHighlight(true) : null}
        onMouseLeave={cmsMode === 'edit' ? () => setHighlight(false) : null}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          mt: 0,
          ml: rounded || centered ? 'auto' : 0,
          mr: rounded || centered ? 'auto' : 0,
          mb: 0,
          borderRadius: rounded ? '50% !important' : 0,
          width: isMultiCol(parent) && !isImageRounded(resource) ? '100%': width,
          height: isImageSquared(resource) ? width : height,
          maxWidth: '100%',
          // minWidth: image ? 200 : full ? 600 : 200,
          // minHeight: image ? 150 : full ? 450 : 150,
          '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: rounded ? '50% !important' : 0,
          }
        }}
      >
        {image ? (
          <>
            <img className="cms-article-image-img" src={image ? image.preview || image.url : ''} />
            {cmsMode === 'preview' && (
              <IconButton
                sx={{
                  position: 'absolute',
                  right: rounded ? 0 : 8,
                  top: rounded ? 0 : 8,
                  zIndex: 2,
                  backgroundColor: 'background.main',
                  p: 0,
                }}
                onClick={handleClickOpen}
              >
                <CopyrightIcon className="cms-article-image-copyright" />
              </IconButton>
            )}
          </>
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            borderRadius: rounded ? '50% !important' : 0,
            width: isMultiCol(parent) && !isImageRounded(resource) ? '100%': width,
            maxHeight: isImageSquared(resource) ? width : height,
            backgroundColor: highlight ? 'background.main' : 'image.main',
          }}
          >
            {!highlight && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: rounded ? '50% !important' : 0,
                  }}
                >
                  <PhotoSizeSelectActualOutlinedIcon
                    color="background"
                    sx={{
                      width: small ? 64 : 112,
                      height: small ? 64 : 112
                    }}
                  />
                  <Typography
                    variant={small ? "body2" : "body1"}
                    sx={{
                      color: 'background.main'
                    }}
                  >
                    {`${width} x ${height}`}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
        {highlight && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: rounded ? '50% !important' : 0,
              backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
              zIndex: 1
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                // maxHeight: style.maxHeight,
                // minHeight: style.minHeight,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: rounded ? '50% !important' : 0,
                }}
              >
                <CameraAltOutlinedIcon color="background" sx={{width: 48, height: 48}} />
                <Typography variant="body2" sx={{ color: 'background.main' }}>Bild hochladen</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <CMSImageEdit
        resource={resource}
        image={image}
        width={width}
        height={height}
        aspect={isImageSquared(resource) ? width/width : width/height}
        open={edit}
        handleClose={() => setEdit(false)}
      />
      <Dialog
        fullWidth
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: 'auto',
            maxWidth: 'unset',
            position: 'relative',
            overflow: 'unset'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: '100%',
            width: '100%',
            height: 32,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            pl: 1,
          }}
        >
          {image && (
            <Typography variant="body2" sx={{ color: 'background.main' }}>
              {`${image.alt ? `${image.alt}: `: ''}${image.description}`}
            </Typography>
          )}
          <IconButton color="background" sx={{ml: 'auto'}} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="div" sx={{display: 'flex' }}>
          <img className="cms-article-image-full-img" src={image ? image.preview || image.url : ''} />
        </Box>
      </Dialog>
    </>
  );
}

CMSImage.propTypes = {
  resource: PropTypes.object,
  image: PropTypes.object,
  full: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CMSImage;
