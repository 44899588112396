import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import {
  setCmsData,
  setConfirmation,
  updateCmsAddedArticles,
  updateCmsArticle,
  updateCmsDeletedArticles
} from "../../general/redux/actions";
import CMSTypeButtonGroup from "./CMSTypeButtonGroup";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

function CMSEdit({ activeId, sub }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsDeletedArticles = useSelector((state) => state.default.cmsDeletedArticles);
  const [detail, setDetail] = React.useState(null);

  React.useEffect(() => {
    if(activeId){
      setDetail(cmsData.find((item) => item.resourceId === activeId));
    }

    return() => {};
  },[activeId]);

  const handleLabelChange = (e, val) => {
    if(val !== null){
      setDetail((prevState) => {
        return {...prevState, label: val};
      });
      dispatch(updateCmsArticle({
        rId: activeId,
        name: 'label',
        val
      }));
    }
  };

  return (
    <Box
      className="cms-edit-toolbar cms-non-closable"
      component="div"
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
      }}
    >
      <CMSTypeButtonGroup
        activeId={activeId}
        cmsData={cmsData}
        handleChange={handleLabelChange}
        value={detail ? detail.label : ''}
        sub={sub}
      />

    </Box>
  );
}

CMSEdit.propTypes = {
  activeId: PropTypes.string,
  data: PropTypes.array,
  setData: PropTypes.func,
};

export default CMSEdit;
