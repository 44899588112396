import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import CMSImage from "./CMSImage";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import {updateCmsArticle, updateJoyrideCmsArticle} from "../../general/redux/actions";
import {getBoxStyle, isBox, isExpandable, isImageRounded, isMultiCol} from "../utils/cms";

function CMSWithImage({ resource, parent, joyrideMark }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsMode = useSelector((state) => state.default.cmsMode);

  const boxedWithImage = Boolean(parent)
    && isBox(resource)
    && !isImageRounded(resource)
    && (resource.label.indexOf('above') !== -1 || resource.label.indexOf('below') !== -1);

  const boxPt = boxedWithImage ? getBoxStyle(resource, 'boxPaddingTop', 0) : 0;
  const boxPl = boxedWithImage ? getBoxStyle(resource, 'boxPaddingLeft', 2) : 0;
  const boxPr = boxedWithImage ? getBoxStyle(resource, 'boxPaddingRight', 2) : 0;
  const boxPb = boxedWithImage ? getBoxStyle(resource, 'boxPaddingBottom', 0) : 0;

  const smallFontSx = isMultiCol(parent, true)  ? {
    '& h2': {
      fontSize: '115%',
    }
  }: {};

  return (
    <>
      {resource.label.indexOf('abovetext') !== -1 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 1,
          }}
        >
          <CMSImage
            resource={resource}
            parent={parent}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          alignItems:'flex-start'
        }}
      >
        {resource.label.indexOf('leftnoFloat') !== -1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mr: 8
            }}
          >
            <CMSImage
              resource={resource}
              parent={parent}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            pt: boxPt,
            pb: boxPb,
            pl: boxPl,
            pr: boxPr,
            ...smallFontSx
          }}
        >
          {!isExpandable(resource) && (
            <EditableTextSlim
              handleChange={(val) => {
                const dFunc = joyrideMark ? updateJoyrideCmsArticle : updateCmsArticle;
                dispatch(dFunc({
                  rId: resource.resourceId,
                  name: 'title',
                  val
                }))
              }}
              currentValue={resource.title}
              variant="h2"
              placeholder={t('cms.field.title.placeholder')}
              disabled={cmsMode !== 'edit'}
              joyrideMark={joyrideMark}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'flex-start'
            }}
          >
            {resource.label.indexOf('fulltextimageleft') !== -1 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mr: 4
                }}
              >
                <CMSImage
                  resource={resource}
                  parent={parent}
                />
              </Box>
            )}
            <EditableTextAreaSlim
              name="note"
              inline
              resource={resource}
              placeholder={t('cms.field.note.placeholder')}
              currentValue={resource.note}
              handleChange={(val) => {
                const dFunc = joyrideMark ? updateJoyrideCmsArticle : updateCmsArticle;
                dispatch(dFunc({
                  rId: resource.resourceId,
                  name: 'note',
                  val
                }))
              }}
              variant={isMultiCol(parent, true) ? 'body2' : 'body1'}
              minHeight={0}
              maxWidth={1152}
              disabled={cmsMode !== 'edit'}
              joyrideMark={joyrideMark}
            />
            {resource.label.indexOf('fulltextimageright') !== -1 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  ml: 4
                }}
              >
                <CMSImage
                  resource={resource}
                  parent={parent}
                  joyrideMark={joyrideMark}
                />
              </Box>
            )}
          </Box>
        </Box>
        {resource.label.indexOf('rightnoFloat') !== -1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: 8
            }}
          >
            <CMSImage
              resource={resource}
              parent={parent}
            />
          </Box>
        )}
      </Box>
      {resource.label.indexOf('belowtext') !== -1 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 1,
          }}
        >
          <CMSImage
            resource={resource}
            parent={parent}
          />
        </Box>
      )}
    </>
  );
}

CMSWithImage.propTypes = {
  resource: PropTypes.object,
  parent: PropTypes.object,
  joyrideMark: PropTypes.bool,
};

export default CMSWithImage;
