import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

function Loading({ shade, shadeOnly, size = 80 }){
  const { t } = useTranslation();
  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: shade ? 'rgba(0, 0, 0, 0)' : 'transparent',
      }}
    >
      {shade ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={shade}
          onClick={() => {}}
        >
          {!shadeOnly && (
            <CircularProgress
              sx={{
                position: 'absolute',
                zIndex: 5000,
                top: (theme) => `calc( 50% - ${theme.spacing(5)})`,
                left: (theme) => `calc( 50% - ${theme.spacing(5)})`,
              }}
              size={size}
            />
          )}
        </Backdrop>
      ) : (
        <CircularProgress
          sx={{
            position: 'absolute',
            zIndex: 5000,
            top: (theme) => `calc( 50% - ${theme.spacing(5)})`,
            left: (theme) => `calc( 50% - ${theme.spacing(5)})`,
          }}
          size={size}
        />
      )}
    </Box>
  );
}

Loading.propTypes = {
  shade: PropTypes.bool,
  shadeOnly: PropTypes.bool,
};

export default Loading;
