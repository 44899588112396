import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import {handleFlatData, handleRepairRank} from "../utils/backwardCompatibility";
import {getCmsArticles} from "../../general/api";
import {setCmsData, setInitialCmsData} from "../../general/redux/actions";
import CMSStageToolbar from "./CMSStageToolbar";
import CMSRoot from "./CMSRoot";
import JoyrideRoot from "../../general/joyride/JoyrideRoot";
import {alpha} from "@mui/material/styles";
import CMSStructureBar from "./CMSStructureBar";

function Main(){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const journalId = useSelector((state) => state.default.journalId);
  const currentUser = useSelector((state) => state.default.currentUser);
  const [isLoading, setIsLoading] = React.useState(false);

  const updateData = () => {
    setIsLoading(true);
    getCmsArticles(journalId).then((response) => {
      const useData = handleRepairRank(handleFlatData(response.data.items || response.data));
      dispatch(setCmsData(useData));
      dispatch(setInitialCmsData(useData));
      setIsLoading(false);
    });
  }

  React.useEffect(() => {
    if(currentUser){
      updateData();
    }
    return () => {};
  }, [currentUser]);

  return (
    <>
      <JoyrideRoot />
      <Box
        component={Paper}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxHeight: '100%',
          '& .far': {
            display: 'initial'
          },
          '& .fas': {
            display: 'initial'
          }
        }}
      >
        <CMSStageToolbar
          loading={isLoading}
          update={updateData}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: 1,
            maxHeight: 'calc(100% - 48px)',
            overflow: 'auto',
            backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),
          }}
        >
          <CMSRoot />
        </Box>
        <CMSStructureBar />
      </Box>
    </>
  );
}

Main.propTypes = {};

export default Main;
