import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import {createCMSArticles, createCMSTopic, getSubRanK, handleCmsTypeIcon} from "../utils/helpers";
import CMSArticle from "./CMSArticle";
import CMSTypeButtonGroup from "./CMSTypeButtonGroup";
import {setCmsData, updateCmsAddedArticles} from "../../general/redux/actions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {CMSGROUPS} from "../utils/constants";
import CMSContainer from "./CMSContainer";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function CMSArticleAdd({ position, handleClose }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectId = useSelector((state) => state.default.projectId);
  const journalId = useSelector((state) => state.default.journalId);
  const cmsData = useSelector((state) => state.default.cmsData);
  const currentUser = useSelector((state) => state.default.currentUser);
  const [currentLabel, setCurrentLabel] = React.useState('standard');
  const [dummyArticles, setDummyArticles] = React.useState(createCMSArticles(
    'standard',
    true,
    journalId,
    projectId,
    null,
    position * 100 || 100,
    currentUser.userId
  ));

  React.useEffect(() => {
    setDummyArticles(createCMSArticles(currentLabel, true, journalId, projectId, null, position * 100 || 100, currentUser.userId));
    return () => {}
  }, [position, currentLabel]);

  const handleLabelChange = (e, val) => {
    if(val !== null){
      setCurrentLabel(val);
    }
  };

  const handleAddCmsArticle = () => {
    const newCmsData = [...cmsData];
    (dummyArticles || []).reverse().forEach((art) => {
      newCmsData.splice(
        position,
        0,
        art
      )
    })
    // TODO
    let outerCount = 0;
    const updatedData = newCmsData.map((item, index) => {
      let newRank;
      if(item.rank % 100 === 0){
        outerCount++;
        newRank = outerCount * 100;
      } else {
        const subRank = getSubRanK(item.rank);
        newRank = (outerCount * 100 || 100) + subRank
      }

      return {
        ...item,
        rank: newRank
      };
    })

    dispatch(setCmsData(updatedData));
    handleClose();
  };

  const children = [...dummyArticles];
  children.shift();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        className="joyride-add-container"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          alignItems: 'center',
          outline: '1px solid',
          outlineOffset: -1,
          outlineColor: (theme) => theme.palette.secondary.main,
        }}
      >
        <Box
          component={Paper}
          elevation={3}
          sx={{
            position: 'absolute',
            bottom: 'calc(100% + 4px)',
            left: 0,
            zIndex: 5,
            flexGrow: 1,
            width: '100%',
            p: 1
          }}
        >
          <CMSTypeButtonGroup
            activeId={dummyArticles && dummyArticles.length ? dummyArticles[0].resourceId : ''}
            cmsData={[dummyArticles[0]]}
            handleChange={handleLabelChange}
            value={currentLabel}
            add
          />
        </Box>
        {CMSGROUPS.buttonGroupStructure.indexOf(currentLabel) !== -1 ? (
          <CMSContainer
            mode="display"
            resource={dummyArticles[0]}
            activeId=""
            childs={children}
          />
        ) : (
          <CMSArticle
            mode="display"
            resource={dummyArticles[0]}
            activeId=""
          />
        )}
        <Box
          component={Paper}
          elevation={3}
          sx={{
            position: 'absolute',
            top:'calc(100% + 4px)',
            left:0,
            zIndex: 5,
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Divider />
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                p: 1,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 1,
              }}
            >
              <Button color="secondary" onClick={handleClose}>
                {t('button.close')}
              </Button>
              <Button onClick={handleAddCmsArticle}>
                {t('button.add')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

CMSArticleAdd.propTypes = {
  position: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default CMSArticleAdd;
