import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import RubikLightWoff from '../general/fonts/Rubik-Light.woff';
import RubikLightWoff2 from '../general/fonts/Rubik-Light.woff2';
import RubikLightTtf from '../general/fonts/Rubik-Light.ttf';
import RubikLightEot from '../general/fonts/Rubik-Light.eot';
import RubikLightSvg from '../general/fonts/Rubik-Light.svg';
import RubikSemiBoldWoff from '../general/fonts/Rubik-SemiBold.woff';
import RubikSemiBoldWoff2 from '../general/fonts/Rubik-SemiBold.woff2';
import RubikSemiBoldTtf from '../general/fonts/Rubik-SemiBold.ttf';
import RubikSemiBoldEot from '../general/fonts/Rubik-SemiBold.eot';
import RubikSemiBoldSvg from '../general/fonts/Rubik-SemiBold.svg';
import RubikItalicWoff from '../general/fonts/Rubik-Italic.woff';
import RubikItalicWoff2 from '../general/fonts/Rubik-Italic.woff2';
import RubikItalicTtf from '../general/fonts/Rubik-Italic.ttf';
import RubikItalicEot from '../general/fonts/Rubik-Italic.eot';
import RubikItalicSvg from '../general/fonts/Rubik-Italic.svg';
import RubikLightItalicWoff from '../general/fonts/Rubik-LightItalic.woff';
import RubikLightItalicWoff2 from '../general/fonts/Rubik-LightItalic.woff2';
import RubikLightItalicTtf from '../general/fonts/Rubik-LightItalic.ttf';
import RubikLightItalicEot from '../general/fonts/Rubik-LightItalic.eot';
import RubikLightItalicSvg from '../general/fonts/Rubik-LightItalic.svg';
import RubikRegularWoff from '../general/fonts/Rubik-Regular.woff';
import RubikRegularWoff2 from '../general/fonts/Rubik-Regular.woff2';
import RubikRegularTtf from '../general/fonts/Rubik-Regular.ttf';
import RubikRegularEot from '../general/fonts/Rubik-Regular.eot';
import RubikRegularSvg from '../general/fonts/Rubik-Regular.svg';
import RubikMediumItalicWoff from '../general/fonts/Rubik-MediumItalic.woff';
import RubikMediumItalicWoff2 from '../general/fonts/Rubik-MediumItalic.woff2';
import RubikMediumItalicTtf from '../general/fonts/Rubik-MediumItalic.ttf';
import RubikMediumItalicEot from '../general/fonts/Rubik-MediumItalic.eot';
import RubikMediumItalicSvg from '../general/fonts/Rubik-MediumItalic.svg';
import RubikMediumWoff from '../general/fonts/Rubik-Medium.woff';
import RubikMediumWoff2 from '../general/fonts/Rubik-Medium.woff2';
import RubikMediumTtf from '../general/fonts/Rubik-Medium.ttf';
import RubikMediumEot from '../general/fonts/Rubik-Medium.eot';
import RubikMediumSvg from '../general/fonts/Rubik-Medium.svg';
import RubikSemiBoldItalicWoff from '../general/fonts/Rubik-SemiBoldItalic.woff';
import RubikSemiBoldItalicWoff2 from '../general/fonts/Rubik-SemiBoldItalic.woff2';
import RubikSemiBoldItalicTtf from '../general/fonts/Rubik-SemiBoldItalic.ttf';
import RubikSemiBoldItalicEot from '../general/fonts/Rubik-SemiBoldItalic.eot';
import RubikSemiBoldItalicSvg from '../general/fonts/Rubik-SemiBoldItalic.svg';

export const themeConfig = {
  spacing: 8,
  typography: {
    fontSize: 18,
    fontFamily: `Rubik, Arial, Helvetica, sans-serif`,
    lineHeight: 1.4,
    fontWeight: 400,
    hyphens: 'auto',
    overflowX: 'hidden',
    h1: {
      fontSize: '2.4em',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 1.2,
      marginTop: 0,
      marginBottom: 8,
    },
    h2: {
      fontSize: '1.7em',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 1.35,
      marginTop: 0,
      marginBottom: 8,
    },
    h3: {
      fontSize: '1.4em',
      fontWeight: 'normal' ,
      fontStyle: 'normal',
      lineHeight: 1.4,
      marginTop: 0,
      marginBottom: 8,
    },
    h4: {
      fontSize: '0.96em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.4,
      marginTop: 8,
      marginBottom: 8,
    },
    h5: {
      fontSize: '0.8em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      marginTop: 8,
      marginBottom: 8,
    },
    h6: {
      fontSize: '0.75em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.2,
      marginTop: 8,
      marginBottom: 8,
    },
  },
  headerSvgIconStyle: {
    width: 42,
    height: 38,
  },
  palette: {
    type: 'light',
    primary: {
      light: '#335EB1',
      main: '#1c3462',
      dark: '#050A13',
      contrastText: '#fff',
    },
    secondary: {
      light: '#59b0c1',
      main: '#138fa7',
      dark: '#0d6474',
      contrastText: '#fff',
    },
    lightblue: {
      light: '#CDE9F0',
      main: '#7fc6d8',
      dark: '#389FB9',
      contrastText: '#fff',
    },
    green: {
      light: '#D3E098',
      main: '#B2C84A',
      dark: '#748428',
      contrastText: '#fff',
    },
    image:{
      main: '#71c8d9'
    },
    background: {
      main: '#fefefe',
      default: '#fefefe',
      paper: '#fff',
      body: '#e4e4e4',
      bars: '#fefefe',
      inputs: '#eeeeee',
    },
    text: {
      primary: '#000000',
      secondary: '#a3a3a3',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Rubik';
          src: url(${RubikLightEot});
          src: url('${RubikLightEot}?#iefix') format('embedded-opentype'),
              url(${RubikLightWoff2}) format('woff2'),
              url(${RubikLightWoff}) format('woff'),
              url(${RubikLightTtf}) format('truetype'),
              url('${RubikLightSvg}#Rubik-Light') format('svg');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
            font-family: 'Rubik';
            src: url('${RubikSemiBoldEot}?#iefix') format('embedded-opentype'),
              url(${RubikSemiBoldWoff2}) format('woff2'),
              url(${RubikSemiBoldWoff}) format('woff'),
              url(${RubikSemiBoldTtf}) format('truetype'),
              url('${RubikSemiBoldSvg}#Rubik-SemiBold') format('svg');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikLightItalicEot});
          src: url('${RubikLightItalicEot}?#iefix') format('embedded-opentype'),
              url(${RubikLightItalicWoff2}) format('woff2'),
              url(${RubikLightItalicWoff}) format('woff'),
              url(${RubikLightItalicTtf}) format('truetype'),
              url('${RubikLightItalicSvg}#Rubik-LightItalic') format('svg');
            font-weight: 300;
            font-style: italic;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikItalicEot});
            src: url('${RubikItalicEot}?#iefix') format('embedded-opentype'),
                url(${RubikItalicWoff2}) format('woff2'),
                url(${RubikItalicWoff}) format('woff'),
                url(${RubikItalicTtf}) format('truetype'),
                url('${RubikItalicSvg}#Rubik-Italic') format('svg');
            font-weight: normal;
            font-style: italic;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikRegularEot});
            src: url('${RubikRegularEot}?#iefix') format('embedded-opentype'),
              url(${RubikRegularWoff2}) format('woff2'),
              url(${RubikRegularWoff}) format('woff'),
              url(${RubikRegularTtf}) format('truetype'),
              url('${RubikRegularSvg}#Rubik-Regular') format('svg');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikMediumItalicEot});
            src: url('${RubikMediumItalicEot}?#iefix') format('embedded-opentype'),
              url(${RubikMediumItalicWoff2}) format('woff2'),
              url(${RubikMediumItalicWoff}) format('woff'),
              url(${RubikMediumItalicTtf}) format('truetype'),
              url('${RubikMediumItalicSvg}#Rubik-MediumItalic') format('svg');
            font-weight: 500;
            font-style: italic;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikMediumEot});
            src: url('${RubikMediumEot}?#iefix') format('embedded-opentype'),
              url(${RubikMediumWoff2}) format('woff2'),
              url(${RubikMediumWoff}) format('woff'),
              url(${RubikMediumTtf}) format('truetype'),
              url('${RubikMediumSvg}#Rubik-Medium') format('svg');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Rubik';
            src: url(${RubikSemiBoldItalicEot});
          src: url('${RubikSemiBoldItalicEot}?#iefix') format('embedded-opentype'),
              url(${RubikSemiBoldItalicWoff2}) format('woff2'),
              url(${RubikSemiBoldItalicWoff}) format('woff'),
              url(${RubikSemiBoldItalicTtf}) format('truetype'),
              url('${RubikSemiBoldItalicSvg}#Rubik-SemiBoldItalic') format('svg');
            font-weight: 600;
            font-style: italic;
            font-display: swap;
        }
      `,
    },
  },
  overrides: {
  }
};

let theme = createTheme(themeConfig);
theme = responsiveFontSizes(theme);
export default theme;
