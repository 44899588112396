import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function CustomExpandMore({ buttonSx = {}, color = 'primary', iconCollapsed, iconExpanded, expanded, handleChange}){
  const { t } = useTranslation();
  return (
    <IconButton
      onClick={() => handleChange(!expanded)}
      aria-expanded={expanded}
      aria-label="show more"
      color={color}
      sx={{ fontSize: 'inherit', ...buttonSx }}
    >
      {!expanded ? iconCollapsed : iconExpanded}
    </IconButton>
  );
}

CustomExpandMore.propTypes = {
  iconCollapsed: PropTypes.node,
  iconExpanded: PropTypes.node,
  expanded: PropTypes.bool,
  handleChange: PropTypes.func,
  color: PropTypes.string,
};

export default CustomExpandMore;
