import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {useDispatch, useSelector} from "react-redux";
import {
  setCmsActiveId, setCmsCopy,
  setCmsData,
  setConfirmation,
  updateCmsArticle,
  updateCmsDeletedArticles
} from "../../general/redux/actions";
import CMSTypeVerticalButtonGroup from "./CMSTypeVerticalButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ExpandOutlinedIcon from "@mui/icons-material/ExpandOutlined";
import CMSColorButton from "./CMSColorButton";
import Divider from "@mui/material/Divider";
import {CMSGROUPS} from "../utils/constants";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  checkNextIsDisabled,
  copyCmsArticle, createSubArticles, getArticleParent,
  getContainerChildLength,
  getLastChildIndexByParentArticle, getSubRanK
} from "../utils/helpers";
import {array_move} from "../../general/helpers";
import AddIcon from "@mui/icons-material/Add";

function CMSStructureBar( ){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsActiveId = useSelector((state) => state.default.cmsActiveId);
  const projectId = useSelector((state) => state.default.projectId);
  const journalId = useSelector((state) => state.default.journalId);
  const cmsData = useSelector((state) => state.default.cmsData);
  const currentUser = useSelector((state) => state.default.currentUser);
  const cmsDeletedArticles = useSelector((state) => state.default.cmsDeletedArticles);
  const [detail, setDetail] = React.useState(null);

  const buttonSx = {
    minWidth: 24,
    flexDirection: 'row',
    px: 1,
    '& .MuiButton-startIcon': {
      mx: 0,
    }
  };
  const isStructureElement = detail ? CMSGROUPS.buttonGroupStructure.indexOf(detail.label) !== -1 : false;
  const isSubArticle = detail && detail.type === 'cms_article_child';
  const parent = isSubArticle ? getArticleParent(cmsData, detail) : null;
  let isTopDisabled = detail ? cmsData.findIndex((it) => it.resourceId === detail.resourceId) === 0 : true;
  let isBottomDisabled = detail ? checkNextIsDisabled(cmsData, detail.resourceId) : true;

  if(isSubArticle){
    const pItemIndex = cmsData.findIndex((it) => it.resourceId === parent.resourceId);
    const cItemIndex = cmsData.findIndex((it) => it.resourceId === cmsActiveId);
    const pChildLength = getContainerChildLength(cmsData, pItemIndex);
    isTopDisabled = pItemIndex + 1 === cItemIndex;
    isBottomDisabled = cItemIndex === pItemIndex + pChildLength;
  }

  React.useEffect(() => {
    if(cmsActiveId){
      setDetail(cmsData.find((item) => item.resourceId === cmsActiveId));
    }

    return() => {};
  },[cmsActiveId, cmsData]);

  const handleConfirm = (actionType, customAction) => {
    const action = () => {};

    dispatch(setConfirmation({
      open: true,
      title: `cms.${actionType}.confirm.title`,
      description: `cms.${actionType}.confirm.description`,
      action: customAction || action,
    }));
  };

  const handleLabelChange = (e, val) => {
    if(val !== null){
      setDetail((prevState) => {
        return {...prevState, label: val};
      });
      dispatch(updateCmsArticle({
        rId: cmsActiveId,
        name: 'label',
        val
      }));
    }
  };

  const updateCmsStyle = (style, value) => {

    dispatch(setCmsData(cmsData.map((it) => {
      if(it.resourceId === cmsActiveId){

        return {
          ...it,
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              [style]: value
            }
          }
        };
      }
      return it;
    })));
  }

  const copyArticle = (aId, cData) => {
    const copiedArticles = copyCmsArticle(aId,[...cData]);
    dispatch(setCmsCopy(copiedArticles));
  }

  const addSubArticle = (aId, cData) => {
    const currentIndex = cData.findIndex((it) => it.resourceId === aId);
    const lastChildIndex = getLastChildIndexByParentArticle(cData, currentIndex);
    const subLabel = cData[lastChildIndex].label;
    const subArticle = createSubArticles(1, true, journalId, projectId, null, cData[lastChildIndex].rank + 2, currentUser.userId, subLabel)[0];
    const updateData = [...cData];
    updateData.splice(lastChildIndex + 1, 0, subArticle);
    dispatch(setCmsData(updateData));
  }

  const moveSubArticle = (dir, aId, cData) => {
    const currentIndex = cData.findIndex((it) => it.resourceId === aId);
    const parentIndex = cmsData.findIndex((it) => it.resourceId === parent.resourceId);
    const subLength = getContainerChildLength(cmsData, parentIndex);

    let nextPosition = currentIndex + (dir === 'top' ?  -1 : 1);
    let updatedData = [...cData];
    updatedData = array_move(updatedData,currentIndex, nextPosition);

    let outerCount = 0;
    let rankAdd = 0;
    let pRank = 0;
    updatedData = updatedData.map((it, i) => {
      let newRank;
      if(outerCount > 0 && outerCount <= subLength){
        newRank = pRank + outerCount + rankAdd;
        rankAdd++;
        outerCount++;
        return {
          ...it,
          rank: newRank
        }
      }
      if(i === parentIndex){
        outerCount++;
        pRank = it.rank;
      }
      return it;
    });

    dispatch(setCmsData(updatedData));
  }

  const moveArticle = (dir, aId, cData) => {
    const currentIndex = cData.findIndex((it) => it.resourceId === aId);
    const isStructureElement = CMSGROUPS.buttonGroupStructure.indexOf(cData[currentIndex].label) !== -1;
    let nextPosition = currentIndex +
      (isStructureElement && dir === 'bottom' ? getContainerChildLength(cData,currentIndex) : 0) +
      (dir === 'top' ?  -1 : 1);
    let updatedData = [...cData];

    if(dir === 'top' && currentIndex !== 0){
      if(updatedData[nextPosition].type === 'cms_article_child'){
        nextPosition = updatedData.findIndex((it) => it.rank === (Math.ceil(updatedData[nextPosition].rank / 100) * 100) - 100)
      }

      if(isStructureElement){
        const lastChildIndex = getLastChildIndexByParentArticle(cmsData, currentIndex);
        const iterations = getContainerChildLength(cmsData, currentIndex);
        for (let iteration = 0;iteration <= iterations; iteration++) {
          updatedData = array_move(updatedData,lastChildIndex, nextPosition) ;
        }
      }
    }

    if(dir === 'bottom' && currentIndex !== updatedData.length -1) {
      if(CMSGROUPS.buttonGroupStructure.indexOf(updatedData[nextPosition].label) !== -1){
        nextPosition = nextPosition + getContainerChildLength(updatedData, nextPosition);
      }
      if(nextPosition > cmsData.length - 1){
        nextPosition = cmsData.length -1;
      }
      if(isStructureElement){
        const iterations = getContainerChildLength(cmsData, currentIndex);
        // nextPosition = currentIndex + iterations + 1;
        for (let iteration = 0;iteration <= iterations; iteration++) {
          updatedData = array_move(updatedData,currentIndex, nextPosition);
        }
      }
    }

    if(!isStructureElement) {
      updatedData = array_move(updatedData,currentIndex, nextPosition);
    }

    let outerCount = 0;
    updatedData = updatedData.map((it) => {
      let newRank;
      if(it.rank % 100 === 0){
        outerCount++;
        newRank = outerCount * 100;
      } else {
        const subRank = getSubRanK(it.rank);
        newRank = (outerCount * 100 || 100) + subRank
      }

      return {
        ...it,
        rank: newRank
      };
    });

    dispatch(setCmsData(updatedData));

  }

  const unpublishArticle = () => {
    const targetItem = cmsData.find((it) => it.resourceId === cmsActiveId);
    if(CMSGROUPS.buttonGroupStructure.indexOf(targetItem.label) !== -1){
      let targetIndex = cmsData.findIndex((it) => it.resourceId === cmsActiveId);
      const childLength = getContainerChildLength(cmsData, targetIndex);
      const unpublishedIds = [];
      for(let i = 0; i <= childLength; i++){
        const rId = cmsData[targetIndex].resourceId;
        unpublishedIds.push(rId);
        targetIndex++
      }

      dispatch(setCmsData(cmsData.map((it) => {
        if(unpublishedIds.indexOf(it.resourceId) !== -1){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }else {
      dispatch(setCmsData(cmsData.map((it) => {
        if(it.resourceId === cmsActiveId){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }
    dispatch(setCmsActiveId(''));
  };

  const deleteArticle = () => {
    const targetItem = cmsData.find((it) => it.resourceId === cmsActiveId);
    if(targetItem.type === 'cms_article_child') {
      if(cmsActiveId.indexOf('created') === -1){
        dispatch(updateCmsDeletedArticles([...cmsDeletedArticles, cmsActiveId]));
      }
      dispatch(setCmsData(cmsData.map((item) => {
        if(item.resourceId === parent.resourceId){
          return { ...item };
        }
        if(item.resourceId === cmsActiveId){
          return { ...item, markedForDeletion: true };
        }
        return item;
      })));
    } else if(CMSGROUPS.buttonGroupStructure.indexOf(targetItem.label) !== -1){
      let targetIndex = cmsData.findIndex((it) => it.resourceId === cmsActiveId);
      const childLength = getContainerChildLength(cmsData, targetIndex);
      const deleteIds = [];
      const deleteArticlesIds = [];
      for(let i = 0; i <= childLength; i++){
        const rId = cmsData[targetIndex].resourceId;
        deleteIds.push(rId);
        if(rId.indexOf('created') === -1){
          deleteArticlesIds.push(rId);
        }
        targetIndex++
      }

      dispatch(updateCmsDeletedArticles([...cmsDeletedArticles, ...deleteArticlesIds]));
      dispatch(setCmsData(cmsData.map((item) => {
        if(deleteIds.indexOf(item.resourceId) !== -1){
          return { ...item, markedForDeletion: true };
        }
        return item;
      })));
    } else {
      if(cmsActiveId.indexOf('created') === -1){
        dispatch(updateCmsDeletedArticles([...cmsDeletedArticles, cmsActiveId]));
      }
      dispatch(setCmsData(cmsData.map((item) => {
        if(item.resourceId === cmsActiveId){
          return { ...item, markedForDeletion: true };
        }
        return item;
      })));
    }
    dispatch(setCmsActiveId(''));
  };

  const toggleFullSection = () => {
    const isFullSection = detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.fullSection;
    updateCmsStyle('fullSection', !isFullSection);
  }

  const toggleExpandable = () => {
    const isExpandable = detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.expandable;
    updateCmsStyle('expandable', !isExpandable);
  }

  return (
    <>
      {cmsActiveId && (
        <Box
          className="cms-article-structure-bar cms-non-closable"
          component={Paper}
          elevation={4}
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            left: 24,
            top: 72,
            minWidth: 80,
          }}
        >
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip title={t('button.unpublish')}>
              <IconButton
                // color="error"
                onClick={() =>{
                  return handleConfirm(
                    detail && detail.published ? 'unpublish' : 'publish',
                    () => unpublishArticle(),
                  );
                }}
              >
                {detail && detail.published ? <VisibilityOff fontSize="inherit" /> : <Visibility fontSize="inherit" />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t('button.delete')}>
              <IconButton
                // color="error"
                onClick={() =>{
                  return handleConfirm(
                    'deleteArticle',
                    () => deleteArticle(),
                  );
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {!isSubArticle && (
              <Tooltip title={t('cms.copy.tooltip')}>
                <IconButton
                  sx={{}}
                  onClick={() => copyArticle( detail.resourceId, cmsData)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {isStructureElement && detail && (detail.label === 'slider' || detail.label === 'sequenceContainer') && (
              <Tooltip title={t('cms.add.tooltip')}>
                <IconButton
                  sx={{}}
                  onClick={() => addSubArticle( detail.resourceId, cmsData)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Divider sx={{my: 1}}/>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip title={t('cms.move.top')}>
              <Button
                disabled={isTopDisabled}
                variant="contained"
                sx={{...buttonSx, ml: 'auto'}}
                startIcon={isSubArticle && detail && detail.label !== 'sequenceItem' ? <NavigateBeforeIcon /> : <ExpandLessIcon />}
                color="secondary"
                onClick={
                  isSubArticle
                    ? () => moveSubArticle('top', detail.resourceId, cmsData, parent)
                    : () => moveArticle('top', detail.resourceId, cmsData)
                }
              >
                &nbsp;
              </Button>
            </Tooltip>
            <Tooltip title={t('cms.move.bottom')}>
              <Button
                disabled={isBottomDisabled}
                variant="contained"
                sx={{...buttonSx, mr: 'auto'}}
                startIcon={isSubArticle && detail && detail.label !== 'sequenceItem' ? <NavigateNextIcon /> : <ExpandMoreIcon />}
                color="secondary"
                onClick={
                  isSubArticle
                    ? () => moveSubArticle('bottom', detail.resourceId, cmsData, parent)
                    : () => moveArticle('bottom', detail.resourceId, cmsData)
                }
              >
                &nbsp;
              </Button>
            </Tooltip>
          </Box>
          <Divider sx={{my: 1}}/>
          <CMSTypeVerticalButtonGroup
            activeId={cmsActiveId}
            cmsData={cmsData}
            handleChange={handleLabelChange}
            value={detail ? detail.label : ''}
            vertical
            isStructureElement={isStructureElement}
          />
          <Divider sx={{my: 1}}/>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip followCursor title={t('cms.toggle.fullSection')}>
              <IconButton onClick={toggleFullSection} sx={{'& svg': {pointerEvents: 'none'}}}>
                {
                  detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.fullSection
                    ? <CloseFullscreenIcon />
                    : <OpenInFullIcon />
                }
              </IconButton>
            </Tooltip>
            <Tooltip followCursor title={t('cms.toggle.expandable')}>
              <IconButton onClick={toggleExpandable}>
                <ExpandOutlinedIcon
                  color={
                    detail && detail.properties && detail.properties.cmsStyle && detail.properties.cmsStyle.expandable
                      ? 'success'
                      : 'default'
                  }
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip followCursor title={t('cms.toggle.text')}>
              <CMSColorButton
                resource={detail}
                type="text"
                updateCmsStyle={updateCmsStyle}
              />
            </Tooltip>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip followCursor title={t('cms.toggle.box')}>
              <CMSColorButton
                type="box"
                resource={detail}
                updateCmsStyle={updateCmsStyle}
              />
            </Tooltip>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip followCursor title={t('cms.toggle.border')}>
              <CMSColorButton
                resource={detail}
                type="border"
                updateCmsStyle={updateCmsStyle}
              />
            </Tooltip>
          </Box>
        </Box>
      )}
    </>
  );
}

CMSStructureBar.propTypes = {
  cmsActiveId: PropTypes.string,
};

export default CMSStructureBar;
