/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StandardCenter = (props) => {
  return (
    <SvgIcon {...props}>
      <g stroke="null">
        <title stroke="null">Layer 1</title>
        <line stroke-width="3" stroke="inherit" id="svg_11" y2="6.375" x2="18" y1="6.375" x1="6" fill="none"/>
        <line stroke="inherit" id="svg_3" y2="10.25001" x2="18" y1="10.25001" x1="6" fill="none"/>
        <line stroke="inherit" id="svg_4" y2="12.91668" x2="18" y1="12.91668" x1="6" fill="none"/>
        <line stroke="inherit" id="svg_5" y2="15.58335" x2="18" y1="15.58335" x1="6" fill="none"/>
        <line stroke="inherit" id="svg_6" y2="18.25002" x2="18" y1="18.25002" x1="6" fill="none"/>
      </g>

    </SvgIcon>
  );
};

StandardCenter.propTypes = {};

export default StandardCenter;
