import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import Box from '@mui/material/Box';
import CMSImage from "./CMSImage";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import {updateCmsArticle} from "../../general/redux/actions";
import {isMultiCol} from "../utils/cms";
import {alpha} from "@mui/material/styles";

function CMSImageWithOverlay({ resource, parent, mode }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hover, setHover] = React.useState(false);
  const containerRef = React.useRef(null);
  const titleRef = React.useRef(null);

  const smallFontSx = isMultiCol(parent, true)  ? {
    '& h2': {
      fontSize: '115%',
    },
    pt: 1,
    pb: 1,
    pl: 1,
    pr: 1,
  }: {};

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      ref={containerRef}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pointerEvents: mode !== 'preview' ? 'initial' : 'none',
        }}
      >
        <CMSImage
          resource={resource}
          parent={parent}
        />
      </Box>
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          backgroundColor: 'background.alphaBlue',
          transitionProperty: 'height',
          transitionDuration: '0.5s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0.1s',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          bottom: 0,
          height: hover ? '100%' : titleRef && titleRef.current ? titleRef.current.clientHeight + 16 : 32,
          pt: 2,
          pb: 2,
          pl: 2,
          pr: 2,
          ...smallFontSx
        }}
      >
        <Box ref={titleRef}>
          <EditableTextSlim
            handleChange={(val) => {
              dispatch(updateCmsArticle({
                rId: resource.resourceId,
                name: 'title',
                val
              }))
            }}
            currentValue={resource.title}
            variant="h2"
            placeholder={t('cms.field.title.placeholder')}
            disabled={mode !== 'edit'}
          />
        </Box>
        <EditableTextAreaSlim
          name="note"
          inline
          resource={resource}
          placeholder={t('cms.field.note.placeholder')}
          currentValue={resource.note}
          handleChange={(val) => {
            dispatch(updateCmsArticle({
              rId: resource.resourceId,
              name: 'note',
              val
            }))
          }}
          variant={isMultiCol(parent, true) ? 'body2' : 'body1'}
          minHeight={0}
          maxWidth={1152}
          disabled={mode !== 'edit'}
        />
      </Box>
    </Box>
  );
}

CMSImageWithOverlay.propTypes = {
  resource: PropTypes.object,
  inExpandable: PropTypes.bool,
  mode: PropTypes.string,
};

export default CMSImageWithOverlay;
