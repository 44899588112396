import React from 'react';
import PropTypes from 'prop-types';
import {ChromePicker, SketchPicker} from "react-color";
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import Crop75TwoToneIcon from "@mui/icons-material/Crop75TwoTone";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

function CMSColorButton(
  {
    type,
    resource,
    updateCmsStyle
  }
){
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const toggleBoxLayout = (val) => {
    updateCmsStyle('box', val);
  }

  const changeColor = (cType) => (color) => {
    let usedCType = 'textColor';
    if(cType === 'box'){
      usedCType = 'boxColor'
    }
    if(cType === 'border'){
      usedCType = 'borderColor'
    }
    updateCmsStyle(usedCType, color.hex);
  }

  const handleBorderChange = (name) => (event) => {
    updateCmsStyle(name, event.target.value);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getCurrentColor = (prop) => {
    switch (prop) {
      case 'box':
        return resource && resource.properties && resource.properties.cmsStyle
          ? resource.properties.cmsStyle.boxColor || '#def0f5'
          : '#def0f5'
      case 'border':
        return resource && resource.properties && resource.properties.cmsStyle
          ? resource.properties.cmsStyle.borderColor || '#ffffff'
          : '#ffffff'
      default:
        return resource && resource.properties && resource.properties.cmsStyle
          ? resource.properties.cmsStyle.textColor || 'textPrimary'
          : 'textPrimary'
    }

  }
  const currentColor = getCurrentColor(type);
  const isBox = resource
    && resource.properties
    && resource.properties.cmsStyle
    && resource.properties.cmsStyle.box;

  return (
    <React.Fragment>
      <IconButton
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select moderation status"
        aria-haspopup="menu"
        onClick={handleToggle}
        color={
          isBox
            ? 'success'
            : 'default'
        }
      >
        {type === 'text' && (
          <FormatColorTextOutlinedIcon />
        )}
        {type === 'border' && (
          <BorderStyleIcon />
        )}
        {type === 'box' && (
          <Crop75TwoToneIcon/>
        )}
        <ArrowDropDownIcon color="secondary"/>
      </IconButton>
      <ButtonGroup
        sx={{boxShadow: 'none'}}
        elevation={0}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement="right-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper elevation={2} sx={{p: 2, minWidth: 400 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {type === 'box' && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                          }}
                        >

                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <TextField
                              id="radius"
                              value={
                                resource && resource.properties && resource.properties.cmsStyle
                                  ? resource.properties.cmsStyle.borderRadius || 0
                                  : 0
                              }
                              size="small"
                              sx={{mb: 1}}
                              onChange={handleBorderChange('borderRadius')}
                              label="Border-Radius"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                            <TextField
                              id="bpt"
                              size="small"
                              sx={{mb: 1}}
                              value={
                                resource && resource.properties && resource.properties.cmsStyle
                                  ? resource.properties.cmsStyle.boxPaddingTop || 4
                                  : 4
                              }
                              onChange={handleBorderChange('boxPaddingTop')}
                              label="Box-Padding-top"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                            <TextField
                              id="bpl"
                              size="small"
                              sx={{mb: 1}}
                              value={
                                resource && resource.properties && resource.properties.cmsStyle
                                  ? resource.properties.cmsStyle.boxPaddingLeft || 4
                                  : 4
                              }
                              onChange={handleBorderChange('boxPaddingLeft')}
                              label="Box-Padding-left"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                            <TextField
                              id="bpr"
                              size="small"
                              sx={{mb: 1}}
                              value={
                                resource && resource.properties && resource.properties.cmsStyle
                                  ? resource.properties.cmsStyle.boxPaddingRight || 4
                                  : 4
                              }
                              onChange={handleBorderChange('boxPaddingRight')}
                              label="Box-Padding-right"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                            <TextField
                              id="bpb"
                              size="small"
                              sx={{mb: 1}}
                              value={
                                resource && resource.properties && resource.properties.cmsStyle
                                  ? resource.properties.cmsStyle.boxPaddingBottom || 4
                                  : 4
                              }
                              onChange={handleBorderChange('boxPaddingBottom')}
                              label="Box-Padding-bottom"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              '& .sketch-picker': {
                                boxShadow: 'none !important'
                              }
                            }}
                          >
                            <SketchPicker
                              color={currentColor}
                              onChangeComplete={changeColor(type)}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >

                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isBox}
                                onChange={(e) => toggleBoxLayout(e.target.checked)}
                              />
                            }
                            label="Box aktivieren"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {type === 'border' && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <TextField
                          id="radius"
                          value={
                            resource && resource.properties && resource.properties.cmsStyle
                              ? resource.properties.cmsStyle.borderRadius || 0
                              : 0
                          }
                          size="small"
                          sx={{mb: 1}}
                          onChange={handleBorderChange('borderRadius')}
                          label="Border-Radius"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                        <TextField
                          id="width"
                          value={
                            resource && resource.properties && resource.properties.cmsStyle
                              ? resource.properties.cmsStyle.borderWidth || 0
                              : 0
                          }
                          onChange={handleBorderChange('borderWidth')}
                          label="Border-width"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          '& .sketch-picker': {
                            boxShadow: 'none !important'
                          }
                        }}
                      >
                        <SketchPicker
                          color={currentColor}
                          onChangeComplete={changeColor(type)}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

CMSColorButton.propTypes = {
  shade: PropTypes.bool,
};

export default CMSColorButton;
