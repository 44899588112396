import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import {handleFlatData, handleRepairRank} from "../utils/backwardCompatibility";
import {getCmsArticles} from "../../general/api";
import {setCmsData} from "../../general/redux/actions";
import CMSStageToolbar from "./CMSStageToolbar";
import CMSRoot from "./CMSRoot";

function DisplayMain(){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const journalId = useSelector((state) => state.default.journalId);
  const currentUser = useSelector((state) => state.default.currentUser);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const updateData = () => {
    setIsLoading(true);
    getCmsArticles(journalId).then((response) => {
      setData(handleFlatData(handleRepairRank(response.data.items || response.data)));
      dispatch(setCmsData(handleFlatData(handleRepairRank(response.data.items || response.data))));
      setIsLoading(false);
    });
  }

  React.useEffect(() => {
    if(currentUser){
      updateData();
    }
    return () => {};
  }, [currentUser]);

  return (
    <>
      <Box
        component={Paper}
        elevation={0}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxHeight: '100%',
          '& .far': {
            display: 'initial'
          },
          '& .fas': {
            display: 'initial'
          }
        }}
      >
        <CMSRoot />
      </Box>
    </>
  );
}

DisplayMain.propTypes = {};

export default DisplayMain;
