/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageTextOverlay = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path stroke="inherit" fill="#ffffff" opacity="undefined" d="m-10.95825,6.50004l7.5,0l0,8.29166l-7.5,0l0,-8.29166z" id="svg_9"/>
        <path id="svg_1" d="m29.54159,1.06253l23.0833,0l0,4.37499l-23.0833,0l0,-4.37499z" opacity="undefined" fill="inherit" stroke="#fff"/>
        <rect stroke="inherit" id="svg_2" height="20.12498" width="22.24998" y="1.93751" x="0.75001" fill="none"/>
        <g stroke="null" id="svg_4">
          <line stroke="inherit" fill="none" x1="3.01199" y1="16.5833" x2="20.48195" y2="16.5833" id="svg_3"/>
          <line stroke="inherit" fill="none" x1="3.01634" y1="19.99997" x2="20.56176" y2="19.99997" id="svg_5"/>
        </g>
        <path stroke="inherit" id="svg_6" d="m8.33937,9.34946l3,-4.7386l3,4.7386l-1.5,0l0,4.7614l-3,0l0,-4.7614l-1.5,0z" fill="none"/>
      </g>

    </SvgIcon>
  );
};

ImageTextOverlay.propTypes = {};

export default ImageTextOverlay;
