import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import Input from "@mui/material/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FormControl from "@mui/material/FormControl";
import TinyEditor from "./TinyEditor";

function DebouncedTiny({ target, placeholder, name, value, onChange, handleChange, inline, minHeight }){
  const { t } = useTranslation();

  return (
    <TinyEditor
      value={value}
      onChange={onChange}
      handleChange={handleChange}
      inline={inline}
      options={{
        height: minHeight || 180,
        placeholder: placeholder || '',
        fixed_toolbar_container: inline ? target: '',
        auto_focus: true,
      }}
    />
  );
}

DebouncedTiny.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default DebouncedTiny;
