import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import {checkTitleVariant, checkTypeHas} from "../utils/helpers";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import { updateCmsArticle} from "../../general/redux/actions";
import {getBoxStyle, getTextColor, isBox, isMultiCol} from "../utils/cms";

function CMSStandard({ resource, noteOnly, mode, parent}){
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const variant = checkTitleVariant(resource.label);

  const boxPt = isBox(resource) ? getBoxStyle(resource, 'boxPaddingTop', 2) : 0;
  const boxPl = isBox(resource) ? getBoxStyle(resource, 'boxPaddingLeft', 2) : 0;
  const boxPr = isBox(resource) ? getBoxStyle(resource, 'boxPaddingRight', 2) : 0;
  const boxPb = isBox(resource) ? getBoxStyle(resource, 'boxPaddingBottom', 2) : 0;
  const textColor = getTextColor(resource);
  const smallFontSx = isMultiCol(parent, true)  ? {
    '& h2': {
      fontSize: '115%',
    }
  }: {};

  const centeredTypes = ['h1only', 'h2only', 'standardcenter'];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: centeredTypes.indexOf(resource.label) !== -1 ? 'center' : 'flex-start',
        pt: boxPt,
        pb: boxPb,
        pl: boxPl,
        pr: boxPr,
        ...smallFontSx
      }}
    >
      {!noteOnly && checkTypeHas('title', resource.label) && (
        <EditableTextSlim
          handleChange={(val) => {
            dispatch(updateCmsArticle({
              rId: resource.resourceId,
              name: 'title',
              val
            }))
          }}
          color={textColor}
          center={['h1only','h2only'].indexOf(resource.label) !== -1}
          currentValue={resource.title}
          variant={variant}
          placeholder={t('cms.field.title.placeholder')}
          disabled={mode !== 'edit'}
        />
      )}
      {Boolean(noteOnly || checkTypeHas('note', resource.label)) && (
        <EditableTextAreaSlim
          name="note"
          inline
          color={textColor}
          resource={resource}
          placeholder={t('cms.field.note.placeholder')}
          currentValue={resource.note}
          handleChange={(val) => {
            dispatch(updateCmsArticle({
              rId: resource.resourceId,
              name: 'note',
              val
            }))
          }}
          variant={isMultiCol(parent, true) ? 'body2' : 'body1'}
          minHeight={0}
          maxWidth={1152}
          disabled={mode !== 'edit'}
        />
      )}
    </Box>
  );
}

CMSStandard.propTypes = {
  resource: PropTypes.object,
  noteOnly: PropTypes.bool,
  mode: PropTypes.string,
};

export default CMSStandard;
