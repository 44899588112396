/* globals PROJECTID */
/* globals JOURNALID */
import React from 'react';
import {useDispatch} from "react-redux";
import Box from "@mui/material/Box";
import {
  getJournalProperties,
  getToken,
} from "../general/api";
import {
  setProjectId,
  setJournalId,
  resetCurrentUser,
  updateCurrentUser, setProperties, toggleCmsSidebar,
} from "../general/redux/actions";
import MessageContainer from "../general/components/MessageContainer";
import Loading from "../general/components/Loading";
import CssBaseline from "@mui/material/CssBaseline";
import DisplayMain from "./components/DisplayMain";

function DisplayApp({ }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    dispatch(setProjectId(PROJECTID));
    dispatch(setJournalId(JOURNALID));
    getToken().then((response) => {
      if (response.data) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
      }
      dispatch(updateCurrentUser(response.data || null));
    });

    getJournalProperties(PROJECTID, JOURNALID).then((response) => {
      dispatch(setProperties(response.data || []));
      if(response.data && response.data['journal.layout.noSidebar']){
        dispatch(toggleCmsSidebar(true))
      }
    });

    return () => {
      dispatch(resetCurrentUser(null))
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '100%' }}>
      <CssBaseline />
      {!loading ? (
        <DisplayMain />
      ) : (
        <Loading />
      )}
      <MessageContainer />
    </Box>
  );
}

DisplayApp.propTypes = { };

export default DisplayApp;