import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import RestoreIcon from '@mui/icons-material/Restore';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { uriToFile} from "../../general/formApi";
import {useDispatch, useSelector} from "react-redux";
import {
  setConfirmation,
  updateCmsAddedAttachments,
  updateCmsArticle,
  updateCmsDeletedAttachments, updateCmsUpdatedAttachments
} from "../../general/redux/actions";
import Zoom from "@mui/material/Zoom";
import Resizer from "react-image-file-resizer";
import {fakeStringId} from "../../general/helpers";
import CMSImageCropper from "./CMSImageCropper";
import {checkCropNeed} from "../utils/helpers";
import Tooltip from "@mui/material/Tooltip";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const Transition = React.forwardRef((props, ref) => {
  const { open } = props;
  return <Zoom style={{ transitionDelay: open ? '500ms' : '0ms' }} ref={ref} {...props} />;
});

Transition.propTypes = {
  open: PropTypes.bool,
};

function CMSImageEdit({
  resource,
  image,
  open,
  handleClose,
  aspect,
  width,
  height,
}){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adoptButtonRef = React.useRef(null);
  const cmsAddedAttachments = useSelector((state) => state.default.cmsAddedAttachments);
  const cmsUpdatedAttachments = useSelector((state) => state.default.cmsUpdatedAttachments);
  const cmsDeletedAttachments = useSelector((state) => state.default.cmsDeletedAttachments);
  const [cropOpen, setCropOpen] = React.useState(false);
  const [imageBroken, setImageBroken] = React.useState(false);
  const [sendDisabled, setSendDisabled] = React.useState(true);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedFilePreview, setSelectedFilePreview] = React.useState(null);
  const [selectedFileName, setSelectedFileName] = React.useState(image ? image.name : '');
  const [selectedFileDesc, setSelectedFileDesc] = React.useState(image ? image.description : '');
  const [selectedFileAlt, setSelectedFileAlt] = React.useState(image ? image.alt : '');
  const [selectedFileType, setSelectedFileType] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [cropData, setCropData] = React.useState("#");
  const [cropper, setCropper] = React.useState();
  const cropperRef = React.useRef(null);

  const config = { compress: false };

  const restorable = cmsDeletedAttachments.find((image) => image.resourceId === resource.id);

  React.useEffect(() => {
    if(resource && resource.resourceId){
      setImageBroken(false);
      setSelectedFile(null);
      setSelectedFilePreview(null);
      setSelectedFileName(image ? image.name : '');
      setSelectedFileDesc(image ? image.description : '');
      setSelectedFileAlt(image ? image.alt : '');
      setSelectedFileType('');
      setImageBroken(false);
    }
    return() => {
      setSelectedFile(null);
      setSelectedFilePreview(null);
      setSelectedFileName('');
      setSelectedFileName('');
      setSelectedFileDesc('');
      setSelectedFileAlt('');
      setSelectedFileType('');
      setImageBroken(false);
    };
  },[resource]);

  const handleCancel = () => {
    setSelectedFile(null);
    setSelectedFilePreview(null);
    setSelectedFileName(image ? image.name : '');
    setSelectedFileDesc(image ? image.description : '');
    setSelectedFileAlt(image ? image.alt : '');
    setSelectedFileType('');
  };

  const handleConfirm = (actionType, customAction) => {
    const action = () => {};

    dispatch(setConfirmation({
      open: true,
      title: `image.${actionType}.confirm.title`,
      description: `image.${actionType}.confirm.description`,
      action: customAction || action,
    }));
  };

  const handleRestoreImage = () => {
    // TODO finish restore mechanism
    setSelectedFile(null);
    setSelectedFilePreview(null);
    setSelectedFileName(restorable.name || '');
    setSelectedFileDesc(restorable.description || '');
    setSelectedFileAlt(restorable.alt || '');
    setSelectedFileType(restorable.mimeType);
  };

  const deleteImage = () => {
    handleCancel();
    if(resource && resource.attachments && resource.attachments.length && isNaN(parseInt(resource.attachments[0].id))){
      dispatch(updateCmsAddedAttachments(cmsAddedAttachments.filter((att) => att.id !== resource.attachments[0].id)));
    }
    if(resource && resource.attachments && resource.attachments.length && !isNaN(parseInt(resource.attachments[0].id))) {
      dispatch(updateCmsDeletedAttachments([
        ...cmsDeletedAttachments,
        {
          ...resource.attachments[0],
          resourceId: resource.id
        }
      ]));
    }
    dispatch(updateCmsArticle({
      rId: resource.resourceId,
      name: 'attachments',
      val: [],
    }))
  }

  const addImage = () => {
    if(selectedFile){
      if(resource && resource.attachments && resource.attachments.length && !isNaN(parseInt(resource.attachments[0].id))) {
        dispatch(updateCmsDeletedAttachments([
          ...cmsDeletedAttachments,
          {
            ...resource.attachments[0],
            resourceId: resource.id
          }
        ]));
      }

      const newImage ={
        id: fakeStringId(),
        resourceId: !isNaN(parseInt(resource.id)) ? resource.id : resource.resourceId,
        file: selectedFile,
        url: selectedFilePreview,
        name: selectedFileName,
        alt: selectedFileAlt,
        description: selectedFileDesc,
      }
      dispatch(updateCmsArticle(
        {
          rId: resource.resourceId,
          name: 'attachments',
          val: [newImage ]
        }
      ))
      if(resource && resource.attachments && resource.attachments.length && isNaN(parseInt(resource.attachments[0].id))){
        dispatch(updateCmsAddedAttachments([
          ...cmsAddedAttachments.filter((att) => att.id !== resource.attachments[0].id),
          {
            ...newImage,
          }
        ]));
      }else {
        dispatch(updateCmsAddedAttachments([
          ...cmsAddedAttachments,
          {
            ...newImage,
          }
        ]));
      }
    } else {
      const updatedCmsArticle = {
        ...resource.attachments[0],
        name: selectedFileName,
        alt: selectedFileAlt,
        description: selectedFileDesc,
      };

      dispatch(updateCmsArticle(
        {
          rId: resource.resourceId,
          name: 'attachments',
          val: [updatedCmsArticle]
        }
      ))
      dispatch(updateCmsUpdatedAttachments([
        ...cmsUpdatedAttachments,
        {
          ...updatedCmsArticle,
        }
      ]));
    }



    handleClose();

  }

  const fileChangedHandler = (event) => {
    var file;
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
      file = event.target.files[0];
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          600,
          600,
          "JPEG",
          100,
          0,
          (uri) => {
            uriToFile(uri).then((f) => {
              setSelectedFilePreview(uri)
              setSelectedFileName(file.name);
              setSelectedFileDesc('');
              setSelectedFileAlt('');
              setSelectedFileType('JPEG');
              setSelectedFile(f);
              setSendDisabled(false);
            })
          },
          "base64",
          240,
          240
        );
      } catch (err) {
        console.debug(err);
      }
    }
  }

  const handleCancelCrop = () => {
    setCropOpen(false);
    if(resource && resource.resourceId){
      setImageBroken(false);
      setSelectedFile(null);
      setSelectedFilePreview(null);
      setSelectedFileName(image ? image.name : '');
      setSelectedFileDesc(image ? image.description : '');
      setSelectedFileAlt(image ? image.alt : '');
      setSelectedFileType('');
      setImageBroken(false);
    }
  }

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onload = function (e) {
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height1 = this.height;
        var width1 = this.width;
        setCropOpen(checkCropNeed(resource, width, width1, height, height1))
        return true;
      };
    }

    reader.onloadend = function(e) {
      setSelectedFilePreview(reader.result)
    };

    setSelectedFileName(file.name);
    setSelectedFileDesc('');
    setSelectedFileAlt('');
    setSelectedFileType(file.type);
    setSelectedFile(file);
    setSendDisabled(false);
  };

  const handleSaveCrop = (file, preview) => {
    setSelectedFileType(file.type);
    setSelectedFile(file);
    setSelectedFilePreview(preview);
  };

  const handleAdoptCropResult = () => {
    setLoading(true);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const uri = cropper.getCroppedCanvas().toDataURL();
    uriToFile(uri).then((f) => {
      handleSaveCrop(f, uri);
      setLoading(false);
      setCropOpen(false);
      // handleClose();
    })

  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}
      sx={{borderRadius: 0}}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography sx={{pb: 0}}>
        <Typography component="div" variant="h2">
          {t('image.edit.title')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{pb: 0}}>
        {cropOpen ? (
          <CMSImageCropper
            loading={loading}
            setLoading={setLoading}
            cropper={cropper}
            setCropper={setCropper}
            cropData={cropData}
            setCropData={setCropData}
            handleSave={handleAdoptCropResult}
            imgUrl={selectedFilePreview}
            aspect={aspect}
            width={width}
            height={height}
            cropperRef={cropperRef}
          />
        ) : (
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', position: 'relative'}}>
              <Box component={Paper} elevation={0} sx={{mx:'auto', position: 'relative', p: 1}}>
                <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {!imageBroken ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              width: 512,
                              minHeight: 400,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              p: 1,
                              backgroundColor: 'background.body'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mx: 'auto',
                                width: 384,
                                minHeight: 400,
                                '& img': {maxWidth: '100%', maxHeight: '100%'}
                              }}
                            >
                              <Box component="label" htmlFor="contained-button-file" sx={{}}>
                                {!selectedFilePreview && !Boolean(image && image.url) ? (
                                  <Fab
                                    component="span"
                                    sx={{width: 112, height: 112, borderRadius: 0}}
                                    color="info"
                                  >
                                    <AddPhotoAlternateIcon sx={{width: 112, height: 112}} />
                                  </Fab>
                                ) : (
                                  <img onLoad={() => setImageBroken(false)} onError={() => setImageBroken(true)} src={selectedFilePreview || image.url}/>
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              ml: 'auto',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip title={t('image.restore.tooltip')}>
                              <IconButton
                                color="error"
                                size="medium"
                                autoFocus
                                sx={{mr: 1}}
                                disabled={!Boolean(restorable)}
                                onClick={handleRestoreImage}
                              >
                                <RestoreIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('image.cancel.tooltip')}>
                              <IconButton
                                color="error"
                                size="medium"
                                autoFocus
                                sx={{mr: 1}}
                                disabled={!selectedFilePreview}
                                onClick={handleCancel}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('image.select.tooltip')}>
                              <Box component="label" htmlFor="contained-button-file" sx={{}}>
                                <IconButton
                                  component="span"
                                  color="primary"
                                  size="medium"
                                >
                                  <AddPhotoAlternateIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <Fab
                        component="span"
                        sx={{width: 112, height: 112, borderRadius: 0}}
                        color="warning"
                        onClick={() => {
                          return handleConfirm(
                            'deleteImage',
                            () => deleteImage(),
                          );
                        }}
                      >
                        <BrokenImageIcon sx={{width: 112, height: 112}}/>
                      </Fab>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 320,
                minHeight: 460,
                pl: 2,
                pr: 1,
                pt: 2,
                borderLeft: '1px solid',
                borderColor: "divider"
              }}
            >
              <TextField
                sx={{mb: 2}}
                variant="standard"
                disabled={Boolean(imageBroken)}
                id="name-text"
                label={t('image.field.name')}
                value={selectedFileName}
                onChange={(e) => {
                  if((image && e.target.value !== image.name) || !selectedFile ){
                    setSendDisabled(false);
                  }
                  setSelectedFileName(e.target.value);
                }}
              />
              <TextField
                sx={{mb: 2}}
                variant="standard"
                disabled={Boolean(imageBroken)}
                id="note-text"
                label={t('image.field.description')}
                multiline
                rows={3}
                value={selectedFileDesc}
                onChange={(e) => {
                  if((image && e.target.value !== image.description) || !selectedFile ){
                    setSendDisabled(false);
                  }
                  setSelectedFileDesc(e.target.value)
                }}
              />
              <TextField
                sx={{mb: 2}}
                variant="standard"
                disabled={Boolean(imageBroken)}
                id="alt-text"
                label={t('image.field.alt')}
                value={selectedFileAlt}
                onChange={(e) => {
                  if((image && e.target.value !== image.alt) || !selectedFile ){
                    setSendDisabled(false);
                  }
                  setSelectedFileAlt(e.target.value)
                }}
              />
              {imageBroken && (
                <Alert
                  variant="outlined"
                  severity="warning"
                >
                  {t('image.brokenText.alert')}
                  <Typography variant="body1">
                    {`${t('image.url.title')}: ${image.url}`}
                  </Typography>
                </Alert>
              )}
              <Box
                component="input"
                accept="image/*"
                sx={{display: 'none !important'}}
                id="contained-button-file"
                type="file"
                onChange={config && config.compress ? fileChangedHandler : handleUploadClick}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {cropOpen ? (
          <>
            <Button color="secondary" autoFocus onClick={handleCancelCrop}>
              {t('button.close')}
            </Button>
            <Button
              color="primary"
              onClick={handleAdoptCropResult}
              startIcon={<ContentCutIcon />}
            >
              {t('button.adopt')}
            </Button>
          </>
        ) :  (
          <>
            <Button color="secondary" autoFocus onClick={handleClose}>
              {t('button.close')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                return handleConfirm(
                  'deleteImage',
                  () => deleteImage(),
                );
              }}
              startIcon={<DeleteIcon/>}
              disabled={!Boolean(image && image.url)}
            >
              {t('button.deleteImage')}
            </Button>
            <Button onClick={addImage} startIcon={<ImageIcon />} disabled={sendDisabled} >
              {image && image.url ? t('button.changeImage') : t('button.adoptImage')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

CMSImageEdit.propTypes = {
  resource: PropTypes.object,
  image: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default CMSImageEdit;
