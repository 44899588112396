/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageAboveText = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <rect id="svg_11" height="4" width="12" y="0.4375" x="0.3125" stroke="inherit" fill="none"/>
        <g id="svg_12">
          <rect stroke-width="0" id="svg_1" height="4.125" width="24" y="7.5" x="0" stroke="inherit" fill="inherit"/>
          <line id="svg_2" y2="15.5" x2="24" y1="15.5" x1="0" stroke="inherit" fill="none"/>
          <line id="svg_3" y2="19.5" x2="24" y1="19.5" x1="0" stroke="inherit" fill="none"/>
          <line id="svg_6" y2="23.5" x2="24" y1="23.5" x1="0" stroke="inherit" fill="none"/>
        </g>
      </g>
    </SvgIcon>
  );
};

ImageAboveText.propTypes = {};

export default ImageAboveText;
