/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BoxImageBelowText = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <ellipse stroke="inherit" ry="4.24997" rx="4.37497" id="svg_2" cy="18" cx="12" fill="none"/>
        <line stroke="inherit" id="svg_3" y2="1" x2="24" y1="1" x1="0" fill="none"/>
        <line stroke="inherit" id="svg_4" y2="4" x2="24" y1="4" x1="0" fill="none"/>
        <line stroke="inherit" id="svg_5" y2="7" x2="24" y1="7" x1="0" fill="none"/>
        <line stroke="inherit" id="svg_6" y2="10" x2="24" y1="10" x1="0" fill="none"/>
      </g>
    </SvgIcon>
  );
};

BoxImageBelowText.propTypes = {};

export default BoxImageBelowText;
