/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TextOnly = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <line id="svg_2" y2="8" x2="24" y1="8" x1="0" />
        <line id="svg_3" y2="12" x2="24" y1="12" x1="0" />
        <line id="svg_6" y2="16" x2="24" y1="16" x1="0" />
        <line id="svg_7" y2="20" x2="24" y1="20" x1="0" />
        <line id="svg_8" y2="4" x2="23.9375" y1="4" x1="-0.125"/>
      </g>
    </SvgIcon>
  );
};

TextOnly.propTypes = {};

export default TextOnly;
