import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CMSImage from "./CMSImage";

function CMSAttachmentOnly({ resource, parent}){
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
      }}
    >
      <CMSImage
        resource={resource}
        parent={parent}
      />
    </Box>
  );
}

CMSAttachmentOnly.propTypes = {
  resource: PropTypes.object,
  parent: PropTypes.object,
};

export default CMSAttachmentOnly;
