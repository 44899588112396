/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const H2Only = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path stroke="#fff" id="svg_1" d="m2.87501,2.31252l17.99998,0l0,4.37499l-17.99998,0l0,-4.37499z" opacity="undefined" fill="inherit"/>
        <text font-weight="bold" transform="matrix(0.893627 0 0 0.812258 1.14671 2.27492)" stroke="#fff"  text-anchor="start" font-family="'Heebo'" font-size="14" stroke-width="0" id="svg_4" y="19.81249" x="3.09636" fill="inherit">H2</text>
      </g>
    </SvgIcon>
  );
};

H2Only.propTypes = {};

export default H2Only;
