import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import CMSImage from "./CMSImage";
import EditableTextSlim from "../../general/forms/EditableTextSlim";
import EditableTextAreaSlim from "../../general/forms/EditableTextAreaSlim";
import {updateCmsArticle, updateJoyrideCmsArticle} from "../../general/redux/actions";
import {getBoxStyle, getTextColor, isBox, isExpandable, isFullSection, isImageRounded, isMultiCol} from "../utils/cms";
import Typography from "@mui/material/Typography";
import CustomExpandMore from "../../general/components/CustomExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Collapse from "@mui/material/Collapse";
import CMSStandard from "./CMSStandard";
import CMSWithImage from "./CMSWithImage";

function CMSSequenceItem({ resource, parent, mode, index, items }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsMode = useSelector((state) => state.default.cmsMode);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      className="cms-sequence-item-root"
      sx={{
        display: 'flex',
        flexDirection: index % 2 === 0 ? 'row-reverse' : 'row',
        alignItems: 'stretch',
      }}
    >
      <Box
        className="cms-sequence-item-picture-root"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: index % 2 === 0 ? 0 : '45%',
          flexGrow: index % 2 === 0 ? 1 : 'unset',
        }}
      >
        <Box
          className="cms-sequence-item-picture-container"
          sx={{
            py: 2,
            position: 'relative',
            height: '100%',
            '&:before': {
              position: 'absolute',
              content: '""',
              width: 2,
              height: items && index === items.length - 1 ? 16 : '100%',
              backgroundColor: 'secondary.main',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 0,
              top: index === 0 ? 16 : 0,
              bottom: items && index === items.length - 1 ? 20 : 0,
            },
            '& img': {
              borderRadius: '50%',
              border: '2px solid',
              borderColor: 'secondary.main',
              backgroundColor: '#fefefe',
              cursor: 'pointer',
            }
          }}
        >
          <CMSImage
            resource={resource}
            parent={parent}
          />
        </Box>
      </Box>
      <Box
        className="cms-sequence-item-text-root"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          px: 2,
          width: index % 2 === 0 ? '45%' : 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Box
            className="cms-sequence-item-title"
            onClick={mode !== 'preview' ? null : handleExpandClick}
            sx={{
              flexGrow: 1,
              '& h2': {
                textAlign: index % 2 === 0 ? 'right' : 'left',
                color: 'secondary.main'
              }
            }}
          >
            <EditableTextSlim
              handleChange={(val) => {
                dispatch(updateCmsArticle({
                  rId: resource.resourceId,
                  name: 'title',
                  val
                }))
              }}
              color={getTextColor(resource)}
              currentValue={resource.title}
              variant="h2"
              placeholder={t('cms.field.title.placeholder')}
              disabled={mode !== 'edit'}
            />
          </Box>
        </Box>
        <Collapse
          className="cms-sequence-item-text-container"
          in={mode !== 'preview' ? true : expanded}
          timeout="auto"
          unmountOnExit
          sx={{
            '& .MuiTypography-body1': {
              textAlign: index % 2 === 0 ? 'right' : 'left',
            }
          }}
        >
          <CMSStandard
            resource={resource}
            noteOnly
            mode={mode}
          />
        </Collapse>
      </Box>
    </Box>
  );
}

CMSSequenceItem.propTypes = {
  resource: PropTypes.object,
  parent: PropTypes.object,
  joyrideMark: PropTypes.bool,
};

export default CMSSequenceItem;
