import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Confirmation from "./Confirmation";
import {hideToast, setMail} from "../../general/redux/actions";
import MailConfigurator from "./mail/MailConfigurator";


function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

function MessageContainer({ resource }){
  const dispatch = useDispatch();
  const confirm = useSelector((state) => state.default.confirm);
  const toast = useSelector((state) => state.default.toast);
  const mail = useSelector((state) => state.default.mail);

  const handleClose = () =>{
    if(toast){
      dispatch(hideToast());
    }
  }

  const handleCloseMail = () =>{
    if(mail){
      dispatch(setMail(null));
    }
  }

  const severity = toast ? toast.type : '';
  const message = toast ? toast.msg : '';

  return (
    <Box>
      <Confirmation {...confirm} open={Boolean(confirm)} />
      <Snackbar
        open={Boolean(toast)}
        autoHideDuration={3500}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} >
          {message}
        </Alert>
      </Snackbar>
      <MailConfigurator
        open={Boolean(mail)}
        handleClose={handleCloseMail}
        principal={mail && mail.principal ? mail.principal : null}
        resource={mail && mail.resource ? mail.resource : null}
        type={mail && mail.type ? mail.type: ''}
      />
    </Box>
  );
}

MessageContainer.propTypes = {
  resource: PropTypes.object,
};

export default MessageContainer;
