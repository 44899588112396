import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CMSArticle from "./CMSArticle";
import {useSelector} from "react-redux";
import CMSContainer from "./CMSContainer";
import CMSAddBar from "./CMSAddBar";
import {CMSGROUPS} from "../utils/constants";
import {getAddPosition} from "../utils/helpers";
import CMSCopyTarget from "./CMSCopyTarget";
import CMSArticleHidden from "./CMSArticleHidden";

function CMSStageItem(
  {
    item,
    cmsData,
    cmsAdd,
    cmsMode,
    cmsCopy,
    cmsActiveId,
    addArticle,
    activePosition,
    setActivePosition,
  }
){
  const isStructureArticle = CMSGROUPS.buttonGroupStructure.indexOf(item.label) !== -1;
  const nextPosition = getAddPosition(isStructureArticle, item, cmsData);

  return (
    <>
      {item.published ? (
        <>
          {isStructureArticle ? (
            <CMSContainer
              mode={cmsMode}
              resource={item}
              activeId={cmsActiveId}
            />
          ) : (
            <CMSArticle
              joyrideMark={item.joyrideMark}
              mode={cmsMode}
              resource={item}
              activeId={cmsActiveId}
            />
          )}
        </>
      ) : (
        <CMSArticleHidden
          mode={cmsMode}
          resource={item}
          activeId={cmsActiveId}
          isStructureArticle={isStructureArticle}
        />
      )}
      {cmsAdd && (
        <CMSAddBar
          active={cmsAdd}
          activePosition={activePosition}
          position={nextPosition}
          addArticle={() => addArticle(nextPosition)}
          handleClose={() => setActivePosition(-1)}
        />
      )}
      {Boolean(cmsCopy && cmsCopy.length) && (
        <CMSCopyTarget
          position={nextPosition}
        />
      )}
    </>
  )

}

function CMSStage({ }){
  const { t } = useTranslation();
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);
  const cmsMode = useSelector((state) => state.default.cmsMode);
  const cmsAdd = useSelector((state) => state.default.cmsAdd);
  const cmsCopy = useSelector((state) => state.default.cmsCopy);
  const cmsActiveId = useSelector((state) => state.default.cmsActiveId);
  const joyride = useSelector((state) => state.default.joyride);
  const joyrideCmsData = useSelector((state) => state.default.joyrideCmsData);
  const [activePosition, setActivePosition] = React.useState(-1);

  return (
    <Box
      className="cms-main-stage joyride-main-stage"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mx: 'auto',
        pt: cmsMode === 'structure' && cmsActiveId ? 5 : 2,
        pb: 3,
        width: cmsSidebar ? 1220 : 1456,
      }}
    >
      <CMSAddBar
        className="cms-article-add"
        active={cmsAdd}
        position={0}
        addArticle={() => setActivePosition(0)}
        activePosition={activePosition}
        handleClose={() => setActivePosition(-1)}
      />
      {Boolean(cmsCopy && cmsCopy.length) && (
        <CMSCopyTarget
          position={0}
        />
      )}
      {!joyride ? (
        <>
          {(cmsData || [])
            .filter((item) => !item.markedForDeletion && item.type === 'cms_article')
            .filter((item) => cmsMode === 'structure' ? true : item.published)
            .sort((a, b) => (a.rank > b.rank) ? 1 : -1)
            .map((item) => {
              return (
                <CMSStageItem
                  key={item.resourceId}
                  cmsMode={cmsMode}
                  cmsData={cmsData}
                  cmsAdd={cmsAdd}
                  cmsCopy={cmsCopy}
                  addArticle={setActivePosition}
                  activePosition={activePosition}
                  setActivePosition={setActivePosition}
                  item={item}
                  cmsActiveId={cmsActiveId}
                />
              );
            })
          }
        </>
      ) : (
        <>
          {(joyrideCmsData || [])
            .filter((item) => !item.markedForDeletion && item.type === 'cms_article')
            .filter((item) => cmsMode === 'structure' ? true : item.published)
            .sort((a, b) => (a.rank > b.rank) ? 1 : -1)
            .map((item) => {
              return (
                <CMSStageItem
                  key={item.resourceId}
                  cmsMode={cmsMode}
                  cmsData={cmsData}
                  cmsAdd={cmsAdd}
                  cmsCopy={cmsCopy}
                  addArticle={setActivePosition}
                  activePosition={activePosition}
                  setActivePosition={setActivePosition}
                  item={item}
                  cmsActiveId={cmsActiveId}
                />
              );
            })
          }
        </>
      )}
    </Box>
  );
}

CMSStage.propTypes = {
  shade: PropTypes.bool,
};

export default CMSStage;
