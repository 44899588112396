import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect, useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import UpdateIcon from '@mui/icons-material/Update';
import Zoom from "@mui/material/Zoom";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { displayToast } from '../../../general/redux/actions';
import TinyEditor from "../../forms/TinyEditor";
import {getMailPreview, searchResources, sendMail, sendMailToAuthor, sendTypedMail} from "../../api";
import Loading from "../Loading";
import Tooltip from "@mui/material/Tooltip";
import {checkAllOptionsAreEmails} from "../../helpers";
import {getConfigAttributeValue, getMainConfigValue} from "../../boardConfig";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import {validateMail} from "./helper";

moment.locale('de');

const Transition = React.forwardRef((props, ref) => {
  const { open } = props;
  return <Zoom style={{ transitionDelay: open ? '500ms' : '0ms' }} ref={ref} {...props} />;
});

Transition.propTypes = {
  open: PropTypes.bool,
};

function MailConfigurator({ type, open, handleClose, resource, principal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectId = useSelector((state) => state.default.projectId);
  const properties = useSelector((state) => state.default.properties);
  const recipientRef = React.useRef(null);
  const recipientCCRef = React.useRef(null);
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [includeResource, setIncludeResource] = React.useState(true);
  const [recipientEmails, setRecipientEmails] = React.useState([]);
  const [recipientEmailList, setRecipientEmailList] = React.useState("");
  const [recipientCCEmails, setRecipientCCEmails] = React.useState([]);
  const [recipientCCEmailList, setRecipientCCEmailList] = React.useState("");
  const [cc, setCC] = React.useState(false);
  const [addPrincipalToBCC, setAddPrincipalToBCC] = React.useState(false);
  const [error, setError] = React.useState({email: false, cc: false});
  const [submitting, setSubmitting] = React.useState(false);
  const [previewUpdating, setPreviewUpdating] = React.useState(false);
  const [previewHtml, setPreviewHtml] = React.useState(null);

  const logoUrl =
    properties && properties['dito.project.email.style.logoPath']
    ? `/dito/template_d7/HTMLMails/generic/${properties['dito.project.email.style.logoPath']}`
    : '';

  const updatePreview = (include) => {
    setPreviewUpdating(true);
    let sendData = {
      type,
      subject: subject || t('[Bitte einen Betreff eingeben]'),
      message: message || t('[Schreiben Sie Ihre Nachricht und Klicken Sie anschliessend auf den "Update"-Button um eine Vorschau Ihres Versands zu erhalten.]'),
      // recipientEmail: recipientEmailList,

    };

    if(type === 'mailToAuthor'){
      sendData = {
        ...sendData,
        resourceId: resource.resourceId,
        authorId: resource.authorId,
      }
      if(includeResource){
        sendData.resource =  {
          ...resource,
          creationDate: moment(resource.creationDate).format('LL')
        };
      }
      if(cc){
        sendData.ccToModerator = true;
      }
    }
    if(type === 'shareResource'){
      sendData = {
        ...sendData,
        resourceId: resource.resourceId,
        resource: {
          ...resource,
          creationDate: moment(resource.creationDate).format('LL')
        },
      }
    }
    if(type === 'messageOnly'){
      sendData = {
        ...sendData,
        recipientName: principal.name,
      }
    }
    getMailPreview(sendData, projectId).then((response) => {
      setPreviewHtml(
        response.data.replace(
          'cid:logo.png',
          logoUrl || '/dito/template_d7/img/logo.png'
        )
      );
      setPreviewUpdating(false);
    })
  };

  React.useEffect(() => {
    let isMounted = true;
    if((
      open && resource && resource.resourceId) ||
      (open && principal && principal.principalId)
    ){
      updatePreview();
    }

    return () => {
      isMounted = false;
      setSubject('');
      setMessage('');
      setCC(false);
      setRecipientEmails([]);
      setRecipientEmailList('');
      setRecipientCCEmails([]);
      setRecipientCCEmailList('');
    };
  }, [open]);

  const handleSend = () => {
    let msg = {
      type,
      recipientEmail: recipientEmails.join(','),
      subject,
      addCC: cc,
      ccEmailAddress: recipientCCEmails.join(','),
      message,
    };
    if(type === 'shareResource'){
      msg = {
        ...msg,
        resourceId: resource.id,
        resourceType: resource.type,
        resource: {
          ...resource,
          creationDate: moment(resource.creationDate).format('LL')
        },
        addPrincipalToBCC: addPrincipalToBCC ? resource.authorId : null,
      }
    }
    if(type === 'messageOnly'){
      msg = {
        ...msg,
        recipientEmail: `${principal.email}${msg.recipientEmail && msg.recipientEmail.length ? ',' : ''}${msg.recipientEmail}`,
        recipientName: principal.name,
      }
    }

    // const errors = validateMail(type, msg);
    //
    // if(Object.keys(errors).length === 0 && errors.constructor === Object){
      setSubmitting(true);
      sendTypedMail(msg, projectId).then((res) => {
        if (res && res.status === 204) {
          dispatch(displayToast({ msg: t('mail.sent.success'), type: 'success' }));
          handleClose();
        } else if(res && res.status === 404) {
          dispatch(displayToast({ msg: t('mail.principal.error'), type: 'error' }));
        } else {
          dispatch(displayToast({ msg: t('mail.sent.error'), type: 'error' }));
        }
        setSubmitting(false);
      });
    // } else {
    //   console.log('contains errors');
    // }
  };

  const handleSendToAuthor = (include) => {
    const msg = {
      type,
      subject,
      message,
      resourceId: resource.id,
      resourceType: resource.type,
      authorId: resource.authorId
    };

    if(include){
      msg.resource =  {
        ...resource,
        creationDate: moment(resource.creationDate).format('LL')
      };
    }

    if(cc){
      msg.ccToModerator = true;
    }

    sendMailToAuthor(msg,projectId).then((res) => {
      if (res && res.status === 204) {
        dispatch(displayToast({ msg: t('mail.sent.success'), type: 'success' }));
        handleClose();
      } else {
        dispatch(displayToast({ msg: t('mail.sent.error'), type: 'error' }));
      }
      setSubmitting(false);
    });
  };

  const handleTitle = (msgType) => {
    switch(msgType){
      case 'shareResource':
        return `${resource.type}.share.title`;
      default:
        return 'mail.toUser.title';
    }
  }

  const isSendDisabled = () => {
    if(type === 'mailToAuthor' || type === 'messageOnly'){
      return Boolean(
          !subject || !subject.length ||
          !message || !message.length
        ) ||
        submitting;
    }
    return Boolean(
      !subject || !subject.length ||
      !message || !message.length ||
      !recipientEmails || !recipientEmails.length
    ) ||
    submitting;
  }
  const emailListToUse =
    properties && properties['dito.project.sendToOutside.emailsList']
      ? properties['dito.project.sendToOutside.emailsList']
      : '';
  const emailCCListToUse = properties && properties['dito.project.sendToOutside.emailsList']
    ? properties['dito.project.sendToOutside.emailsListCC']
    : '';


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen
      disableEnforceFocus
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar variant="dense">
          <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
            {t(handleTitle(type))}
          </Typography>
          <IconButton
            edge="end"
            size="large"
            autoFocus
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box dividers sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1,overflow: 'hidden', position: 'relative' }}>
        <Box sx={{display: 'flex', flexDirection: 'row', flexGrow: 1, height: '100%'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, maxWidth: '50%', p: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {type === 'mailToAuthor' || type === 'messageOnly' ? (
                <Typography
                  component="div"
                  variant="body1"
                  sx={{flexGrow: 1}}
                >
                  {`${t('Email an Benutzer: ')} ${principal.name}`}
                </Typography>
              ) : (
                <Box sx={{flexGrow: 1}} />
              )}
              <Box sx={{ml: 'auto'}}>
                <Tooltip title={t('mail.updatePreview.tooltip')}>
                  <Button
                    sx={{mr: 1}}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => updatePreview()}
                    startIcon={<UpdateIcon fontSize="inherit" />}
                  >
                    {t('button.update')}
                  </Button>
                </Tooltip>
                <Tooltip title={t('mail.send.tooltip')}>
                  <Button
                    disabled={isSendDisabled(submitting, type, subject, message, recipientEmails)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={type !== 'mailToAuthor' ? handleSend : () => handleSendToAuthor(includeResource)}
                    startIcon={<SendIcon fontSize="inherit" />}
                  >
                    {t('button.send')}
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 600,
                mx: 'auto'
              }}
            >
              {type !== 'mailToAuthor' && type !== 'messageOnly' && (
                <>
                  <Autocomplete
                    multiple
                    sx={{ '& .MuiChip-root': { borderRadius: 0 }}}
                    freeSolo
                    id="recipientEmails-standard"
                    options={emailListToUse ? emailListToUse.split(',') : []}
                    value={recipientEmails}
                    inputValue={recipientEmailList}
                    onChange={(event, newValue) => {
                      setRecipientEmails(newValue);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return (
                          <Chip
                            label={option}
                            {...getTagProps({index})}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )
                      })
                    }
                    onInputChange={(event, newInputValue) => {
                      const options = newInputValue.split(",");
                      setError({email: false, cc: false});
                      if (options.length > 1) {
                        const newOpts = recipientEmails
                          .concat(options)
                          .map(x => x.trim())
                          .filter(x => x);
                        if(checkAllOptionsAreEmails(options)){
                          setRecipientEmails(
                            newOpts
                          );
                          setRecipientEmailList('');
                        } else {
                          setError({email: true, cc: false})
                          setRecipientEmailList(newInputValue.replace(',', ''));
                        }

                      } else {
                        setRecipientEmailList(newInputValue);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={error.email}
                        helperText={error.email ? t('mail.invalid.email') : ''}
                        margin="dense"
                        label={t('mail.recipient.label')}
                        placeholder={t('mail.recipient.placeholder')}
                        fullWidth
                        variant="standard"
                        ref={recipientRef}
                      />
                    )}
                  />
                  <Autocomplete
                    sx={{ '& .MuiChip-root': { borderRadius: 0 }}}
                    multiple
                    freeSolo
                    id="tags-standard"
                    options={emailCCListToUse ? emailCCListToUse.split(',') : []}
                    value={recipientCCEmails}
                    inputValue={recipientCCEmailList}
                    onChange={(event, newValue) => {
                      setRecipientCCEmails(newValue);
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return (
                          <Chip
                            label={option}
                            {...getTagProps({index})}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )
                      })
                    }
                    onInputChange={(event, newInputValue) => {
                      const options = newInputValue.split(",");
                      setError({email: false, cc: false});
                      if (options.length > 1) {
                        const newOpts = recipientCCEmails
                          .concat(options)
                          .map(x => x.trim())
                          .filter(x => x);
                        if(checkAllOptionsAreEmails(options)){
                          setRecipientCCEmails(
                            newOpts
                          );
                          setRecipientCCEmailList('');
                        } else {
                          setError({email: false, cc: true})
                          setRecipientCCEmailList(newInputValue.replace(',', ''));
                        }

                      } else {
                        setRecipientCCEmailList(newInputValue);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={error.cc}
                        helperText={error.cc ? t('mail.invalid.email') : ''}
                        margin="dense"
                        id="recipientsCCEmail"
                        label={t('mail.recipientCC.label')}
                        placeholder={t('mail.recipientCC.placeholder')}
                        fullWidth
                        type="email"
                        variant="standard"
                        ref={recipientCCRef}
                      />
                    )}
                  />
                </>
              )}
              <TextField
                sx={{mb: 2, mt: 1}}
                autoFocus
                margin="dense"
                id="name"
                label="Betreff"
                fullWidth
                variant="standard"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <TinyEditor
                value={message || ''}
                onChange={(content) => setMessage(content)}
                options={{height: 400}}
              />
              <FormGroup sx={{mt: 2}}>
                {type !== 'mailToAuthor' && type !== 'messageOnly' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addPrincipalToBCC}
                        onChange={(e) => setAddPrincipalToBCC(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Kopie an den Author senden - (BCC)"
                  />
                )}
                {type === 'mailToAuthor' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeResource}
                        onChange={(e) => setIncludeResource(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Beitrag in der E-Mail anzeigen"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cc}
                      onChange={(e) => setCC(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Kopie an mich selbst senden - (CC)"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              maxWidth: '50%',
              alignItems: 'center',
              p: 2,
              backgroundColor: 'background.body',
              position: 'relative',
              overflow: 'auto'
            }}
          >
            {previewUpdating && <Loading shade/>}
            <ScopedCssBaseline>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 600,
                  mx: 'auto'
                }}
                dangerouslySetInnerHTML={{
                  __html:previewHtml || ''
                }}
              />
            </ScopedCssBaseline>
          </Box>
        </Box>

        {submitting && <Loading />}
      </Box>
    </Dialog>
  );
}

MailConfigurator.propTypes = {
  resource: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  principal: PropTypes.object,
  type: PropTypes.string,
};

export default MailConfigurator;
