/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const H3Only = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <path fill="inherit" opacity="undefined" d="m2.87501,2.31252l17.99998,0l0,4.37499l-17.99998,0l0,-4.37499z" id="svg_1" stroke="#fff"/>
        <text fill="inherit" x="3.09636" y="19.81249" id="svg_4" stroke-width="0" font-size="14" font-family="'Heebo'" text-anchor="start"  stroke="#fff" transform="matrix(0.893627 0 0 0.812258 1.14671 2.27492)" font-weight="bold">H3</text>
      </g>
    </SvgIcon>
  );
};

H3Only.propTypes = {};

export default H3Only;
