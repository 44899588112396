import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import {
  setCmsActiveId,
  setCmsData,
  setCmsMode,
  toggleCmsAdd,
  toggleCmsSidebar, toggleJoyride
} from "../../general/redux/actions";
import {createTopics, updateJournalProperties, updateResources} from "../../general/api";
import Tooltip from "@mui/material/Tooltip";
import {normalizeCMSItem} from "../utils/helpers";
import {fileDelete, fileUpload} from "../../general/formApi";
import Dialog from "@mui/material/Dialog";
import {LinearProgress} from "@mui/material";

function CMSStageToolbar({ update, loading }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectId = useSelector((state) => state.default.projectId);
  const journalId = useSelector((state) => state.default.journalId);
  const cmsInitialData = useSelector((state) => state.default.cmsInitialData);
  const cmsData = useSelector((state) => state.default.cmsData);
  const cmsMode = useSelector((state) => state.default.cmsMode);
  const cmsSidebar = useSelector((state) => state.default.cmsSidebar);
  const cmsAdd = useSelector((state) => state.default.cmsAdd);
  const joyride = useSelector((state) => state.default.joyride);
  const cmsDeletedArticles = useSelector((state) => state.default.cmsDeletedArticles);
  const cmsAddedAttachments = useSelector((state) => state.default.cmsAddedAttachments);
  const cmsUpdatedAttachments = useSelector((state) => state.default.cmsUpdatedAttachments);
  const cmsDeletedAttachments = useSelector((state) => state.default.cmsDeletedAttachments);

  const [progress, setProgress] = React.useState('');
  const [progressing, setProgressing] = React.useState(false);

  const handleChange = (event, newMode) => {
    if(newMode !== null){
      dispatch(setCmsMode(newMode));
      dispatch(setCmsActiveId(''));
      dispatch(toggleCmsAdd(false));
    }
  };

  const determineCreatedResourceId = (att, createdItems, newItems) => {
    const createdTopicRuntime = newItems.find((item) => item.resourceId === att.resourceId);
    const rank = createdTopicRuntime ? createdTopicRuntime.rank : 0;
    const createdTopicAfterSave = createdItems.find((item) => item.rank === rank);

    return createdTopicAfterSave ? createdTopicAfterSave.id : 0;
  };

  const saveCMS = () => {
    setProgressing(true);
    setProgress('start');
    const callBack = (resp) => {
      if(resp && resp.status === 200){
        // update();
      }
    }

    const preparedCmsData = cmsData.map((item) => {
      return {
        ...item,
        properties: {
          ...item.properties,
          cmsStyle: JSON.stringify(item.properties.cmsStyle)
        }
      }
    });

    const itemsWithAttachments = preparedCmsData.filter((item) => item.attachments && item.attachments.length);
    const attachmentImages = [];
    (itemsWithAttachments || []).forEach((item) => {
      attachmentImages.push(...item.attachments);
    })

    const newItems = preparedCmsData.filter((item) => !item.id);
    const existingItems = preparedCmsData.filter((item) => item.id);
    const deletedItems = preparedCmsData.filter((item) => cmsDeletedArticles.indexOf(item.resourceId) !== -1);
    const newTopics = newItems.map((item) => normalizeCMSItem(false, item, 'topic', journalId, projectId))
    const updateTopics = existingItems
      .map((item) => normalizeCMSItem(false, item, 'topic', journalId, projectId));
    const deletedTopicIds = deletedItems.map((item) => item.resourceId);
    const cmsDeletedAttachmentIds = cmsDeletedAttachments.map((att) => att.id);
    const deletedTopicAttachments = [...cmsDeletedAttachments];
    const cmsUpdatedAttachmentIds = cmsUpdatedAttachments.map((att) => att.id);
    const updatedTopicAttachments = [...cmsUpdatedAttachments];

    deletedItems
      .filter((item) => item.attachments && item.attachments.length)
      .forEach((item) => {
        item.attachments.forEach((att) => {
          if(cmsDeletedAttachmentIds.indexOf(att.id) === -1){
            deletedTopicAttachments.push(att);
          }
        })
      });

    setProgress('analyzing');
    updateJournalProperties(projectId, journalId, { 'journal.layout.noSidebar': cmsSidebar ? 'true' : 'false' }).then((respJournal) => {
      setProgress('create');
      createTopics(projectId, journalId, newTopics).then((respCreate) => {
        if(respCreate && respCreate.status === 201){
          setProgress('update');
          updateResources(
            updateTopics,
            'topic',
            projectId,
            journalId,
            callBack
          ).then((respUpdate) => {
            if(respUpdate && respUpdate.status === 200){
              setProgress('attachmentUpdate');
              const updateAttachmentPromises = [];
              updatedTopicAttachments
                .filter((att) => deletedTopicIds.indexOf(att.resourceId) === -1)
                .forEach((att) => {
                  const orgArticle = (cmsInitialData || []).find((it) => it.id === att.resourceId);
                  updateAttachmentPromises.push(fileUpload({ ...orgArticle.attachments[0] },null, att.name, att.description, att.alt ))
                })
              Promise.all(updateAttachmentPromises).then((resDeleteAttachments) => {
                setProgress('attachmentDelete');
                const deleteAttachmentPromises = [];
                deletedTopicAttachments
                  .filter((att) => deletedTopicIds.indexOf(att.resourceId) === -1)
                  .forEach((att) => {
                    deleteAttachmentPromises.push(fileDelete({ ...att }))
                  })
                Promise.all(deleteAttachmentPromises).then((resDeleteAttachments) => {
                  setProgress('attachmentAdd');
                  const addAttachmentPromises = [];
                  cmsAddedAttachments
                    .forEach((att) => {
                      if(isNaN(parseInt(att.resourceId))){
                        addAttachmentPromises.push(fileUpload(
                          {...att, id: null, resourceId: determineCreatedResourceId(att, respCreate.data, newItems)},
                          att.file,
                          att.name,
                          att.description,
                          att.alt
                        ))
                      } else {
                        addAttachmentPromises.push(fileUpload(
                          {...att, id: null},
                          att.file,
                          att.name,
                          att.description,
                          att.alt
                        ))
                      }
                    })
                  Promise.all(addAttachmentPromises).then((resAddAttachments) => {
                    setProgress('done');
                    setProgressing(false);
                  })
                })
              })

            } else {
              setProgress('attachmentUpdate');
            }
          })
        }
      })
    })
  };

  const resetCMS = () => {
    dispatch(setCmsData([...cmsInitialData]))
  };

  return (
    <>
      <AppBar
        position="relative"
        sx={{zIndex: 1}}
        elevation={1}
        color="background"
      >
        <Toolbar color="secondary" variant="dense">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ToggleButtonGroup
              className="joyride-modes"
              size="small"
              color="primary"
              value={cmsMode}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="preview" className="joyride-preview-mode">Vorschau</ToggleButton>
              <ToggleButton value="edit" className="joyride-edit-mode">Bearbeiten</ToggleButton>
              <ToggleButton value="structure" className="joyride-structure-mode">Struktur</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Typography
              className="joyride-mode-options"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              variant="body1"
              component="div"
            >
              {cmsMode === 'structure' && (
                <Tooltip title={t(`cms.add.tooltip`)}>
                  <IconButton
                    className="joyride-add-button"
                    color={cmsAdd ? 'success' : 'default'}
                    disabled={loading}
                    variant="contained"
                    onClick={() => dispatch(toggleCmsAdd(!cmsAdd))}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </Box>
          <Box
            className="joyride-main-buttons"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Tooltip title={t('cms.info.tooltip')}>
              <IconButton
                className="joyride-info-button"
                color={joyride ? 'success' : 'info'}
                onClick={() => dispatch(toggleJoyride(!joyride))}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('cms.sidebar.tooltip')}>
              <IconButton
                className="joyride-sidebar-button"
                color={cmsSidebar ? 'success' : 'default'}
                onClick={() => dispatch(toggleCmsSidebar(!cmsSidebar))}
              >
                <ViewSidebarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t(`cms.reset.tooltip`)}>
              <IconButton
                className="cms-reset-button"
                disabled={loading}
                variant="contained"
                onClick={() => resetCMS()}
              >
                <RestartAltOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Button
              className="cms-save-button"
              color="success"
              disabled={loading}
              variant="outlined"
              onClick={saveCMS}
              startIcon={<SaveOutlinedIcon />}
            >
              {t('button.save')}
            </Button>
          </Box>
        </Toolbar>
        {/*<Collapse in={cmsMode === 'structure' && cmsActiveId} timeout="auto" unmountOnExit>*/}
        {/*  <Divider />*/}
        {/*  <CMSEdit activeId={cmsActiveId}  />*/}
        {/*</Collapse>*/}
      </AppBar>
      <Dialog maxWidth="sm" fullWidth onClose={() => {}} open={progressing}>
        <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2}}>
          <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, py: 2}}>
            <LinearProgress />
          </Box>
          <Typography component="div" variant="h3" textAlign="center">{progress}</Typography>
        </Box>
      </Dialog>
  </>
  );
}

CMSStageToolbar.propTypes = {
  update: PropTypes.func,
};

export default CMSStageToolbar;
