import {MAXIMAGEHEIGHT, MAXIMAGEHEIGHTSIDEBAR, MAXIMAGEWIDTH, MAXIMAGEWIDTHSIDEBAR} from "./constants";

export const isFullSection = (resource) => {
  return resource && resource.properties && resource.properties.cmsStyle && resource.properties.cmsStyle.fullSection;
}


export const isExpandable = (resource) => {
  return resource && resource.properties && resource.properties.cmsStyle && resource.properties.cmsStyle.expandable;
}

export const isBox = (resource) => {
  return resource && resource.properties && resource.properties.cmsStyle && resource.properties.cmsStyle.box;
}

export const getBoxStyle = (resource, name, defaultVal) => {
  return resource
    && resource.properties
    && resource.properties.cmsStyle
      ? parseInt(resource.properties.cmsStyle[name] || defaultVal)
      : defaultVal;
}

export const getBoxColor = (resource) => {
  const basicBoxColor = 'background.green'
  return resource && resource.properties && resource.properties.cmsStyle ? resource.properties.cmsStyle.boxColor || basicBoxColor : basicBoxColor;
}

export const getBorderColor = (resource) => {
  const basicBorderColor = 'background.main'
  return resource && resource.properties && resource.properties.cmsStyle ? resource.properties.cmsStyle.borderColor || basicBorderColor : basicBorderColor;
}

export const getTextColor = (resource) => {
  const basicTextColor = isExpandable(resource) ? 'primary.main' : 'textPrimary'
  return resource && resource.properties && resource.properties.cmsStyle ? resource.properties.cmsStyle.textColor || basicTextColor : basicTextColor;
}

export const isMultiCol = (resource, greater) => {
  if(!resource){
    return false;
  }
  switch (resource.label) {
    case 'multicol2':
    case 'multicol3':
      return greater ? false : true;
    case 'multicol4':
    case 'multicol6':
      return true;
    default:
      return false;
  }
}

/*IMAGE RELATED*/
const correctToNext8 = (val) => {
  const rest = val % 8;
  return val + (8 - rest);

};

const handleImageSizeByLabel = (label, value, isFullSection) => {
  let retVal = isFullSection ? value : Math.round(parseInt(value)/ 1.213);
  if(label.indexOf('eft') !== -1 || label.indexOf('ight') !== -1){
    return correctToNext8(parseInt(retVal / 2))
  }
  return correctToNext8(retVal);
};

const getImageMeasures = (label, sub, hasSidebar, isFullSection, rounded) => {
  const baseWidth = hasSidebar ? MAXIMAGEWIDTHSIDEBAR : MAXIMAGEWIDTH;
  const baseHeight = hasSidebar ? MAXIMAGEHEIGHTSIDEBAR : MAXIMAGEHEIGHT;
  let width = handleImageSizeByLabel(label, baseWidth, isFullSection);
  let height = handleImageSizeByLabel(label, baseHeight, isFullSection);
  if(sub !== -1){
    const subWidth = correctToNext8(parseInt(width / sub));
    const subHeight = correctToNext8(parseInt(height / sub));
    const reduction = rounded ? parseInt(subWidth/2) : 0;
    return { width: correctToNext8(parseInt(subWidth - reduction)), height: subHeight };
  }
  return { width, height};
};

const getSubVal = (resource) => {
  if(resource.label === 'slider'){
    return 1;
  }

  if(resource.label === 'sequenceContainer'){
    return 4;
  }
  return parseInt(resource.label.replace('multicol', ''));
}
export const getImage = (resource) => {
  return resource && resource.attachments && resource && resource.attachments.length ? resource && resource.attachments[0] : null;
}

export const isImageRounded = (resource) => {
  if(resource && resource.label === 'sequenceItem'){
    return true;
  }
  return resource && resource.properties && resource.properties.cmsStyle && resource.properties.cmsStyle.imageRounded;
}

export const isImageSquared = (resource) => {
  return resource && resource.properties && resource.properties.cmsStyle && resource.properties.cmsStyle.imageSquare;
}

export const isImageCentered = (resource) => {
  return resource && resource.properties && resource.properties.cmsStyle && (resource.properties.cmsStyle.imageCentered || resource.properties.cmsStyle.imageRounded);
}

export const getImageDimensions = (resource, parent, hasSidebar, rounded) => {
  const sub = parent ? getSubVal(parent): -1;

  return getImageMeasures(resource.label, sub, hasSidebar, isFullSection(resource), rounded)
}
