/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ImageBelowText = (props) => {
  return (
    <SvgIcon {...props}>
      <g>
        <title>Layer 1</title>
        <rect stroke-width="0" id="svg_1" height="4.125" width="24" y="0" x="0" stroke="inherit" fill="inherit"/>
        <line id="svg_2" y2="8" x2="24" y1="8" x1="0" stroke="inherit" fill="none"/>
        <line id="svg_3" y2="12" x2="24" y1="12" x1="0" stroke="inherit" fill="none"/>
        <line id="svg_6" y2="16" x2="24" y1="16" x1="0" stroke="inherit" fill="none"/>
        <rect id="svg_11" height="4" width="12" y="19.5" x="0.5" stroke="inherit" fill="none"/>
      </g>
    </SvgIcon>
  );
};

ImageBelowText.propTypes = {};

export default ImageBelowText;
