import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel'
import CMSContainerArticle from "./CMSContainerArticle";
import {useSelector} from "react-redux";
import CMSImage from "./CMSImage";

function CMSSequenceContainer(
  {
    resource,
    mode,
    disabled,
    activeId,
    items,
    highlightParent,
    deactivateArticle
  }
){
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .cms-sequence': {
          overflow:  Boolean((items || []).find((it) => it.resourceId === activeId)) ? 'visible' : 'hidden'
        }
      }}
    >
      {(items || [])
        .map((item, i) => {
          return (
            <CMSContainerArticle
              key={item.resourceId}
              parent={resource}
              mode={mode}
              resource={item}
              activeId={activeId}
              highlightParent={highlightParent}
              index={i}
              items={items}
            />
          );
        })
      }
    </Box>
  );
}

CMSSequenceContainer.propTypes = {
  resource: PropTypes.object,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CMSSequenceContainer;
