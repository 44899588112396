import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CMSEditButtons from "./CMSEditButtons";
import CMSEditClickAway from "./CMSEditClickAway";
import CMSEdit from "./CMSEdit";
import {useSelector} from "react-redux";

function CMSArticleEdit(
  {
    mode,
    activeId,
    activateArticle,
    deactivateArticle,
    highlightArticle,
    deactivateCopy,
    sub
  }
){
  const { t } = useTranslation();
  const cmsCopy = useSelector((state) => state.default.cmsCopy);

  return (
    <>
      {/*<Box*/}
      {/*  component={Paper}*/}
      {/*  elevation={3}*/}
      {/*  sx={{*/}
      {/*    position: 'absolute',*/}
      {/*    bottom: 'calc(100% + 4px)',*/}
      {/*    left:0,*/}
      {/*    zIndex: 1,*/}
      {/*    flexGrow: 1,*/}
      {/*    width: '100%',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {Boolean(!cmsCopy || !cmsCopy.length) && (*/}
      {/*    <CMSEdit activeId={activeId} />*/}
      {/*  )}*/}
      {/*</Box>*/}
      <CMSEditClickAway
        activateArticle={activateArticle}
        deactivateArticle={deactivateArticle}
        highlightArticle={highlightArticle}
        deactivateCopy={deactivateCopy}
        mode={mode}
        cmsCopy={cmsCopy}
      />
      {/*<Box*/}
      {/*  component={Paper}*/}
      {/*  elevation={0}*/}
      {/*  sx={{*/}
      {/*    position: 'absolute',*/}
      {/*    top:'calc(100% + 4px)',*/}
      {/*    left:0,*/}
      {/*    zIndex: 15,*/}
      {/*    flexGrow: 1,*/}
      {/*    width: '100%',*/}
      {/*    backgroundColor: 'transparent',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>*/}
      {/*    {Boolean(!cmsCopy || !cmsCopy.length) && (*/}
      {/*      <>*/}
      {/*        <Divider />*/}
      {/*        <CMSEditButtons activeId={activeId} sub={sub}/>*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </>
  );
}

CMSArticleEdit.propTypes = {
  shade: PropTypes.bool,
};

export default CMSArticleEdit;
