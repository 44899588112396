import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CMSArticle from "./CMSArticle";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {setCmsData} from "../../general/redux/actions";
import CMSContainer from "./CMSContainer";
import {CMSGROUPS} from "../utils/constants";
import {getContainerChildLength} from "../utils/helpers";
import CMSContainerArticle from "./CMSContainerArticle";

const getButtonPosition = (item, data) => {
  const itemIndex = data.findIndex((it) => it.resourceId === item.resourceId);
  let left = 0;
  let controlLeft = 0;
  if(data[itemIndex - 1] && (!data[itemIndex - 1].published || data[itemIndex - 1].type === 'cms_article_child')){
    for(let i = 0; i < data.length; i++) {
      if(data[itemIndex - controlLeft - 1] && !data[itemIndex - controlLeft - 1].published && data[itemIndex - controlLeft - 1].type === 'cms_article'){
        left++;
        controlLeft++;
      } else if(data[itemIndex - controlLeft - 1] && data[itemIndex - controlLeft - 1].type === 'cms_article_child') {
        controlLeft++;
      } else {
        break;
      }
    }
  }

  let right = 0;
  let controlRight = 0;
  if(data[itemIndex + 1] && (!data[itemIndex + 1].published || data[itemIndex + 1].type === 'cms_article_child')){
    for(let i = 0; i < data.length; i++) {
      if(data[itemIndex + controlRight + 1] && !data[itemIndex + controlRight + 1].published  && data[itemIndex + controlRight + 1].type === 'cms_article'){
        right++;
        controlRight++;
      } else if(data[itemIndex + controlRight + 1] && data[itemIndex + controlRight + 1].type === 'cms_article_child') {
        controlRight++;
      } else {
        break;
      }
    }
  }

  if(left || right){
    return {
      left: `calc(100% - ${right * 16}px)`,
      zIndex: right * 2,
      top: left === 0 ? 'unset' : (left % 2 !== 0 ? -8 : 'unset'),
      bottom: left === 0 ? 'unset' : (left % 2 === 0 ? -8 : 'unset')
    };
  }
  return {
    left: '100%',
    zIndex: 1
  };
};

function CMSArticleHidden(
  {
    resource,
    mode,
    activeId,
    isStructureArticle,
    joyrideMark,
    parent,
    highlightParent,
    items
  }
){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cmsData = useSelector((state) => state.default.cmsData);
  const [item, setItem] = React.useState({...resource});
  const [highlight, setHighlight] = React.useState(false);
  const [articleShown, setArticleShown] = React.useState(false);

  React.useEffect(() => {
    setItem({...resource});
    return () => {};
  },[resource]);

  const getClassName = (joyrideMark) => {
    if(joyrideMark){
      return 'joyride-article-hidden-root cms-article-item';
    }
    return 'cms-article-item'
  }

  const handleClose = (e) => {
    if(!e.target.closest('.cms-hidden-buttons')) {
      setArticleShown(false);
    }
  };

  const publishArticle = () => {
    setArticleShown(false);
    const targetItem = cmsData.find((it) => it.resourceId === item.resourceId);
    if(CMSGROUPS.buttonGroupStructure.indexOf(targetItem.label) !== -1){
      let targetIndex = cmsData.findIndex((it) => it.resourceId === item.resourceId);
      const childLength = getContainerChildLength(cmsData, targetIndex);
      const unpublishedIds = [];
      for(let i = 0; i <= childLength; i++){
        const rId = cmsData[targetIndex].resourceId;
        unpublishedIds.push(rId);
        targetIndex++
      }

      dispatch(setCmsData(cmsData.map((it) => {
        if(unpublishedIds.indexOf(it.resourceId) !== -1){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }else {
      dispatch(setCmsData(cmsData.map((it) => {
        if(it.resourceId === item.resourceId){
          return { ...it, published: !it.published };
        }
        return it;
      })));
    }
  };

  const buttonPosition = getButtonPosition(resource, cmsData);

  return (
    <ClickAwayListener onClickAway={articleShown ? handleClose : () => {}}>
      <Box
        className={getClassName(joyrideMark)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          mb: 2,
          height: articleShown ? 'auto' : 2,
          backgroundColor: articleShown ? 'background.main' : 'divider',
          borderTop: articleShown ? '2px solid' : 'none',
          borderBottom: articleShown ? '2px solid' : 'none',
          borderColor: articleShown ? 'success.main' : 'transparent',
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Fab
            sx={{
              position: 'absolute',
              zIndex: highlight ? 200 : buttonPosition.zIndex,
              left: buttonPosition.left,
              top: buttonPosition.top,
              bottom: buttonPosition.bottom,
            }}
            size="small"
            aria-label="Anzeigen"
            color={ articleShown ? "success" : (highlight ? "info" : "divider")}
            onClick={() => setArticleShown(!articleShown)}
            onMouseEnter={() => setHighlight(true)}
            onMouseLeave={() => setHighlight(false)}
          >
            {articleShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Fab>
        </Box>
        <Collapse
          in={articleShown}
          timeout="auto"
          unmountOnExit
          sx={{
            width: '100%',
            p: 0
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
          >
            {isStructureArticle && (
              <CMSContainer
                mode="display"
                resource={item}
                activeId={activeId}
              />
            )}
            {Boolean(parent) && (
              <CMSContainerArticle
                parent={resource}
                mode={mode}
                resource={item}
                activeId={activeId}
                highlightParent={highlightParent}
                items={items}
              />
            )}
            {Boolean(!parent && !isStructureArticle) && (
              <CMSArticle
                mode="display"
                resource={item}
                activeId={activeId}
              />
            )}
            <Box
              component={Paper}
              elevation={3}
              sx={{
                position: 'absolute',
                top:'calc(100% + 4px)',
                left:0,
                zIndex: 1,
                flexGrow: 1,
                width: '100%',
              }}
            >
              <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <Divider />
                <Box
                  className="cms-hidden-buttons"
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Button color="primary" sx={{ml: 'auto'}} onClick={publishArticle}>
                    {t('button.recover')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
}

CMSArticleHidden.propTypes = {
  resource: PropTypes.object,
};

export default CMSArticleHidden;
