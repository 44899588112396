import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Collapse from "@mui/material/Collapse";
import CMSArticleAdd from "./CMSArticleAdd";
import {useSelector} from "react-redux";

function CMSAddBar({ active, position, activePosition, addArticle, handleClose }){
  const { t } = useTranslation();
  const theme = useTheme();
  const joyrideCDemoAdd = useSelector((state) => state.default.joyrideDemoAdd);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Zoom
      in={active && ( activePosition === -1 || activePosition === position || joyrideCDemoAdd)}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${active ? transitionDuration.exit : transitionDuration.enter}ms`,
      }}
      unmountOnExit
    >
      <Box
        className={position === 0 ? 'joyride-article-add-bar' : ''}
        component="div"
        sx={{
          my: 1,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          height: !joyrideCDemoAdd && active && position !== activePosition ? 4 : 'auto',
          // overflow: active && position !== activePosition ? 'visible' : 'hidden',
          backgroundColor: !joyrideCDemoAdd && active && position !== activePosition ? 'secondary.main' : 'background.main',
        }}
      >
        {!joyrideCDemoAdd && active && activePosition !== position && (
          <Box
            component="div"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Fab
              sx={{
                position: 'absolute',
                zIndex: 2,
              }}
              size="small"
              aria-label="Hinzufügen"
              color="secondary"
              onClick={addArticle}
            >
              <AddIcon />
            </Fab>
          </Box>
        )}
        <Collapse
          in={(joyrideCDemoAdd && position === 0) || (active && position === activePosition)}
          timeout="auto"
          unmountOnExit
          sx={{
            width: '100%',
            py: 1,
            px: 0
          }}
        >
          <CMSArticleAdd position={activePosition} handleClose={handleClose}/>
        </Collapse>
      </Box>
    </Zoom>
  );
}

CMSAddBar.propTypes = {
  position: PropTypes.number,
};

export default CMSAddBar;
