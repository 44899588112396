/* globals API_BASE_URL */
import axios from 'axios';

export const RequestType = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};

export const createRequestOptions =
  (method) => (url, data, noAuth, additionalHeaders, ditoCall) => {
    return {
      url,
      baseURL: ditoCall ? '/' : API_BASE_URL,
      method,
      headers: getAuthHeader(noAuth, additionalHeaders),
      data,
    };
  };

export const submitRequest = (requestOptions, callback) => {
  return axios(requestOptions)
    .then((response) => {
      if (callback) {
        setTimeout(() => callback(response)) ;
      }
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }

      if (error.request) {
        return error.request;
      }

      return error.message;
    });
};

export const paramsMatcher = (params) => {
  if (!params || typeof params !== 'object') {
    return '/?searchAll=true';
  }

  const keys = Object.keys(params);
  const reqStrg = (keys || [])
    .map((key) => {
      return `${key}=${params[key]}`;
    })
    .join('&');

  return reqStrg && reqStrg.length ? `?${reqStrg}` : '/?searchAll=true';
};

export const getAuthHeader = (noAuth, existingHeaders) => {
  let token = localStorage.getItem('currentUser');
  token = token && token !== 'null' ? JSON.parse(token).token : '';
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };

  if (!noAuth) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (existingHeaders) {
    headers = { ...headers, ...existingHeaders };
  }

  return headers;
};

/*USER*/
export const getToken = () => {
  const url = '/dito/explore?action=get-token';
  return submitRequest(createRequestOptions(RequestType.get)(url, null, true, null, true))
};

export const getUserById = (userId,  pId) => {
  const url = `/user/moderation/${pId}/${userId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const searchUsers = (sendData, pId, cId) => {
  const url = `/user/search/${pId}/${cId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const createUser = (pId, sendData) => {
  const url = `/user/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const patchUser = (pId, sendData, callBack) => {
  const url = `/user/${pId}`;

  return submitRequest(createRequestOptions(RequestType.patch)(url, sendData), callBack);
};

export const updateGroupMembership = (pId, sendData) => {
  const url = `/user/group/${pId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, sendData));
};

export const getUserResources = (uId) => {
  const url = `/resource/get-resource-from/${uId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const getUserWarnState = (pId, uId) => {
  const url = `/user/properties/${pId}/${uId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const warnUser = (pId, uId) => {
  const url = `/user/properties/warn-user/${pId}/${uId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, {}));
};

export const blockUser = (pId, uId) => {
  const url = `/user/properties/blocked-user/${pId}/${uId}?`;

  return submitRequest(createRequestOptions(RequestType.post)(url, {}));
};

export const anonymizeUser = (pId, uId) => {
  const url = `/user/delete/${pId}/${uId}?`;

  return submitRequest(createRequestOptions(RequestType.delete)(url, null));
};

export const resetPassword = (pId, principalId) => {
  const url = `/user/reset-password/${pId}/${principalId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, null));
};

export const resendActivationMail = (pId, uId) => {
  const url = `/registration/resend-activation-mail/${uId}?project-uid=${pId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};


/*GROUP*/
export const getGroups = (pId) => {
  const url = `/group/${pId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const getModerators = (pId) => {
  const url = `/group/moderators?project-uid=${pId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

/*PROJECT*/
export const getKeywords = (pId) => {
  const url = `/resource/keyword/${pId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

/*JOURNAL*/
export const getJournalGenericProperties = (jId) => {
  const url = `/resource/journal/generic-properties`;

  return submitRequest(createRequestOptions(RequestType.post)(url, [jId]));
};

export const getJournalProperties = (pId, jId) => {
  const url = `/resource/journal/properties/${pId}?journal-uid=${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url, [jId]));
};

export const updateJournalProperties = (pId, jId, sendData) => {
  const url = `/resource/journal/properties/${pId}/${jId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getJournalLinkLabels = (jId) => {
  const url = `/resource/journal/link-labels?journal-uid=${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const getJournalQualifiers = (jId) => {
  const url = `/resource/journal/qualifiers?journal-uid=${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const getJournalLabels = (jId) => {
  const url = `/resource/journal/labels?journal-uid=${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};


/*RESEOURCE*/
export const searchResources = (sendData) => {
  const url = '/resource/search';

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getResourceById = (rType, tId) => {
  const url = `/resource/${rType}/moderation/${tId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const updateResources = (body, resourceType, pId, jId, callBack = () => {}) => {
  const url = `/resource/${resourceType}/${pId}/${jId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, body), callBack);
};

export const createTopics = (pId, jId, sendData) => {
  const url = `/resource/topic/${pId}/${jId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const createComment = (pId, sendData) => {
  const url = `/resource/article/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const patchComment = (pId, sendData) => {
  const url = `/resource/article/${pId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, sendData));
};

export const getInternalComments = (tId) => {
  const url = `/resource/annotation/internal_comment/${tId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const deleteInternalComments = (sendData) => {
  const url = '/resource/annotation';

  return submitRequest(createRequestOptions(RequestType.delete)(url, sendData));
};

export const createInternalComment = (sendData) => {
  const url = '/resource/annotation';

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getResourceKeywords = (pId, rId) => {
  const url = `/resource/keyword/${pId}/${rId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const updateResourceKeywords = (pId, rId, sendData) => {
  const url = `/resource/keyword/${pId}/${rId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, sendData));
};

export const updateKeywordGroup = (pId, kwGId, sendData) => {
  const url = `/resource/keyword_group/${pId}/${kwGId}`;

  return submitRequest(createRequestOptions(RequestType.put)(url, sendData));
};


/*MAIL*/
export const sendMail = (sendData, pId) => {
  const url = `/email/sendModerationMail/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const sendMailToAuthor = (sendData, pId) => {
  const url = `/email/send-mail-to-author/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};


export const sendTypedMail = (sendData, pId) => {
  const url = `/email/sendMail/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getMailPreview = (sendData, pId) => {
  const url = `/email/previewMail/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

/*EVENT*/
export const getResourceEvents = (rid) => {
  const url = `/event/${rid}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

/*VOTING*/
export const getResourceVoting = (sendData, full) => {
  const url = `/voting/voting-info/find-by-resources${!full ? '?withAllConfigurations=false' : ''}`; // /${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getResourceVotingInfo = (sendData, pId) => {
  const url = `/voting/voting-info/find-by-resources`; // /${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

export const getJournalVotingInfo = (jId) => {
  const url = `/voting/voting-info/${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

/*ASSIGNMENT*/

export const getAssignmentByResource = (pId, rId) => {
  const url = `/resource/assignment/resource/${pId}/${rId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const getAssignmentByPrincipal = (pId, uId) => {
  const url = `/resource/assignment/principal/${pId}/${uId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};

export const updateAssignment = (sendData, pId) => {
  const url = `/resource/assignment/${pId}`;

  return submitRequest(createRequestOptions(RequestType.post)(url, sendData));
};

/*CMS*/
export const getCmsArticles = (jId) => {
  const url = `/cms/${jId}`;

  return submitRequest(createRequestOptions(RequestType.get)(url));
};