/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MultiCol6 = (props) => {
  return (
    <SvgIcon {...props}>
      <g >
        <title>Layer 1</title>
        <rect stroke="inherit" id="svg_1" height="12.625" width="23.125" y="5.5" x="0.375" fill="#fff"/>
        <rect stroke="inherit" id="svg_2" height="9.125" width="2" y="7.25" x="2" fill="#fff"/>
        <rect stroke="inherit" id="svg_3" height="9.125" width="2" y="7.25" x="5.5" fill="#fff"/>
        <rect stroke="inherit" id="svg_4" height="9.125" width="2" y="7.25" x="9.125" fill="#fff"/>
        <rect stroke="inherit" id="svg_5" height="9.125" width="2" y="7.25" x="12.75" fill="#fff"/>
        <rect stroke="inherit" id="svg_6" height="9.125" width="2" y="7.25" x="16.25" fill="#fff"/>
        <rect stroke="inherit" id="svg_7" height="9.125" width="2" y="7.25" x="19.875" fill="#fff"/>
      </g>

    </SvgIcon>
  );
};

MultiCol6.propTypes = {};

export default MultiCol6;
