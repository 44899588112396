import {fakeStringId} from "../../general/helpers";
import i18n from "../../general/i18n";
import {DEFAULTBOXSTYLE, DEPRECTATED_TYPES} from "./constants";

export const handleFlatData = (items) => {
  return (items || []).map((item) => {
    if (!item.properties.cmsStyle) {
      return {
        ...item,
        properties: {
          ...item.properties,
          cmsStyle: {}
        },
        ...item.properties,
        cmsStyle: {}
      };
    }
    return {
      ...item,
      properties: {
        ...item.properties,
        cmsStyle: JSON.parse(item.properties.cmsStyle)
      },
      ...item.properties,
      cmsStyle: JSON.parse(item.properties.cmsStyle)
    };
  })
};

const getColMod = (size) => {
  switch (size) {
    case 2:
      return 6;
    case 3:
      return 4;
    case 4:
      return 3;
    case 6:
      return 2;
    default:
      return ''
  }
}
const sliceBySize = (items, size) => {
  const useItems = [...items];
  const returnItems = [];
  const modCol = getColMod(size);
  const colLength = Math.round(useItems.length / modCol);
  for(let i = 0; i < colLength;i++){
    let targetRank = colLength ? useItems[0].rank : 0;
    let part = useItems.length && useItems.length >= modCol ? useItems.slice(0,modCol) : useItems.slice(0,useItems.length);
    const partRanks = part.map((it) => it.rank).filter((r) => parseInt(r/100) === parseInt(targetRank/100));

    returnItems.push(part);
    if(useItems.length) {
      useItems.splice(0,partRanks.length);
      if(partRanks.length < modCol){
        targetRank = useItems[0].rank;
      }
    }
  }
  return returnItems;
}

const getColType = (size) => {
  switch (size) {
    case 2:
      return 'multicol6';
    case 3:
      return 'multicol4';
    case 4:
      return 'multicol3';
    case 6:
      return 'multicol2';
    default:
      return ''
  }
}
const createParentArticle = (size, rank) => {
  return {
    label: getColType(size),
    type: 'cms_article',
    id: null,
    resourceId: fakeStringId(),
    title: i18n.t('cms.field.title.dummy'),
    note: i18n.t('cms.field.note.dummy'),
    qualifier: '',
    attachments: [],
    rank,
    properties: {
      cmsCol: '',
    },
    parentId: null,
    projectId: null,
    published: true,
    markedForDeletion: false,
    authorId: null,
  }
}
const insertRepairContainer = (items, colBlock, size) => {
  const newItems = [...items];
  const blockIds = colBlock.map((block) => block.resourceId);
  const firstIndex = newItems.findIndex((it) => it.resourceId === colBlock[0].resourceId);
  const prevItem = newItems[firstIndex - 1];

  let newRank = 100;
  if(prevItem){
    newRank = (Math.ceil(prevItem.rank / 100) * 100) + 100;
  }

  newItems.splice(firstIndex, colBlock.length);
  colBlock.reverse().forEach((block) => {
    newItems.splice(firstIndex,0, block);
  })
  const newParent = createParentArticle(size, newRank);
  newItems.splice(firstIndex,0, newParent);
  let outerCount = 0;
  let afterItemsCount = 1;
  const lastIndex = newItems.findIndex((it) => it.resourceId === colBlock[colBlock.length -1 ].resourceId);
  const revisedItems = newItems.map((it, index) => {
    if(blockIds.indexOf(it.resourceId) !== -1){
      outerCount++;
      return {
        ...it,
        rank: newRank + blockIds.indexOf(it.resourceId) + outerCount,
        type: 'cms_article_child'
      }
    }
    if(index > lastIndex){
      if(it.type !== 'cms_article_child'){
        afterItemsCount++;
      }
      return {...it, rank: it.rank + (afterItemsCount * 100) };
    }
    return it;
  });

  return revisedItems
}

const checkColIntegrity = (cols, size) => {
  switch(size){
    case 2:
      return cols.length % 6;
    case 3:
      return cols.length % 4;
    case 4:
      return cols.length % 3;
    case 6:
      return cols.length % 2;
    default:
      return -1;
  }
}
const handleRepairSlider = (items, slider) => {
  const newItems = [...items];

  const sliderIndex = newItems.findIndex((it) => it.resourceId === slider.resourceId);
  const prevItem = newItems[sliderIndex - 1];
  let newRank = 100;
  if(prevItem){
    newRank = (Math.ceil(prevItem.rank / 100) * 100) + 100;
  }

  const searchSliderRank = slider.rank;
  const sliderItems = newItems
    .filter((item) => searchSliderRank && item && item.rank > searchSliderRank && item.rank < searchSliderRank + 1000)
    .map((it) => it.resourceId);

  let outerCount = 0;
  const revisedItems = newItems.map((item) => {
    if(item.resourceId === slider.resourceId){
      return {
        ...item,
        rank: newRank,
        type: 'cms_article'
      }
    }
    if(searchSliderRank && item && item.rank > searchSliderRank && item.rank < searchSliderRank + 1000){
      outerCount++
      return {
        ...item,
        rank: newRank + sliderItems.indexOf(item.resourceId) + outerCount,
        type: 'cms_article_child'
      }
    }
    return item
  });

  return revisedItems;
};

const handleRepairSequence = (items, sequence) => {
  const newItems = [...items];

  const sequenceIndex = newItems.findIndex((it) => it.resourceId === sequence.resourceId);
  const prevItem = newItems[sequenceIndex - 1];
  let newRank = 100;
  if(prevItem){
    newRank = (Math.ceil(prevItem.rank / 100) * 100) + 100;
  }

  const searchSequenceRank = sequence.rank;
  const sequenceItems = newItems
    .filter((item) => searchSequenceRank && item && item.rank > searchSequenceRank && item.rank < searchSequenceRank + 1000)
    .map((it) => it.resourceId);

  let outerCount = 0;
  const revisedItems = newItems.map((item) => {
    if(item.resourceId === sequence.resourceId){
      return {
        ...item,
        rank: newRank,
        type: 'cms_article'
      }
    }
    if(searchSequenceRank && item && item.rank > searchSequenceRank && item.rank < searchSequenceRank + 1000){
      outerCount++
      return {
        ...item,
        rank: newRank + sequenceItems.indexOf(item.resourceId) + outerCount,
        type: 'cms_article_child'
      }
    }
    return item
  });

  return revisedItems;
};

const ensureColIntegrity = (cols, integritySum, size) => {
  const addCols = [];
  for(let i = 0; i < (getColMod(size) - integritySum); i++) {
    cols.push({
      ...cols[cols.length-1],
      type: 'cms_article_child',
      id: null,
      resourceId: fakeStringId(),
      title: '',
      note: '',
      attachments: [],
      rank: cols[cols.length-1].rank + 1,
      properties: {
        cmsCol: `${size}`,
      }
    })
  }
  return [...cols, ...addCols];
};

const extractColItems = (items, size) => {
  let newItems = [...items];

  let cols = [];
  (newItems || []).forEach((item, index) => {
    if(item && item.properties.cmsCol && item.properties.cmsCol === `${size}`){
      cols.push(item);
    }
  })

  cols = sliceBySize(cols, size);

  cols = cols.map((colAr) => {
    const integritySum = checkColIntegrity(colAr, size);
    if(integritySum > 0){
      return ensureColIntegrity(colAr, integritySum, size)
    }

    return colAr;
  })

  cols.forEach((col) => {
    if(col.length){
      newItems = insertRepairContainer(newItems, col, size)
    }
  });

  return newItems;
}
const handleRepairColContainer = (items) => {
  let newItems = [...items];

  const posCols = [2,3,4,6];

  posCols.forEach((posCol) => {
    newItems = extractColItems(newItems, posCol);
  })

  const slider = newItems.find((it) => it.label === 'slider');

  if(slider){
    newItems = handleRepairSlider(newItems, slider)
  }

  const sequence = newItems.find((it) => it.label === 'slider');

  if(sequence){
    newItems = handleRepairSequence(newItems, sequence)
  }

  return newItems
    .sort((a, b) => (a.rank > b.rank) ? 1 : -1)
    .map((it) => {
      if(it && (!it.properties || !it.properties.cmsCol || it.label === 'slider')){
        return {
          ...it,
          type: 'cms_article',
          properties: {
            cmsStyle: { ...it.properties.cmsStyle }
          }
        };
      }

      return {
        ...it,
        properties: {
          cmsStyle: { ...it.properties.cmsStyle }
        }
      };
    });

};

const checkIsOldRankSystem = (items) => {
  const itemRanks = items.map((item) => item.rank);
  const rankLessThan100 = itemRanks.find((rank) => rank < 100);
  const rankNotBySystem = itemRanks.find((rank) => rank % 100 !== 0 && rank % 10 === 0);
  if(Boolean(rankLessThan100 || rankNotBySystem )){
    return true;
  }

  return false;
};

const prepareRanks = (items) => {
  let cmsArticleRanks = 1;
  let sliderLoop = null;
  let childRun = 0;
  let childColMod;

  const newItems = items.map((it, index) => {
    if(sliderLoop && it.rank > sliderLoop + 1000){
      sliderLoop = null;
      cmsArticleRanks++;
    }

    let rank = cmsArticleRanks * 100;
    if(!it.properties.cmsCol && !sliderLoop){
        cmsArticleRanks++;
        rank = cmsArticleRanks * 100;
        childRun = 0;
        if(it.label === 'slider'){
          sliderLoop = it.rank;
        }
        return {...it, rank, type: 'cms_article' };
    }

    if(childRun === 0){
      childColMod = getColMod(parseInt(it.properties.cmsCol));
    }
    const currentColMod = getColMod(parseInt(it.properties.cmsCol));
    if(currentColMod !== childColMod || (childRun === currentColMod)){
      childRun = 0;
      cmsArticleRanks++;
      rank = cmsArticleRanks * 100;
    }
    const childRank = rank + childRun + childRun + 1
    childRun++;

     return {...it, rank:childRank, type: 'cms_article_child' };
  })

  return newItems.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
}

const correctLayout = (items) => {
  return items.filter((it) => it).map((it) => {
    if(DEPRECTATED_TYPES.indexOf(it.label) !== -1){
      if(it.label.indexOf('_exp') !== -1){
        return {
          ...it,
          label: it.label.replace('_exp', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              expandable: true,
            }
          }
        }
      }
      if(it.label.indexOf('Full') !== -1){
        return {
          ...it,
          label: it.label.replace('FullSection', '').replace('FullContainer', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              fullSection: true,
              ...DEFAULTBOXSTYLE,
              box: it.label.indexOf('FullContainer') !== -1,
              boxColor: 'background.lightblue',
            }
          }
        }
      }
      if(it.label.indexOf('Toggle') !== -1){
        return {
          ...it,
          label: it.label.replace('box', '').replace('Toggle', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              ...DEFAULTBOXSTYLE,
              boxPaddingTop: 0,
              boxPaddingLeft: 2,
              boxPaddingRight: 2,
              boxPaddingBottom: 2,
              expandable: true,
              box: true,
              boxColor: 'background.lightblue',
              imageSquare: true,
            }
          }
        }
      }
      if(it.label.indexOf('box') !== -1){
        return {
          ...it,
          label: it.label.replace('box', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              ...DEFAULTBOXSTYLE,
              boxPaddingTop: 2,
              boxPaddingLeft: 2,
              boxPaddingRight: 2,
              boxPaddingBottom: 2,
              box: true,
              boxColor: 'background.main',
              imageRounded: true,
            }
          }
        }
      }
      if(it.label.indexOf('Small') !== -1){
        return {
          ...it,
          label: it.label.replace('Small', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              ...DEFAULTBOXSTYLE,
              boxPaddingTop: 2,
              boxPaddingLeft: 8,
              boxPaddingRight: 8,
              boxPaddingBottom: 2,
              box: true,
              boxColor: 'background.main',
            }
          }
        }
      }
      if(it.label.indexOf('center') !== -1){
        return {
          ...it,
          label: it.label.replace('center', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
             textAlign: 'center'
            }
          }
        }
      }
      if(it.label.indexOf('Icon') !== -1){
        return {
          ...it,
          label: it.label.replace('Icon', ''),
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
             imageIcon: true
            }
          }
        }
      }
      if(it.label.indexOf('standardBox') !== -1){
        let bColor = 'background.lightblue';
        let textColor = 'textPrimary';
        if(it.label.indexOf('02') !== -1){
          bColor = 'background.blue';
          textColor = '#ffffff';
        }
        if(it.label.indexOf('03') !== -1){
          bColor = 'background.green';
          textColor = '#ffffff';
        }
        return {
          ...it,
          label: 'standard',
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              ...DEFAULTBOXSTYLE,
              boxPaddingTop: 2,
              boxPaddingLeft: 2,
              boxPaddingRight: 2,
              boxPaddingBottom: 2,
              box: true,
              boxColor: bColor,
              textColor,
            }
          }
        }
      }
      if(it.label.indexOf('BG0') !== -1){
        let bColor = 'background.lightblue';
        let textColor = 'textPrimary';
        if(it.label.indexOf('BG02') !== -1){
          bColor = 'background.blue';
          textColor = '#ffffff';
        }
        if(it.label.indexOf('BG03') !== -1){
          bColor = 'background.green';
          textColor = '#ffffff';
        }
        const label = it.label.replace('BG01', '').replace('BG02', '').replace('BG03', '');

        return {
          ...it,
          label,
          properties: {
            ...it.properties,
            cmsStyle: {
              ...it.properties.cmsStyle,
              ...DEFAULTBOXSTYLE,
              boxPaddingTop: 0,
              boxPaddingLeft: 2,
              boxPaddingRight: 2,
              boxPaddingBottom: 2,
              box: true,
              boxColor: bColor,
              textColor,
            }
          }
        }
      }
      return {...it}
    }
    return {...it};
  })
}
export const handleRepairRank = (items) => {
  const repairNeed = checkIsOldRankSystem(items);

  if(repairNeed){
    const layoutCorrectedItems = correctLayout(items);
    return handleRepairColContainer(prepareRanks(layoutCorrectedItems));
  }

  return items;
};
