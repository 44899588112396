export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_JOURNAL_ID = 'SET_JOURNAL_ID';

export const SET_ACTIVE_RESOURCE_ID = 'SET_ACTIVE_RESOURCE_ID';

export const SET_COMMUNITY_ID = 'SET_COMMUNITY_ID';

export const SET_MODERATORS = 'SET_MODERATORS';

export const setProjectId = (payload) => {
  return { type: SET_PROJECT_ID, payload };
};

export const setJournalId = (payload) => {
  return { type: SET_JOURNAL_ID, payload };
};

export const setActiveResourceId = (payload) => {
  return { type: SET_ACTIVE_RESOURCE_ID, payload };
};

export const setCommunityId = (payload) => {
  return { type: SET_COMMUNITY_ID, payload };
};

export const setModerators = (payload) => {
  return { type: SET_MODERATORS, payload };
};


export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export const AUTH_RESET_USER = 'AUTH_RESET_USER';

export const updateCurrentUser = (payload) => {
  return { type: AUTH_UPDATE_USER, payload };
};

export const resetCurrentUser = (payload) => {
  return { type: AUTH_RESET_USER, payload };
};

export const SET_CONFIRMATION = 'SET_CONFIRMATION';
export const DISPLAY_TOAST = 'DISPLAY_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SET_MAIL = 'SET_MAIL';

export const setConfirmation = (payload) => {
  return { type: SET_CONFIRMATION, payload };
};

export const displayToast = (payload) => {
  return { type: DISPLAY_TOAST, payload };
};

export const hideToast = (payload) => {
  return { type: HIDE_TOAST, payload };
};

export const setMail = (payload) => {
  return { type: SET_MAIL, payload };
};

export const SET_BOARD_CONFIG = 'SET_BOARD_CONFIG';

export const setBoardConfig = (payload) => {
  return { type: SET_BOARD_CONFIG, payload };
};

export const SET_GENERIC_PROPERTIES = 'SET_GENERIC_PROPERTIES';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_QUALIFIERS = 'SET_QUALIFIERS';
export const SET_LABELS = 'SET_LABELS';
export const SET_LINK_LABELS = 'SET_LINK_LABELS';
export const SET_KEYWORDS = 'SET_KEYWORDS';

export const setGenericProperties = (payload) => {
  return { type: SET_GENERIC_PROPERTIES, payload };
};

export const setProperties = (payload) => {
  return { type: SET_PROPERTIES, payload };
};

export const setQualifiers = (payload) => {
  return { type: SET_QUALIFIERS, payload };
};

export const setLabels = (payload) => {
  return { type: SET_LABELS, payload };
};

export const setLinkLabels = (payload) => {
  return { type: SET_LINK_LABELS, payload };
};

export const setKeywords = (payload) => {
  return { type: SET_KEYWORDS, payload };
};

export const SET_GROUPS = 'SET_GROUPS';

export const setGroups = (payload) => {
  return { type: SET_GROUPS, payload };
};

export const SET_VOTING_CONFIG = 'SET_VOTING_CONFIG';

export const setVotingConf = (payload) => {
  return { type: SET_VOTING_CONFIG, payload };
};

export const SET_INITIAL_CMS_DATA = 'SET_INITIAL_CMS_DATA';
export const SET_CMS_DATA = 'SET_CMS_DATA';
export const UPDATE_CMS_ARTICLE = 'UPDATE_CMS_ARTICLE';
export const SET_CMS_MODE = 'SET_CMS_MODE';
export const TOGGLE_CMS_SIDEBAR = 'TOGGLE_CMS_SIDEBAR';
export const UPDATE_CMS_ADD = 'UPDATE_CMS_ADD';
export const UPDATE_CMS_COPY = 'UPDATE_CMS_COPY';
export const SET_CMS_ACTIVE_ID = 'SET_CMS_ACTIVE_ID';
export const UPDATE_CMS_DELETED_ARTICLES = 'UPDATE_CMS_DELETED_ARTICLES';
export const UPDATE_CMS_ADDED_ATTACHMENTS = 'UPDATE_CMS_ADDED_ATTACHMENTS';
export const UPDATE_CMS_UPDATED_ATTACHMENTS = 'UPDATE_CMS_UPDATED_ATTACHMENTS';
export const UPDATE_CMS_DELETED_ATTACHMENTS = 'UPDATE_CMS_DELETED_ATTACHMENTS';

export const setInitialCmsData = (payload) => {
  return { type: SET_INITIAL_CMS_DATA, payload };
};

export const setCmsData = (payload) => {
  return { type: SET_CMS_DATA, payload };
};

export const updateCmsArticle = (payload) => {
  return { type: UPDATE_CMS_ARTICLE, payload };
};

export const setCmsMode = (payload) => {
  return { type: SET_CMS_MODE, payload };
};

export const toggleCmsSidebar = (payload) => {
  return { type: TOGGLE_CMS_SIDEBAR, payload };
};

export const toggleCmsAdd = (payload) => {
  return { type: UPDATE_CMS_ADD, payload };
};

export const setCmsCopy = (payload) => {
  return { type: UPDATE_CMS_COPY, payload };
};

export const setCmsActiveId = (payload) => {
  return { type: SET_CMS_ACTIVE_ID, payload };
};

export const updateCmsDeletedArticles = (payload) => {
  return { type: UPDATE_CMS_DELETED_ARTICLES, payload };
};

export const updateCmsAddedAttachments = (payload) => {
  return { type: UPDATE_CMS_ADDED_ATTACHMENTS, payload };
};

export const updateCmsUpdatedAttachments = (payload) => {
  return { type: UPDATE_CMS_UPDATED_ATTACHMENTS, payload };
};

export const updateCmsDeletedAttachments = (payload) => {
  return { type: UPDATE_CMS_DELETED_ATTACHMENTS, payload };
};

export const SET_JOYRIDE = 'SET_JOYRIDE';
export const UPDATE_JOYRIDE_ARTICLE = 'UPDATE_JOYRIDE_ARTICLE';
export const SET_JOYRIDE_ADD = 'SET_JOYRIDE_ADD';

export const toggleJoyride = (payload) => {
  return { type: SET_JOYRIDE, payload };
};

export const updateJoyrideCmsArticle = (payload) => {
  return { type: UPDATE_JOYRIDE_ARTICLE, payload };
};

export const toggleJoyrideAdd = (payload) => {
  return { type: SET_JOYRIDE_ADD, payload };
};